import { BraintreeClientPaymentMethod } from '@generated/swaggerClient';
import { checkoutContactValidationSchema } from '@modules';
import { HahFormikFieldGroup, HahTooltip, Icon, iconLibrary, InputGroup, performDataLayerLog, sentryWrapper, toastError, useEffectAsync } from '@shared';
import { CheckoutFormModel } from '@types';
import { ErrorMessage, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

// Helper function to check if any contact field has been filled
const hasUserEnteredContactInfo = (contact: CheckoutFormModel["contact"]): boolean => {
    return Object.values(contact).some(value => value.trim() !== '');
};

export const ContactInfoForm = () => {
    const { values, setFieldValue } = useFormikContext<CheckoutFormModel>();

    const isContactInfoValid = async (contact: CheckoutFormModel['contact']) => {
        return await checkoutContactValidationSchema.isValid(contact);
    };

     // Ref to track if logging has already happened
    const hasLoggedContactInfoChange = useRef(false);

    useEffect(() => {
        if (!hasLoggedContactInfoChange.current && hasUserEnteredContactInfo(values.contact)) {
            hasLoggedContactInfoChange.current = true;
            performDataLayerLog({ event: 'begin_checkout' }, true);

        }
    }, [values.contact]);

    // If contact info changes / invalidates we need to clear the payment method and notify the user
    useEffectAsync(async () => {
        const contactIsValid = await isContactInfoValid(values.contact);
        if (!contactIsValid && values.paymentMethod !== BraintreeClientPaymentMethod.Unknown) {
            setFieldValue('paymentMethod', BraintreeClientPaymentMethod.Unknown);

            sentryWrapper.logInfo('Contact info was altered after payment method was selected, so clearing payment method', { component: 'ContactInfoForm' });

            toastError({ title: 'Please enter valid contact info before selecting a payment method.'});
        }
    }, [values.contact, values.paymentMethod]);

	const parentName = 'contact';
	const tooltip = <HahTooltip tooltipContent={'Your phone number is required to communicate with you and to ensure the best moving experience possible.'} />;
	return (
		<InputGroup rows={3} cols={2} className='mb-4'>
			<HahFormikFieldGroup
				parentName={parentName}
				name='email'
				rounded={['topLeft', 'topRight']}
				placeholder='me@email.com'
				cols={2}
				iconLeft={<Icon icon={iconLibrary.faEnvelope} />}
				required
			/>
			<HahFormikFieldGroup parentName={parentName} name='phone' placeholder='(555) 000-0000' cols={2} iconRight={tooltip} required />
			<HahFormikFieldGroup rounded={['bottomLeft']} parentName={parentName} name='firstName' placeholder='First name' required />
			<HahFormikFieldGroup rounded={['bottomRight']} parentName={parentName} name='lastName' placeholder='Last name' required />
			<span className='text-start text-sm text-destructive mt-2'>
				<ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.firstName`} />
				<ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.lastName`} />
				<ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.phone`} />
				<ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.email`} />
			</span>
		</InputGroup>
	);
};
