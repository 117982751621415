import { PayPalButtons, GooglePayButton, ApplePayButton } from '@components';
import { BraintreeClientPaymentMethod, BraintreeToken } from '@generated/swaggerClient';
import { useBrandInfo, useBtClientState } from '@hooks';
import { iconLibrary, Flex, Icon, ActionButton, HahFormikCheckbox, isApplePayUnavailable, SpinnerBasic, toastError } from '@shared';
import { CheckoutFormModel } from '@types';
import { ErrorMessage, FormikHelpers, useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

interface FooterNavCheckoutProps {
    paymentMethod?: BraintreeClientPaymentMethod;
    continueButtonContent?: string;
    onHandleBook: (
        values: CheckoutFormModel,
        formikHelpers: FormikHelpers<CheckoutFormModel>,
        paymentNonce: string,
        cardholderName: string,
        digitalWalletBillingPostalCode: string,
        deviceData: string
    ) => Promise<boolean>;
    onSubmitted: () => void;
    grandTotal: string;
    braintreeToken: BraintreeToken;
    deviceData: React.MutableRefObject<string | null>;
    disableNext: boolean;
}

export const FooterNavCheckout = ({
    onHandleBook,
    grandTotal,
    paymentMethod = BraintreeClientPaymentMethod.Unknown,
    continueButtonContent = 'Continue',
    braintreeToken,
    deviceData,
    onSubmitted,
    disableNext
}: FooterNavCheckoutProps) => {
    const formikContext = useFormikContext<CheckoutFormModel>();
    const { btClientInstance } = useBtClientState();
    const brand = useBrandInfo();

    const handleSubmit = useCallback(() => {
        if (formikContext.isSubmitting) return;
        if (formikContext.values.agreedToTos === false) {
            toastError({ title: 'Please agree to our Terms of Service before booking.' });
        }
        formikContext.submitForm();
    }, [formikContext]);

    const nextPageButton = (
        <ActionButton disabled={disableNext} shape='pill' onClickAction={handleSubmit} args='next' className={'w-[345px] max-w-full text-xl rounded-md'}>
            {continueButtonContent}
            <Icon size='xs' icon={iconLibrary.faArrowRight} className='ms-2' />
        </ActionButton>
    );

    const formikRef = useRef(formikContext);

    useEffect(() => {
        formikRef.current = formikContext; // Keep the ref updated
    }, [formikContext]);

    const handlePaymentMethodApproved = useCallback(
        async (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => {
            const { values } = formikRef.current; // Use the latest context since we're not re-rendering the button components
            if (await onHandleBook(values, formikRef.current, paymentNonce, cardholderName ?? '', postalCode ?? '', deviceData.current ?? '')) {
                onSubmitted();
            }
        },
        [deviceData, onHandleBook, onSubmitted] // Dependencies exclude formikContext
    );

    const agreeToTosLabel = (
        <>
            Please agree to our{' '}
            <a target='_blank' href='https://www.porchmovinggroup.com/legal/'>
                Terms of Service
            </a>
            {' '}before booking. Your reservation is confirmed once you reach the confirmation page.
        </>
    );

    if (!btClientInstance) {
        return <SpinnerBasic />;
    }

    return (
        <div className='px-4 w-full bg-neutralGrey-50 border-t border-neutralGrey-200 py-6 mt-6 z-10 md:sticky md:bottom-0'>
            <div className={`md:w-[576px] mx-auto text-center mb-3`}>
                <Flex align='center' justify='center' className='gap-2 text-start'>
                    <HahFormikCheckbox id='agreedToTos' name='agreedToTos' required />
                    <label htmlFor='agreedToTos' className='text-sm font-medium cursor-pointer'>
                        {agreeToTosLabel}
                    </label>
                </Flex>
                <ErrorMessage name='agreedToTos' component='div' className='text-red-600' />
            </div>
            <Flex direction='col' align='center' className='w-[576px] h-11 mx-auto'>
                <div className={`${paymentMethod === BraintreeClientPaymentMethod.PayPal ? 'block' : 'hidden'} max-w-full`}>
                    <PayPalButtons
                        displayName={brand.companyName}
                        merchantAccount={braintreeToken.merchantAccount}
                        clientInstance={btClientInstance}
                        grandTotal={grandTotal}
                        handlePaymentMethodApproved={handlePaymentMethodApproved}
                    />
                </div>

                <div className={`${paymentMethod === BraintreeClientPaymentMethod.AndroidPayCard ? 'block' : 'hidden'} max-w-full`}>
                    <GooglePayButton
                        googleMerchantID={braintreeToken.googleMerchantID}
                        clientInstance={btClientInstance}
                        grandTotal={grandTotal}
                        handlePaymentMethodApproved={handlePaymentMethodApproved}
                    />
                </div>
                {!isApplePayUnavailable() && (
                    <div className={`${paymentMethod === BraintreeClientPaymentMethod.ApplePay ? 'block' : 'hidden'} max-w-full`}>
                        <ApplePayButton
                            displayName={brand.companyName}
                            clientInstance={btClientInstance}
                            grandTotal={grandTotal}
                            handlePaymentMethodApproved={handlePaymentMethodApproved}
                        />
                    </div>
                )}

                <div
                    className={`${paymentMethod === BraintreeClientPaymentMethod.CreditCard || paymentMethod === BraintreeClientPaymentMethod.Unknown ? 'block' : 'hidden'} max-w-full`}>
                    {nextPageButton}
                </div>
            </Flex>
        </div>
    );
};
