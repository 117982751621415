import { HahFormikFieldGroup, HahFormikSelectField, InputGroup, stateOptions } from '@shared';
import { ErrorMessage } from 'formik';

interface Props {
	parentName?: string;
	label?: string;
}

export const AddressManualEntry = ({ parentName, label }: Props) => {
	return (
		<InputGroup label={label} rows={4} cols={2} required className='mb-6'>
			<HahFormikFieldGroup
				cols={2}
				rounded={['topLeft', 'topRight']}
				label={label}
				maxLength={500}
				parentName={parentName}
				placeholder='Street Address'
				name='street'
                autoComplete={`section-${parentName} address-line1`}
                required
			/>
			<HahFormikFieldGroup cols={2} maxLength={50} parentName={parentName} placeholder='Street Address Line 2 (apt #/suite)' name='street2' autoComplete={`section-${parentName} address-line2`} required />
			<HahFormikFieldGroup cols={2} maxLength={50} parentName={parentName} placeholder='City' name='city' autoComplete={`section-${parentName} address-level2`} required />
            <HahFormikSelectField cols={1} rounded={['bottomLeft']} parentName={parentName} name="state" required options={stateOptions} autoComplete={`section-${parentName} address-level1`} placeholder='State' />
			<HahFormikFieldGroup cols={1} rounded={['bottomRight']} maxLength={5} parentName={parentName} placeholder='ZIP' name='zip' autoComplete={`section-${parentName} postal-code`} required />
			<span className='text-start text-sm text-destructive mt-2'>
				<ErrorMessage className='text-red-600 mb-2' component='div' name={parentName ? `${parentName}.city` : 'city'} />
				<ErrorMessage className='text-red-600 mb-2' component='div' name={parentName ? `${parentName}.state` : 'state'} />
				<ErrorMessage className='text-red-600 mb-2' component='div' name={parentName ? `${parentName}.zip` : 'zip'} />
				<ErrorMessage className='text-red-600 mb-2' component='div' name={parentName ? `${parentName}.street` : 'street'} />
			</span>
		</InputGroup>
	);
};
