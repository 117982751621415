import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Flex, Icon } from '@shared';

interface FeatureProps {
    icon: IconDefinition | IconDefinition[];
    text: string | JSX.Element;
}
export const Feature = (props: FeatureProps) => {
    return (
        <Flex direction='col' align='center' className='gap-1'>
            {Array.isArray(props.icon) ? (
                <span className='fa-layers fa-fw w-10 h-10 mb-2'>
                    <Icon fixedWidth className='text-primary-500 block mb-2 drop-shadow' icon={props.icon[0]} />
                    <Icon fixedWidth className='text-neutralGrey-50 block mb-2 drop-shadow' icon={props.icon[1]} transform='shrink-6' inverse />
                </span>
            ) : (
                <Icon className='text-primary-500 block mb-2 drop-shadow w-10 h-10' icon={props.icon} />
            )}
            <div className='self-stretch text-center text-neutralGrey-600 text-xs font-normal leading-[18px] px-4 md:px-10'>{props.text}</div>
        </Flex>
    );
};
