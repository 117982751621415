import { ContactInfoForm, FatalErrorForm, FooterNavCheckout, Headings, PaymentInfo, QuoteSummary } from '@components';
import { BraintreeClientPaymentMethod, BraintreeToken } from '@generated/swaggerClient';
import { ApiCallResult, useBrandInfo, useBtClientState, useCheckoutClientFactory, useCheckoutState } from '@hooks';
import { checkoutValidationSchema } from '@modules';
import { ErrorFocus, Flex, getValidPaymentMethodLabel, sentryWrapper, SpinnerBasic, toastError, tokenizeAndSubmit, useEffectOnlyOnceAsync } from '@shared';
import { CheckoutFormModel } from '@types';
import { HostedFields } from 'braintree-web';
import { Form, Formik, FormikHelpers } from 'formik';
import { useRef, useState } from 'react';
import { debugLoggerInfo } from '@utils';
type tokenizeFunc = HostedFields['tokenize'];
type TModel = CheckoutFormModel;

interface Props {
    onSubmitted: () => void;
    onHandleBook: (
        values: TModel,
        formikHelpers: FormikHelpers<TModel>,
        paymentNonce: string,
        cardholderName: string,
        digitalWalletBillingPostalCode: string,
        deviceData: string
    ) => Promise<boolean>;
    model?: TModel;
}
export const CheckoutForm = ({ onSubmitted, onHandleBook }: Props) => {
    const { model } = useCheckoutState();
    const { btClientInstance } = useBtClientState();
    const deviceData = useRef<string | null>(null);
    const tokenizeRef = useRef<tokenizeFunc>();
    const [braintreeToken, setBraintreeToken] = useState<ApiCallResult<BraintreeToken>>();
    const { wrapApiCall } = useCheckoutClientFactory();
    const brandInfo = useBrandInfo();

    useEffectOnlyOnceAsync(async () => {
        debugLoggerInfo('CheckoutForm: Fetching Braintree client token');
        if (braintreeToken == null) {
            const request = await wrapApiCall(client => client.checkoutGenerateClientToken());

            setBraintreeToken(request);

            if (!request.success) {
                toastError({ title: `There was a fatal error loading a resource that is needed for this page. Please try turning off adblockers and refreshing. If you continue to experience problems, please contact us at ${brandInfo.salesPhoneNumber?.displayFormat}.` });

                // this is a big deal, so log as fatal
                sentryWrapper.logException(request.errorObj, 'fatal', 'Braintree client token request was not successful', { component: 'CheckoutForm' });
            }
        }
    });



    const initialValues: TModel = {
        paymentInfo: {
            billingZip: '',
            cardholderName: '',
            number: '',
            expirationDate: '',
            cvv: '',
        },
        contact: { ...model.contact, phone: model.contact.phone ?? '' },
        paymentMethod: BraintreeClientPaymentMethod.Unknown,
        agreedToTos: false,
    };

    // If we're setting up the the digital wallet buttons and putting them in the footer, we don't need to handle the payment method here. This will always be the cc option.
    const handleSubmit = async (values: TModel, formikHelpers: FormikHelpers<TModel>) => {
        // This shouldn't happen, but just in case
        if (!btClientInstance) {
            sentryWrapper.logFatal('handleSubmit triggered (credit card payment method) but no Braintree Client found', { component: 'CheckoutForm.handleSubmit' });

            toastError({ title: 'An error occurred processing your payment method - no Braintree Client found.' });
            return;
        }

        sentryWrapper.logInfo('handleSubmit triggered (credit card payment method)', { component: 'CheckoutForm.handleSubmit' });

        let nonce = '';
        let cardholderName = '';
        let error = 'An error occurred processing your payment method. Please check your payment method and retry.';
        switch (values.paymentMethod) {
            case BraintreeClientPaymentMethod.Unknown:
                formikHelpers.setFieldError('paymentMethod', 'Please select a payment method');
                break;
            case BraintreeClientPaymentMethod.CreditCard: {
                const tokenizeResult = await tokenizeAndSubmit(tokenizeRef, formikHelpers);
                if (tokenizeResult.success) {
                    nonce = tokenizeResult.nonce;
                    cardholderName = tokenizeResult.cardholderName;
                }
                else {
                    error = tokenizeResult.error;
                }
                break;
            }
        }

        if (!nonce || nonce === '') {
            // I don't think we want to set field error here because it would prevent them from submitting again

            sentryWrapper.logFatal('handleSubmit triggered (credit card payment method) but no nonce found', { component: 'CheckoutForm.handleSubmit' });

            toastError({ title: error });
            return;
        }

        if (await onHandleBook(values, formikHelpers, nonce, cardholderName, '', deviceData.current ?? '')) {
            onSubmitted();
        }
    };

    // we don't yet have a token/all data loaded, so show a spinner
    if (braintreeToken == null || model.selection.grandTotal == null || model.selection.providerName == null) {
        return <SpinnerBasic />;
    }

    // we got a response back for the bt client, but it wasn't successful. we cannot proceed
    if (braintreeToken != null && !braintreeToken.success) {
        return <FatalErrorForm />;
    }

    const resolvedBtClient = braintreeToken.result!;
    const resolvedBtClientToken = resolvedBtClient.clientToken;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={checkoutValidationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
            // PMG-6018 bug this is re-initializing the form because we change the model which changes the initial values
            //enableReinitialize
            onSubmit={handleSubmit}>
            {formik => (
                <Form className='form-wrapper' noValidate>
                    <Flex grow={1} direction='col' className='w-[576px] px-4'>
                        <ErrorFocus />
                        <Headings primary={'Book Your Move'} />
						<Flex direction='col' className='p-4 bg-white rounded-3xl shadow mb-6'>
							<h2 className='mb-0 flex-auto'>Your Contact Info <span className='text-red-600'>*</span></h2>
							<p className='mb-2'>Your information will be shared with your mover, <strong>{model.selection.providerName}</strong></p>
							<ContactInfoForm />
						</Flex>

						<PaymentInfo tokenizeRef={tokenizeRef} btClientToken={resolvedBtClientToken} deviceData={deviceData} />

                        <QuoteSummary />
					</Flex>
					<FooterNavCheckout
                        braintreeToken={resolvedBtClient}
                        paymentMethod={formik.values.paymentMethod}
                        continueButtonContent={formik.values.paymentMethod == BraintreeClientPaymentMethod.Unknown ? 'Select a payment method' : `Book Now with ${getValidPaymentMethodLabel(formik.values.paymentMethod)}`}
                        onHandleBook={onHandleBook}
                        onSubmitted={onSubmitted}
                        grandTotal={model.selection.grandTotal?.toString() ?? '123.45'}
                        disableNext={!formik.isValid}
                        deviceData={deviceData} />
				</Form>
			)}
		</Formik>
	);
};
