import { Input } from '@lemonsqueezy/wedges';
import { ChangeEvent, ChangeEventHandler, FormEventHandler, forwardRef } from 'react';
import classNames from 'classnames';
import { Icon, iconLibrary } from '@shared';

interface InputProps {
    type?: string;
    autoComplete?: string;
    name: string;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    label?: string;
    className?: string;
    description?: string;
    required?: boolean;
    placeholder?: string;
    value?: string;
    id?: string;
    maxLength?: number;
    disabled?: boolean;
    destructive?: boolean;
    helperText?: string;
    ref?: React.RefObject<HTMLInputElement>;
    onChange: ChangeEventHandler<HTMLInputElement>; // Keep it as ChangeEventHandler
    onClear?: () => void; // Optional prop for clearing
}

export const HahInput = forwardRef<HTMLInputElement, InputProps>(({ onChange, onClear, iconLeft, iconRight, className, value, ...rest }, ref) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> & FormEventHandler<HTMLLabelElement> = e => {
        onChange(e as ChangeEvent<HTMLInputElement>);
    };

    const hasClearButton = !!onClear; // Check if the clear button should be rendered
    const classes = classNames(`${iconLeft ? 'ps-10' : ''}`, className);

    return (
        <div className='relative'>
            {iconLeft && <span className='absolute top-2 left-4 z-[1]'>{iconLeft}</span>}
            <Input
                className={classes}
                ref={ref} // Pass the forwarded ref
                onChange={handleChange}
                value={value}
                {...rest}
            />
            {iconRight && !hasClearButton && <span className='absolute top-2 right-4 z-[1]'>{iconRight}</span>}
            {hasClearButton && value && (
                <button type='button' className='absolute top-[42px] right-3 z-[1]' onClick={onClear}>
                    <Icon icon={iconLibrary.faTimes} />
                </button>
            )}
        </div>
    );
});
