import { BrandType } from "@generated/swaggerClient";
import { useBrandInfo } from "@hooks";
import classNames from "classnames";
import React from "react";

export const Headings = ({ primary, secondary, imgSrc, imgAlt, className, hideImgOnSm = true }: { primary?: string | React.ReactNode; secondary?: string | React.ReactNode; imgSrc?: string; imgAlt?: string; className?: string; hideImgOnSm?: boolean}) => {
    const brandInfo = useBrandInfo();
    const isHireAHelper = brandInfo.brand === BrandType.HireAHelper;
    
    return (<div className={classNames(`flex flex-col ${isHireAHelper ? 'items-start text-start' : 'items-center text-center'}`, className)}>
        {imgSrc && <img src={imgSrc} alt={imgAlt} width={72} height={72} className={`mt-6 ${hideImgOnSm ? 'hidden md:block' : ''}`} /> }
        {primary && <h1>{primary}</h1>}
        {secondary && <p className='subhead'>{secondary}</p>}
    </div>)
};