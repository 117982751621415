import { useEffect } from 'react';
import { useCheckoutState } from '@hooks';
import { Urls } from '@utils';
import { useNavigate } from 'react-router-dom';
import { sentryWrapper } from '@shared';
/**
 * Redirects to the index page if the quote ID is not found
 * @param id The quote ID
 */
export const useRedirectWhenQuoteNotFound = () => {
    const navigate = useNavigate();
    const { model } = useCheckoutState();

    useEffect(() => {
        // We need to make sure we don't redirect on confirmation pages UNLESS the user is just trying to navigate there directly
        if (pagesThatDoNotRedirect.includes(window.location.pathname) || isContinuePath(window.location.pathname)) {
            return;
        }

        if (!model.id || model.id === '') {
            sentryWrapper.logWarn('User tried to access a page without a quote ID', { hook: 'useRedirectWhenQuoteNotFound' }, { model: JSON.stringify(model) });
            navigate(Urls.locations.index);
        }
    }, [model, navigate]);
};

const pagesThatDoNotRedirect = [
    Urls.locations.index,
    Urls.confirmation,
    Urls.quote.longDistanceConfirmation
];

const isContinuePath = (path: string) => {
    // Matches UUIDs with hyphens. Example /continue/507EB7AF-FD1B-4240-AC80-AB8DFAB83B85
    const continuePathRegex = /^\/continue\/[A-Za-z0-9-]+$/;
    return continuePathRegex.test(path);
};