import packageJson from '../package.json';

let environment: string = 'Production'; // Default to production

if (/-staging|-qa\d+\./i.test(window.location.host)) {
    environment = 'Staging';

    const qaRegex = /[a-zA-Z0-9]+-(qa\d+)\./i;
    const match = window.location.host.match(qaRegex);

    if (match) {
        environment = match[1].toUpperCase(); // Match[1] captures "qa1", "qa2", etc.
    }
} else if (/-dev/i.test(window.location.host)) {
    environment = 'Development';
}

export const environmentName: string = environment;
export const rootDomain: string = environment === 'Production' ? 'https://www.movingplace.com/' : environment === 'Development' ? 'https://dev.movingplace.com:7277' : `https://${environment.toLowerCase()}.movingplace.com/`;
export const releaseVersion: string = `${packageJson.name}@${import.meta.env.VITE_RELEASE_VERSION || 'latest'}`;
export const customerLoginUrl: string = environment === 'Production'
    ? 'https://www.porchmovinggroup.com/customer/'
    : `https://${environment === 'Development' ? 'dev' : environment.toLowerCase()}.porchmovinggroup.com/customer/`;
//export const emitDebuggingInfo = environment !== 'Production';
export const emitDebuggingInfo = true; // nicholashead-pmg hardcoded on for PMG-6542 - so we log more console stuff to sentry's breadcrumbs

// Log the environment and release if not in production
if (environment !== 'Production') {
    console.info('pmgConfig', { environmentName, releaseVersion });
}

// Keep in sync with HAH and other apps
export function getOrchestratorRootUrl(): string {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    if (subdomain === 'checkout-dev') {
        return 'http://localhost:5055';
    }
    else if (subdomain === 'checkout-staging' || (/^checkout-qa[1-5]$/.test(subdomain))) {
        // remove 'checkout-' prefix
        return `https://${subdomain.replace('checkout-', '')}.porchmovinggroup.com/v1/orchestrator`;
    }
    else {
        return 'https://www.porchmovinggroup.com/v1/orchestrator';
    }
}
