import { useBrandInfo } from '@hooks';
import { debugLoggerInfo, Urls } from '@utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Adjusts the title for each page based on the current route.
 * @param defaultTitle The default title if we cannot match a route.
 */
export const usePageTitles = (defaultTitle = '') => {
    const location = useLocation();
    const brandInfo = useBrandInfo();

    useEffect(() => {
        const path = location.pathname;
        const prefix = `${brandInfo.companyName} |`;
        let pageTitle = brandInfo.companyName;

        // from https://porchmovinggroup.notion.site/MovingPlace-com-URLs-Sitemap-152a3e1ebc6480bba8d5d31b347f0569
        switch (path) {
            case Urls.index:
                pageTitle = `${prefix} Home`;
                break;
            case Urls.locations.index:
                pageTitle = `${prefix} Locations`;
                break;
            case Urls.locations.load:
                pageTitle = `${prefix} Load`;
                break;
            case Urls.locations.unload:
                pageTitle = `${prefix} Unload`;
                break;
            case Urls.items:
                pageTitle = `${prefix} Items`;
                break;
            case Urls.date:
                pageTitle = `${prefix} Date`;
                break;
            case Urls.quote.index:
                pageTitle = `${prefix} Quote`;
                break;
            case Urls.quote.providers:
                pageTitle = `${prefix} Quote - MSP List`;
                break;
            case Urls.quote.longDistance:
                pageTitle = `${prefix} Long Distance Form`;
                break;
            case Urls.quote.longDistanceConfirmation:
                pageTitle = `${prefix} Long Distance Form Confirmation`;
                break;
            case Urls.time:
                pageTitle = `${prefix} Time`;
                break;
            case Urls.checkout:
                pageTitle = `${prefix} Checkout`;
                break;
            case Urls.confirmation:
                pageTitle = `${prefix} Confirmation`;
                break;
            case Urls.noProvidersFound:
                pageTitle = `${prefix} No Providers Found`;
                break;
            default:
                pageTitle = prefix;
                if (defaultTitle != '') {
                    pageTitle += ` ${defaultTitle}`;
                }
        }

        document.title = pageTitle;

        debugLoggerInfo('usePageTitles triggered', { path, pageTitle });
    }, [location, defaultTitle, brandInfo.companyName]);
};
