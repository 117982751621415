import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { HahTooltip, Icon, iconLibrary, IconStack, SpinnerBasic } from '@shared';

interface CardProps {
    title: string;
    subtitle?: string | React.ReactNode;
    bgColor?: string; // Optional tw background color for the card
    titleTextColor?: string; // Optional tw text color for the title
    iconLeft?: IconDefinition | [IconDefinition, IconDefinition]; // Optional single or array of exactly 2 icons to be used for stacking Font Awesome Icons
    iconRight?: IconDefinition; // Optional URL for the right icon
    tooltip?: string; // Optional tooltip content
    className?: string; // Optional additional classes to container element
    titleFontSize?: string; // Optional font size for the title
    centered?: boolean; // Optional center the content
    isLoading?: boolean;
}

/**
 * Card component that displays a card with a title, optional subtitle, icons, and other customizable properties.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title of the card.
 * @param {string | React.ReactNode} [props.subtitle] - The subtitle of the card, can be a string or a React node.
 * @param {string} [props.bgColor='bg-neutralGrey-50'] - The background color of the card.
 * @param {string} [props.titleTextColor='text-neutralGrey-700'] - The text color of the title.
 * @param {IconDefinition | [IconDefinition, IconDefinition]} [props.iconLeft] - The icon(s) to display on the left side of the card.
 * @param {IconDefinition} [props.iconRight] - The icon to display on the right side of the card.
 * @param {string} [props.tooltip] - The tooltip content for the card.
 * @param {string} [props.className] - Additional classes to apply to the card container.
 * @param {string} [props.titleFontSize='text-sm leading-[21px] font-semibold'] - The font size of the title.
 * @param {boolean} [props.centered=true] - Whether to center the content of the card.
 * @param {boolean} [props.isLoading] - Whether the card is in a loading state.
 *
 * @returns {JSX.Element} The rendered card component.
 */
export const Card: React.FC<CardProps> = ({
    title,
    subtitle,
    iconLeft,
    iconRight,
    tooltip,
    className,
    isLoading,
    bgColor = 'bg-neutralGrey-50',
    titleTextColor = 'text-neutralGrey-700',
    titleFontSize = 'text-sm leading-[21px] font-semibold',
    centered = true,
}) => {
    return (
        <div className={`w-full px-4 py-2 rounded-lg flex-col ${centered ? 'justify-center items-center' : ''} inline-flex mb-2 ${bgColor} ${className}`}>
            <div className={`${centered ? 'justify-center items-center' : ''} gap-2 inline-flex`}>
                {/* Left Icon */}
                {iconLeft && (
                    Array.isArray(iconLeft) ? (
                        <IconStack firstIcon={iconLeft[0]} secondIcon={iconLeft[1]}
                        firstIconClassNames={`${titleTextColor}`}
                        secondIconClassNames={`${titleTextColor} fa-xl`}
                        containerClassName='fa-xs' />
                    ) : (
                        <div>
                            <Icon icon={iconLeft} className={`w-3.5 h-3.5 ${titleTextColor}`} />
                        </div>
                    )
                )}

                {/* Title */}
                {isLoading ? (
                    <SpinnerBasic />
                ) : (
                    <div className={`${centered ? 'text-center' : 'text-start'} ${titleFontSize} ${titleTextColor}`}>
                        <span>{title}</span>
                    </div>
                )}

                {/* Tooltip */}
                {tooltip && (
                    <div className={`${centered ? 'justify-center items-center' : ''} inline-flex`}>
                        <HahTooltip tooltipContent={tooltip}>
                            <Icon icon={iconLibrary.faCircleQuestion} className={`w-3.5 h-3.5 ${titleTextColor}`} />
                        </HahTooltip>
                    </div>
                )}

                {/* Right Icon */}
                {iconRight && (
                    <div>
                        <Icon icon={iconRight} className={`w-3.5 h-3.5 ${titleTextColor}`} />
                    </div>
                )}
            </div>

            {/* Subtitle */}
            {subtitle && <div className='text-center text-neutralGrey-900 text-base font-normal leading-normal mt-1'>{subtitle}</div>}
        </div>
    );
};
