import { ApiException, CheckoutClient } from '@generated/swaggerClient';
import { FormikHelpers } from 'formik';
import { useCheckoutState } from '@hooks';
import { sentryWrapper, toastError, ToastMessage } from '@shared';
import { js2 } from '@utils';

/**
 * @returns A factory function getClient that creates a new instance of the CheckoutClient
 */
export const useCheckoutClientFactory = () => {
    const { setIsFetching } = useCheckoutState();
    const getClient = () => checkoutClientFactory();

    const wrapApiCall = async <TResult, TModel>(func: (client: CheckoutClient) => Promise<TResult>, formikHelpers?: FormikHelpers<TModel>): Promise<ApiCallResult<TResult>> => {
        return wrapApiCallInner2(func, getClient(), setIsFetching, toastError, formikHelpers)
    }

    return { getClient: () => checkoutClientFactory(), wrapApiCall };
}

export const checkoutClientFactory = () => {
    const apiPort = import.meta.env.VITE_API_DEV_PORT;
    const baseUrl = apiPort ? `https://localhost:${apiPort}` : undefined; // this MUST be localhost, because that's what the local API is configured for

    return new CheckoutClient(baseUrl);
}

export interface ApiCallResult<T> {
    /**
     * The result of the API call, if successful.
     */
    result?: T;
    /**
     * Indicates whether the call was successful.
     */
    success: boolean;
    /**
     * The error object if the call failed due to an nswag API exception.
     */
    errorObj?: object | undefined;
}

const fieldMapping: Record<string, string> = {
    PhoneNumber: 'contact.phone',
    // Add more mappings as needed
};

const wrapApiCallInner2 = async <TResult, TModel>(func: (client: CheckoutClient) => Promise<TResult>, client: CheckoutClient, setIsFetching: (fetching: boolean) => void, toastError: (opts: Partial<Omit<ToastMessage, 'type'>>) => void, formikHelpers?: FormikHelpers<TModel>): Promise<ApiCallResult<TResult>> => {
    setIsFetching(true);
    try {
        return { result: await func(client), success: true };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
        // please note: the swagger client will throw an ApiException ONLY IF there is not a more specific error class that handles it.
        // otherwise that one will be thrown instead.
        // in javascript, you can throw anything! what a bizarre strange world.
        // so make sure you check the swaggerClient to see exactly what is doing. can't make any assumptions.

        // FUTURE: use proper static types for each error type and handle them accordingly

        if (ApiException.isApiException(error)) {
            // this is a swagger low-level generic client exception - log and then pass back

            const apiError = error as ApiException;

            sentryWrapper.logException(error, 'error', `useCheckoutClient had an ApiException: ${apiError.message}`,
                { hook: 'useCheckoutClient' },
                {
                    apiErrorStatus: apiError.status,
                    apiErrorMessage: apiError.message,
                    apiErrorResult: apiError.result,
                    apiErrorResponse: apiError.response
                });

            return { success: false, errorObj: error };
        }

        // HACK: if the object has "errors", assume it's a ModelStateError and try and map it to formik fields
        // FUTURE: this should not be just checking the "any" type for "errors" property - instead, we should have a specific type in the swaggerClient that relates to bad requests/model state errors
        if (error.errors && formikHelpers) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Object.entries(error.errors).forEach(async ([key, value]: [string, any]) => {
                // Map server-side key to Formik field name
                const mappedKey = fieldMapping[key] || key.split('.').map((k) => k.charAt(0).toLowerCase() + k.slice(1)).join('.');

                const fieldEl = document.querySelector(`[name="${mappedKey}"]`);
                if (fieldEl) {
                    await formikHelpers.setFieldTouched(mappedKey, true);
                    formikHelpers.setFieldError(mappedKey, value[0]);
                    fieldEl.scrollIntoView({ behavior: 'smooth', block: 'center' });

                    return;
                }
            });

            toastError({ title: 'There was an error with your submission. Please review the form and try again.' });

            // log as a warning, not an error
            // CANNOT USE captureException here since error is not an instanceof Error
            sentryWrapper.logWarn('useCheckoutClient had an error, but it may be a BadRequest',
                { hook: 'useCheckoutClient' },
                { errorJson: js2(error), errors: error.errors });

            return { success: false, errorObj: error };
        }

        // fall back for any other error type that may happen

        // log generic exception
        // CANNOT USE captureException here since error is not an instanceof Error
        sentryWrapper.logError('useCheckoutClient had an error',
            { hook: 'useCheckoutClient' },
            { errorJson: js2(error) });

        return { success: false, errorObj: error };
    } finally {
        setIsFetching(false);
    }
}
