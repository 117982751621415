
import { GetCancellationFeeBreakdownResponseDto } from '@generated/swaggerClient';
import { formatCurrency } from '@utils';
import React from 'react';
export function CancellationFeesList({ cancellationFees }: { cancellationFees: GetCancellationFeeBreakdownResponseDto[]; }) {

    return (
        <div className="pt-3 text-sm">
            <label className="font-bold">Cancellation fees</label>
            {cancellationFees.map((x) => {
                return <div key={`${x.label} Cancellation Policy`} className="flex justify-between py-2">
                <div>
                    {x.cancellationFee! > 0
                    ? `${x.maxHours} ${x.minHours === 0 ? 'hour' : 'hours'} or less:`
                    : `More than ${x.minHours} hours notice:`}
                </div>
                <div className="w-1/2 text-right">
                    {x.cancellationFee! > 0 ? formatCurrency(x.cancellationFee!) : 'free to cancel'}
                </div>
            </div>
            })}
        </div>
    );
}
