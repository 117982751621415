export class ParsedAddress {
    street: string = '';
    street2: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';

    placeId: string = '';
    placeFormattedAddress: string = '';

    invalid: boolean = false;
    invalidReason: string = '';

    constructor(place: google.maps.places.PlaceResult | undefined) {
        if (!place || !place.address_components) {
            this.invalid = true;
            this.invalidReason = 'No place data or address components';
            return;
        }

        this.placeId = place.place_id || '';
        this.placeFormattedAddress = place.formatted_address || '';

        // Ensure street number is present and is a number.
        const streetNumberComponent = place.address_components.find(c => c.types.includes('street_number'));
        if (!streetNumberComponent || isNaN(Number(streetNumberComponent.long_name))) {
            this.invalid = true;
            this.invalidReason = 'No street number or street number not a number';
            return;
        }

        for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0];

            switch (componentType) {
                case 'street_number': {
                    this.street = `${component.long_name} ${this.street}`;
                    break;
                }

                case 'route': {
                    this.street += component.short_name;
                    break;
                }

                case 'postal_code': {
                    this.zip = `${component.long_name}${this.zip}`;
                    break;
                }

                case 'administrative_area_level_1': {
                    this.state = component.short_name;
                    break;
                }

                case 'subpremise': {
                    this.street2 = component.long_name;
                    break;
                }
            }
        }

        // get get city is trickier sometimes; first try locality, then try admin level 3 - most common places
        let cityComponent =
            place.address_components.find(c => c.types.includes('locality')) ||
            place.address_components.find(c => c.types.includes('administrative_area_level_3'));

        // try and get it from sublocality - this can happen in NYC, etc
        // from google's docs: "Brooklyn and other parts of New York City do not include the city as part of the address. Instead, they use sublocality_level_1."
        if (!cityComponent) {
            cityComponent = place.address_components.find(c => c.types.includes('sublocality_level_1'));
        }

        // if we're still null, try getting from neighborhood - some places in FL are like this? "Land O Lakes, FL"
        if (!cityComponent) {
            cityComponent = place.address_components.find(c => c.types.includes('neighborhood'));
        }

        if (cityComponent) {
            this.city = cityComponent.long_name;
        } else {
            this.invalid = true;
            this.invalidReason = 'No city found';
        }

        // validate each field is filled/present after being parsed
        if (this.street.length == 0 || this.city.length == 0 || this.state.length == 0 || this.zip.length == 0) {
            this.invalid = true;
            this.invalidReason = 'Missing street, city, state, or zip';
        }
    }
}
