/**
 * Interface representing the structure of Optimizely IDs.
 */
export interface OptimizelyExperimentIds {
    /** The unique id of the page in Optimizely (also called pageName). */
    apiName: string;
    /** The ID of the experiment. */
    experimentId: string;
    /** The ID of the control group. */
    controlId: string;
    /** The ID of the variant group. */
    variantId: string;
};

/**
 * Optimizely IDs for the PMG-6172 experiment.
 *
 * @type {OptimizelyExperimentIds}
 */
export const PMG_6172_Porch_Home_Warranty: OptimizelyExperimentIds = {
    apiName: '5088625092722688_porch_60_day_warranty_add_on',
    experimentId: '4518697922985984',
    controlId: '5644597829828608',
    variantId: '4915756039667712',
};

/**
 * Optimizely IDs for the PMG-6172 experiment.
 *
 * @type {OptimizelyExperimentIds}
 */
export const PMG_6424_MP_Homepage_Funnel: OptimizelyExperimentIds = {
    apiName: '5088625092722688_url_targeting_for_homepage_cta_test',
    experimentId: '5901551697395712',
    controlId: '5202223345434624',
    variantId: '5303059346096128',
};

/**
 * Optimizely IDs for the PMG-6172 experiment.
 *
 * @type {OptimizelyExperimentIds}
 */
export const PMG_6747_MP_LD_Funnel: OptimizelyExperimentIds = {
    apiName: '5088625092722688_url_targeting_for_split_test_long_distance_form',
    experimentId: '5627172275093504',
    controlId: '4996422739689472',
    variantId: '6603223826432000',
};
