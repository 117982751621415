import { Flex } from '@shared';
import { Link } from 'react-router-dom';
import { BrandPhoneNumber } from '@components';
import { BrandType, IBrandInformationDto } from '../generated/swaggerClient';
import { Alert } from '@lemonsqueezy/wedges';
import { Urls } from '@utils';
export const BrandedNav = ({ brandInfo }: { brandInfo: IBrandInformationDto }) => {
    let homePageUrl = window.location.origin;
    const onCheckout = window.location.pathname === Urls.checkout
    if (homePageUrl.includes('checkout-')) {
        homePageUrl = homePageUrl.replace("checkout-", "");
    }
    else if (homePageUrl.includes('checkout.')) {
        homePageUrl = homePageUrl.replace("checkout.", "");
    }

	return (
		// in the future we can add things like dark:bg-coolGrey-950 dark:text-white and control if the user prefers dark mode
		<nav className='w-full border-b border-hahGrey backdrop-blur-xl bg-white'>
            {onCheckout && <Alert before={<></>} className="bg-wg-blue-600 rounded-none" title={<div className="w-full flex justify-center lg:items-center"><p className="text-white">Save <strong>$50</strong> Today on Your Local Full Service Move! Enter the code: <strong>SAVE50LFS</strong> at checkout to claim your discount</p></div>} closable /> }
			<div className='container px-5 mx-auto'>
                <Flex justify='between' align='center' className='h-[68px] md:h-20'>
                    <Link to={homePageUrl} className='flex-shrink-0 me-0'>
                        {
                            brandInfo.brand !== BrandType.Unknown &&
                            <picture>
                                <source media='(max-width: 576px)' srcSet={`${brandInfo.mobileLogoUrl}, ${brandInfo.mobileLogo2xUrl} 2x`} width='36' height='41' />
                                <img
                                    src={`${brandInfo.logoUrl}`}
                                    srcSet={`${brandInfo.logoUrl}, ${brandInfo.logo2xUrl} 2x`}
                                    width='193'
                                    height='40'
                                    className='inline-block align-top'
                                    alt={brandInfo.companyName}
                                />
                            </picture>
                        }
                    </Link>
                    <BrandPhoneNumber />
				</Flex>
			</div>
            
		</nav>
	);
};
