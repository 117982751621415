import { BrandPhoneNumber } from "@components";

/**
 * FatalErrorForm component displays a user-friendly error message when a fatal error occurs.
 */
export const FatalErrorForm = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <h1 className="text-3xl font-bold text-red-600 mb-4">Something went wrong</h1>
            <p className="text-lg text-gray-700 mb-6 text-center">
                We're sorry, but an unexpected error has occurred. Please try again later, or give us a call at <BrandPhoneNumber />
            </p>
        </div>
    );
};
