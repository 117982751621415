import { TimeSelectionForm } from "@components";
import { UpdateStartTimeDto } from "@generated/swaggerClient";
import { useCheckoutClientFactory, useCheckoutNavigation, useCheckoutState } from "@hooks";
import { sentryWrapper } from "@shared";
import { TimeSelectionFormModel } from "@types";
import { js2 } from "@utils";
import { FormikHelpers } from "formik";

export const TimePage = () => {
    const { onNavigate } = useCheckoutNavigation();
    const { model, setModel } = useCheckoutState();
    const { wrapApiCall } = useCheckoutClientFactory();

    const handleChange = async (values: TimeSelectionFormModel, formikHelpers: FormikHelpers<TimeSelectionFormModel>) => {
        setModel({
            preferredStartTime: values.preferredStartTime
        });

        const dto = UpdateStartTimeDto.fromJS({ ...values });
        const result = await wrapApiCall((client) => client.dateTimePreferredStartTime(model.id, dto), formikHelpers);

        if (!result.success) {
            sentryWrapper.logException(result.errorObj, 'error', 'Error updating preferred start time', { component: 'TimePage' }, { id: model.id, dto: js2(dto) });
        }

        return result.success;
    }

    return <TimeSelectionForm
        onSubmitted={onNavigate}
        onChange={handleChange}
        model={{ preferredStartTime: model.preferredStartTime }}
        draftQuoteID={model.id}
        providerName={model.selection.providerName} />;
}