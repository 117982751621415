import React, { useRef } from 'react';
import { Client } from 'braintree-web';
import { useGooglePayButton } from '@hooks';

interface GooglePayButtonProps {
    googleMerchantID: string;
    clientInstance: Client;
    grandTotal: string;
    handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void;
}

export const GooglePayButton: React.FC<GooglePayButtonProps> = ({
    googleMerchantID,
    clientInstance,
    grandTotal,
    handlePaymentMethodApproved,
}) => {
    // Refs for the GooglePay button containers
	const googlePayButtonRef = useRef<HTMLDivElement | null>(null);

    // Use the hook to handle button setup and logic
    useGooglePayButton(googleMerchantID, clientInstance, grandTotal, googlePayButtonRef, handlePaymentMethodApproved);

    return (
        <>
            {/* Containers for GooglePay buttons */}
            <div className='w-[345px]' id='google-pay-button' ref={googlePayButtonRef} />
        </>
    );
};