import { Address, UpdateLocationDetailsDto, HeavyItemOptionsDto, UpdateContactDto, CheckoutQuoteProviderDto } from '../generated/swaggerClient';
import { AutocompleteAddress, AddressDetailsModel, HeavyItemOptionsModel, ContactModel, QuoteProviderInfo } from '../types';

export function mapAddress(apiAddress: Address): AutocompleteAddress {
    const { street, street2, city, state, zip } = apiAddress;
    return {
        street,
        street2: street2 || '',
        city,
        state,
        zip,
        fullAddress: getFullAddress(apiAddress),
        placeId: street && city && state && zip ? '1234' : '',
    };
}

export function getFullAddress(apiAddress: Address): string {
    const { street, street2, city, state, zip } = apiAddress;
    return `${street}, ${street2 ? street2 + ', ' : ''}${city}, ${state}, ${zip}`;
}

export function mapAddressDetails(apiAddressDetails: UpdateLocationDetailsDto): AddressDetailsModel {
    const { type, bedrooms, hasElevator, stairs, street2 } = apiAddressDetails;
    return {
        bedrooms: bedrooms === -1 ? undefined : bedrooms,
        hasElevator: hasElevator ?? false,
        propertyType: type,
        stairs: stairs === -1 ? undefined : stairs,
        street2: street2,
    };
}

export function mapHeavyItems(apiHeavyItems: HeavyItemOptionsDto): HeavyItemOptionsModel {
    const { hasBabyGrandPiano, hasMarbleFurniture, hasPianoUpright, hasPoolTable, hasSafe, hasStove, heavyItemBetween300and450lbsQty, heavyItemBetween450and600lbsQty, heavyItemOver600lbsQty } = apiHeavyItems;
    const hasOtherHeavyItems = [heavyItemBetween300and450lbsQty, heavyItemBetween450and600lbsQty, heavyItemOver600lbsQty].some(qty => qty && qty > 0);
    return {
        hasBabyGrandPiano: !!hasBabyGrandPiano,
        hasSafe: !!hasSafe,
        hasPianoUpright: !!hasPianoUpright,
        hasPoolTable: !!hasPoolTable,
        hasStove: !!hasStove,
        other: hasOtherHeavyItems,
        hasMarbleFurniture: !!hasMarbleFurniture,
        heavyItemBetween300and450lbsQty: heavyItemBetween300and450lbsQty ?? 0,
        heavyItemBetween450and600lbsQty: heavyItemBetween450and600lbsQty ?? 0,
        heavyItemOver600lbsQty: heavyItemOver600lbsQty ?? 0
    };
}

export function mapContact(apiContact: UpdateContactDto): ContactModel {
    const { firstName, lastName, email, phone } = apiContact;
    return {
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        email: email ?? '',
        phone: phone ?? '',
    };
}

export function mapProvider(apiProvider: CheckoutQuoteProviderDto): QuoteProviderInfo {
    const { providerLocationId, crewSize, hours, grandTotal, transportOptionId, providerId, providerListQueryID, providerIndex } = apiProvider;
    return {
        providerID: providerId,
        providerLocationID: providerLocationId,
        movers: crewSize,
        hours,
        grandTotal,
        transportOptionId,
        providerIndex,
        providerListQueryID,
    };
}
