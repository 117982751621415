import { BrandPhoneNumber } from '@components';
import { sentryWrapper } from '@shared';
import { Urls } from '@utils';
import { Link, useLocation } from 'react-router-dom';

/**
 * Handles routes that don't match any of our defined routes.
 */
export const NoMatchRoute = () => {
    const location = useLocation();
    /*     // Check if the path ends with a trailing slash and doesn't match the root path ("/")
    const hasTrailingSlash = location.pathname.endsWith('/') && location.pathname !== '/';

    if (hasTrailingSlash) {
        // Redirect to the same path without the trailing slash
        return <Navigate to={location.pathname.slice(0, -1)} />;
    } */

    // log to sentry, and show a generic 404 page
    sentryWrapper.logWarn(`404: ${location.pathname}`, { component: 'NoMatchRoute' });

    // FUTURE: this should not show the header...
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-4">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">Uh oh! This page seems to be lost.</h1>
            <p className="text-lg text-gray-600 mb-4">We looked everywhere, but couldn't find the page you're after. Maybe it took a detour or decided to go on an adventure?</p>
            <p className="text-lg text-gray-600 mb-4">
                Please give us a call at <BrandPhoneNumber /> if you would like to talk to us directly, or you can try <Link to={Urls.locations.index} className="text-blue-500 hover:underline">starting your journey over</Link>.
            </p>
        </div>
    );
};
