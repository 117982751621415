export function countWords(s: string): number {
    s = s.replace(/(^\s*)|(\s*$)/gi, ''); //exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, ' '); //2 or more space to 1
    s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
    return s.split(' ').length;
}

/**
 * calculates how long some text should take to read given xxx words per minute average
 * @param stringToRead
 */
export function millisecondsToRead(stringToRead: string): number {
    const totalWords = countWords(stringToRead);
    const minutesToRead = totalWords / 75; // wpm - 238 is really the average but im dumbing it down - lets act like they can only do 75 wpm
    return Math.ceil(minutesToRead * 60_000);
}