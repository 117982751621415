import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Textarea } from "@lemonsqueezy/wedges";
import {
    ChangeEvent,
    forwardRef,
} from "react";

interface TextareaProps {
    name: string;
    icon?: IconDefinition;
    label?: string;
    className?: string;
    description?: string;
    required?: boolean;
    placeholder?: string;
    value?: string;
    id?: string;
    maxLength?: number;
    disabled?: boolean;
    destructive?: boolean;
    helperText?: string;
    ref?: React.RefObject<HTMLTextAreaElement>;
    onChange: (value: string) => void;
}

export const HahTextarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ onChange, ...rest }, ref) => {

        const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
            onChange(e.target.value);
        };

        return (
            <Textarea
                {...rest}
                ref={ref} // Pass the forwarded ref
                onChange={(e) => handleChange(e as ChangeEvent<HTMLTextAreaElement>)}
            />
        );
    }
);
