import classNames from "classnames";

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    direction?: 'row' | 'col';
    align?: 'start' | 'center' | 'end' | 'stretch';
    justify?: 'start' | 'center' | 'end' | 'between' | 'around';
    grow?: number | undefined;
    shrink?: number | undefined;
}

export const Flex = ({
    children,
    direction = 'row',
    align,
    justify,
    grow,
    shrink,
    ...props
}: FlexProps) => {
    const directionClass = direction === 'col' ? 'flex-col' : 'flex-row';
    const alignClass = align ? `items-${align}` : '';
    const justifyClass = justify ? `justify-${justify}` : '';
    // Apply grow and shrink based on props, if it is undefined we will not apply the class
    const growClass = grow === 1 ? 'flex-grow' : grow === 0 ? 'flex-grow-0' : '';
    const shrinkClass = shrink === 1 ? 'flex-shrink' : shrink === 0 ? 'flex-shrink-0' : '';

    // Destructure className out of props to prevent overwriting
    const { className: propsClassName, ...restProps } = props;

    const classnames = classNames([propsClassName, 'max-w-full flex', directionClass, alignClass, justifyClass, growClass, shrinkClass]);
    return (
        <div className={classnames} {...restProps}>
            {children}
        </div>
    );
};

interface FlexItemProps {
    children?: React.ReactNode;
    size?: number;
    className?: string;
}
export const FlexItem = ({ children, size = 12, className = '' }: FlexItemProps) => {
    const widthClass = `w-${size === 12 ? 'full' : `${size}/12`}`;

    return <div className={`${widthClass} ${className}`}>{children}</div>;
};
