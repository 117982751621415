import React from 'react';
import { BtPaymentInfo } from '@components';
import { Flex } from '@shared';
import { HostedFields } from 'braintree-web';
type tokenizeFunc = HostedFields['tokenize'];

interface PaymentInfoProps {
	tokenizeRef: React.MutableRefObject<tokenizeFunc | undefined>;
    btClientToken: string;
    deviceData: React.MutableRefObject<string | null>;
}

export const PaymentInfo = ({ tokenizeRef, btClientToken, deviceData }: PaymentInfoProps) => {

	return (
		<div className='relative'>
			<Flex grow={1} direction='col' className='p-4 bg-white rounded-3xl shadow'>
				<Flex direction='row' className=''>
					<h2 className='mb-0 flex-auto'>Payment <span className='text-red-600'>*</span></h2>
					<img className='' src='https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png' alt='BrainTree Badge' width='89' data-pin-nopin='true' />
				</Flex>
				<p className='mb-4'>Your payment information is encrypted and secure</p>
                <BtPaymentInfo btClientToken={btClientToken} tokenizeRef={tokenizeRef} deviceData={deviceData} />
			</Flex>
		</div>
	);
};
