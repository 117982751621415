import { useRef } from 'react';
import { Select, SelectContent, SelectGroup, SelectIcon, SelectItem, SelectPortal, SelectTrigger, SelectValue } from '@lemonsqueezy/wedges';
import classNames from 'classnames';
import { Option } from '../types';
import { roundedClassMap } from '@shared';

interface HahSelectProps<T> {
    id?: string;
    label: string;
    placeholder?: string;
    defaultValue?: T;
    required?: boolean;
    tooltip?: string;
    options: Option<T>[] | string[];
    onChange: (value: T) => void;
    value?: T;
    inline?: boolean;
    destructive?: boolean;
    helperText?: string;
    cols?: number;
    rounded?: ('topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight')[];
}
function isStringArray<T>(options: Option<T>[] | string[]): options is string[] {
    return typeof options[0] === 'string';
}
function normalizeOptions<T>(options: Option<T>[] | string[]): Option<T>[] {
    if (isStringArray(options)) {
        return options.map(str => ({ label: str, value: str as unknown as T }));
    } else {
        return options;
    }
}

export const HahSelect = <T,>({
    id,
    label,
    required,
    tooltip,
    placeholder = 'Select an option',
    onChange,
    options,
    value,
    inline,
    destructive,
    helperText,
    cols,
    rounded = [],
}: HahSelectProps<T>) => {
    const wrapper = useRef<HTMLDivElement>(null);

    const transformedOptions = normalizeOptions(options);

    const handleChange = (stringValue: string) => {
        const selectedOption = transformedOptions.find(option => option.value == stringValue || JSON.stringify(option.value ?? '') == stringValue);
        if (selectedOption) {
            onChange(selectedOption.value);
        }
    };

    const getDisplayLabel = (value: T | undefined): string => {
        if (typeof value === 'boolean') {
            return value ? 'Yes' : 'No';
        }

        return transformedOptions.find(option => option.value === value)?.label ?? placeholder;
    };

    const roundedClasses = rounded.map(key => roundedClassMap[key] || '').join(' ');
    return (
        <div
            ref={wrapper}
            className={classNames(
                'flex-col gap-10',
                inline ? 'inline-flex max-w-[196px]' : 'max-w-full',
                rounded.length > 0 ? `!rounded-none ${roundedClasses}` : '',
                cols ? `col-span-${cols}` : ''
            )}>
            <Select
                helperText={helperText}
                destructive={destructive}
                id={id}
                label={label}
                tooltip={tooltip}
                required={required}
                onValueChange={handleChange}
                value={value && JSON.stringify(value)} // Convert value to string
            >
                <SelectTrigger
                    className={classNames(
                        'focus:-outline-offset-2 data-[state=open]:-outline-offset-2 min-w-full bg-white',
                        inline ? 'inline-flex max-w-[196px]' : 'max-w-full',
                        rounded.length > 0 ? `!rounded-none ${roundedClasses}` : ''
                    )}>
                    <SelectValue placeholder={placeholder}>{getDisplayLabel(value)}</SelectValue>
                    <SelectIcon />
                </SelectTrigger>

                <SelectPortal container={wrapper.current}>
                    <SelectContent
                        className='max-h-48'
                        showScrollButtons
                        // fixes bug w/ mobile where a button under this would be clicked when the select option is clicked https://github.com/radix-ui/primitives/issues/1658#issuecomment-2278473430
                        ref={ref => {
                            if (ref) {
                                ref.addEventListener('touchend', e => e.preventDefault());
                            }
                        }}>
                        <SelectGroup>
                            {transformedOptions.map((option, index) => (
                                <SelectItem key={`${id || 'select-item'}-${index}`} value={JSON.stringify(option.value)}>
                                    {' '}
                                    {/* Convert option.value to string */}
                                    {option.label}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </SelectPortal>
            </Select>
        </div>
    );
};
