import { FatalErrorForm, ProviderSummary } from '@components';
import {
    ProviderListResult,
	ProviderSearch
} from '@generated/swaggerClient';
import { useCheckoutClientFactory, useCheckoutState, useProviderListState } from '@hooks';
import { Flex, HahSelect, RecommendedProviderSortOrder, sentryWrapper, sortingOptions, sortProviders, useEffectAsync } from '@shared';
import { debugLoggerInfo, js2 } from '@utils';
import React, { useMemo, useState } from 'react';

export const ProviderList = () => {
	const { model } = useCheckoutState();
	const { wrapApiCall } = useCheckoutClientFactory();
	const [providersList, setProvidersList] = React.useState<ProviderListResult>();
    const [isFatalError, setIsFatalError] = useState<boolean>(false);

	useEffectAsync(async () => {
        debugLoggerInfo('ProviderList.tsx Getting providers list via useEffectAsync', { draftQuoteId: model.id });
        const getAndSetProvidersList = async () => {
            const request = new ProviderSearch({
                overrideCrewSize: undefined,
                overrideLaborHours: undefined,
            });
            const result = await wrapApiCall(client => client.providersGetProviders(model.id, request));

            if (!result.success) {
                // we experienced some server error
                sentryWrapper.logException(result.errorObj, 'error', 'Get providers server call failed', { component: 'ProviderList' }, { draftQuoteId: model.id, request: js2(request) });

                setIsFatalError(true);
                return;
            }

            setProvidersList(result.result!);
        };

		await getAndSetProvidersList();
	}, [model.id]);

    if (isFatalError) {
        return <FatalErrorForm />;
    }

    if (!providersList) {
        // We don't need to show a spinner here because when we're fetching providers, we're already showing a spinner
        return null;
    }

	return <div>{providersList.providers.length > 0 && <ProviderListDetails list={providersList} />}</div>;
};

const ProviderListDetails = ({ list }: { list: ProviderListResult }) => {
    const { selectedProviderSortOrder, setSelectedProviderSortOrder } = useProviderListState();

    const sortedProviders = useMemo(() => sortProviders(list.providers, selectedProviderSortOrder), [
        list.providers,
        selectedProviderSortOrder,
    ]);

    function handleSortOrderChange(value: string): void {
        let foundOption = sortingOptions.find(option => option.label === value)?.value;
        if (!foundOption) {
            // this should never happen, but lets log it and fail gracefully for the user
            sentryWrapper.logError('Could not find sorting option; falling back to default', { component: 'ProviderListDetails' }, { value });
            foundOption = RecommendedProviderSortOrder;
        }
        setSelectedProviderSortOrder(foundOption);
    }

	return (
		<div>
			<Flex direction='row' align='center' className='w-[576px] px-3 mb-6'>
				<h3 className='flex-grow'>{list.providers.length} Movers available</h3>
				<HahSelect
					label={''}
					options={sortingOptions.map(option => option.label)}
					onChange={handleSortOrderChange}
					value={sortingOptions.find(option => option.value === selectedProviderSortOrder)?.label}
				/>
			</Flex>
			{sortedProviders!.map((provider, i) => (
				<div key={i}>
					<ProviderSummary provider={provider} showSelect={true} />
				</div>
			))}
		</div>
	);
};