import { ApiCallResult, useCheckoutClientFactory } from '@hooks';
import { Spinner, useEffectAsync } from '@shared';
import { useState } from 'react';
import { environmentName, releaseVersion } from '../pmgConfig';

export const DebugPage = () => {
    const { wrapApiCall } = useCheckoutClientFactory();

    const [versionResponse, setVersionResponse] = useState<ApiCallResult<string>>();

    useEffectAsync(async () => {
        setVersionResponse(await wrapApiCall(client => client.versionGetVersion()));
    }, []);

    // FUTURE: we will want to maybe wire up storing the version in localStorage - and then periodically check (maybe per page request) - and if version is out of sync, prompt user to refresh to get latest code
    // for checkout, this may not matter - but for other SPA apps we're building, it will

    if (!versionResponse) {
        return <Spinner />;
    }

    if (!versionResponse?.success) {
        return <div>Error loading debugging information.</div>;
    }

    return (
        <>
            <h1>Debug Info</h1>
            <div>Envrionment: {environmentName}</div>
            <div>Server Version: {versionResponse.result!}</div>
            <div>Client Version: {releaseVersion}</div>
        </>
    );
};
