import { DateTime } from 'luxon';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import '../styles/calendar.scss';
import { longDistanceDisabledDaysRules } from '@utils';
interface CalendarProps {
	moveDate?: string;
	onChange: (date: string) => void;
    isLongDistance?: boolean;
}
const rightArrow =
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M443.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 240 16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0L244.7 420.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l176-176z"/></svg>';
const arrowLeft =
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M12.7 244.7L1.4 256l11.3 11.3 168 168L192 446.6 214.6 424l-11.3-11.3L62.6 272 432 272l16 0 0-32-16 0L62.6 240 203.3 99.3 214.6 88 192 65.4 180.7 76.7l-168 168z"/></svg>';

export const Calendar = ({ moveDate, onChange, isLongDistance }: CalendarProps) => {
    const minDatePicker = DateTime.now().plus({ days: 1 }).toISODate();
    const ldRules = longDistanceDisabledDaysRules();

	return (
		<>
			<div className='w-full rounded-lg shadow-lg p-4 bg-white'>
				<Flatpickr
					className='hidden'
					data-enable-time={false}
					value={moveDate}
					options={{
						nextArrow: rightArrow,
						prevArrow: arrowLeft,
						inline: true,
						minDate: minDatePicker as string, //to prevent same or next day orders.
						enableTime: false,
						allowInvalidPreload: true, // Because IOS doesn't allow minDate... this allows iOS users to select a previous date and not have the field look empty https://github.com/flatpickr/flatpickr/issues/1224
                    disable: isLongDistance ? ldRules : [],
					}}
					onDayCreate={(dObj, dStr, fp, dayElem) => {
						if (dayElem.classList.contains('flatpickr-disabled')) {
							dayElem.classList.add('disabled');
                        }
                        // Example logic to add classes based on availability
						//const date = dayElem.dateObj;
						// } else if (date) {
						// 	dayElem.classList.add('best-value');
						// } else if (date == 'popular') {
						// 	dayElem.classList.add('popular');
						// } else if (date == 'unavailable') {
						// 	dayElem.classList.add('unavailable');
						// }
					}}
					onChange={(_, currentDate) => {
						onChange(currentDate);
					}}
				/>
				{/* FUTURE: Calendar Legends V2 */}
				{/* <div className='flex flex-col items-start mt-4 text-sm'>
					<div className='flex items-center'>
						<div className='h-6 w-6 border border-neutralGrey-200 bg-white mr-2 p-1 text-center'>
							<span className='h-3 w-3 rounded-full bg-green-500 inline-block' />
						</div>
						<span>Best value and most availability</span>
					</div>
					<div className='flex items-center mt-1'>
						<div className='h-6 w-6 border border-neutralGrey-200 bg-white mr-2 p-1 text-center'>
							<span className='h-3 w-3 rounded-full bg-yellow-500 inline-block' />
						</div>
						<span>Popular move day</span>
					</div>
					<div className='flex items-center mt-1'>
						<div className='h-6 w-6 border border-neutralGrey-200 bg-white mr-2 p-1 text-center'>
							<span className='h-3 w-3 rounded-full bg-neutralGrey-400 inline-block' />
						</div>
						<span>Unavailable</span>
					</div>
				</div> */}
			</div>
		</>
	);
};
