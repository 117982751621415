import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultState, useCheckoutClientFactory, useCheckoutState } from '@hooks';
import { performDataLayerLog, sentryWrapper } from '@shared';
import { mapAddress, mapAddressDetails, mapContact, mapHeavyItems, mapProvider, Urls } from '@utils';
import { CheckoutModel } from '../types';
import { heavyItemsValidationSchema, locationDetailsValidationSchema, locationsValidationSchema, dateValidationSchema } from '@modules';

export function useGetQuote() {
    const { setModel } = useCheckoutState();
    const { wrapApiCall } = useCheckoutClientFactory();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);

    async function loadCheckoutQuoteAndNavigate() {
        if (!id) return;
        setLoading(true);
        const result = await wrapApiCall(client => client.quoteGet(id));
        if (result.success) {
            const checkoutQuote = result.result!;
            const heavyItemsData = checkoutQuote.heavyItems.heavyItemOptions;

            // If quote is inactive, send the user away.
            if (checkoutQuote.hasActiveQuote != null && checkoutQuote.hasActiveQuote === false) {
                navigate('/locations?redirectedfrom=continue-page&error=expired', { replace: true });
                return;
            }

            // user is from MP homepage CTA
            let fastMode = false;
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get('fast') === '1') {
                fastMode = true;
            }
            const checkoutModelTemp: CheckoutModel = {
                ...defaultState.model,
                id: checkoutQuote.id,
                selection: mapProvider(checkoutQuote.selectedProvider),
                recommendation: mapProvider(checkoutQuote.recommendedProvider),
                heavyItems: {
                    heavyItemOptions: mapHeavyItems(heavyItemsData!),
                },
                loadAddress: mapAddress(checkoutQuote.loadAddress),
                unloadAddress: mapAddress(checkoutQuote.unloadAddress),
                loadAddressDetails: mapAddressDetails(checkoutQuote.loadAddressDetails),
                unloadAddressDetails: mapAddressDetails(checkoutQuote.unloadAddressDetails),
                preferredStartTime: checkoutQuote.preferredStartTime,
                jobDate:
                    checkoutQuote.jobDate && checkoutQuote.jobDate !== '0001-01-01'
                        ? checkoutQuote.jobDate
                        : undefined,
                manualAddressEntry: checkoutQuote.manualAddressEntry,
                contact: checkoutQuote.contact ? mapContact(checkoutQuote.contact) : undefined,
                isLongDistance: false,
                discountCode: checkoutQuote.discountCode,
                discountAmount: checkoutQuote.discountAmount,
                fastMode: fastMode,
            } as CheckoutModel;

            // Set the model in context
            setModel({ ...checkoutModelTemp });

            // Check validations
            const locationsPageIsValid = await locationsValidationSchema.isValid({
                loadAddress: checkoutModelTemp.loadAddress,
                unloadAddress: checkoutModelTemp.unloadAddress,
                manualAddressEntry: false
            }, { abortEarly: false });

            const loadLocationDetailsPageIsValid = await locationDetailsValidationSchema.isValid({
                propertyType: checkoutModelTemp.loadAddressDetails.propertyType,
                bedrooms: checkoutModelTemp.loadAddressDetails.bedrooms,
                stairs: checkoutModelTemp.loadAddressDetails.stairs,
                hasElevator: checkoutModelTemp.loadAddressDetails.hasElevator,
                street2: checkoutModelTemp.loadAddressDetails.street2,
            }, { abortEarly: false });

            const unloadLocationDetailsPageIsValid = await locationDetailsValidationSchema.isValid({
                propertyType: checkoutModelTemp.unloadAddressDetails.propertyType,
                bedrooms: checkoutModelTemp.unloadAddressDetails.bedrooms,
                stairs: checkoutModelTemp.unloadAddressDetails.stairs,
                hasElevator: checkoutModelTemp.unloadAddressDetails.hasElevator,
                street2: checkoutModelTemp.unloadAddressDetails.street2,
            }, { abortEarly: false });

            const heavyItemsPageIsValid = await heavyItemsValidationSchema.isValid({
                heavyItemOptions: checkoutModelTemp.heavyItems.heavyItemOptions,
            }, { abortEarly: false });

            const datePageIsValid = await dateValidationSchema.isValid({
                date: checkoutModelTemp.jobDate,
            }, { abortEarly: false });

            setLoading(false);

            // Check for long distance before we determine where to send them
            if (locationsPageIsValid) {
                const isLongDistanceResult = await wrapApiCall((client) => client.locationsIsLongDistance(checkoutModelTemp.loadAddress.zip, checkoutModelTemp.unloadAddress.zip));

                if (isLongDistanceResult.success) {
                    checkoutModelTemp.isLongDistance = isLongDistanceResult.result ?? false;
                    setModel({ isLongDistance: isLongDistanceResult.result });

                    // update ga4 data - per John B wants to know immediately
                    performDataLayerLog({
                        is_long_distance: isLongDistanceResult.result
                    }, true);
                } else {
                    // FUTURE: what if it wasnt successful?? do we not let them proceed?
                    // i guess log to sentry for now..
                    sentryWrapper.logException(isLongDistanceResult.errorObj, 'error', 'locationsIsLongDistance came back non-successful', { component: 'ContinuePage' }, { loadZip: checkoutModelTemp.loadAddress.zip, unloadZip: checkoutModelTemp.unloadAddress.zip });
                }
            }

            // If we know they're long distance, send them to the long distance page, otherwise navigate based on validation
            if (checkoutModelTemp.isLongDistance) {
                navigate(Urls.quote.longDistance, { replace: true });
            }
            else if (locationsPageIsValid && loadLocationDetailsPageIsValid && unloadLocationDetailsPageIsValid && heavyItemsPageIsValid && datePageIsValid) {
                navigate(Urls.quote.index, { replace: true });
            } else if (locationsPageIsValid) {
                navigate(Urls.locations.load, { replace: true });
            } else {
                navigate('/locations?redirectedfrom=continue-page', { replace: true });
            }
        } else {
            sentryWrapper.logException(result.errorObj, 'warning', 'Error fetching checkout quote; falling back to locations page', { component: 'ContinuePage' }, { id });
            navigate('/locations?redirectedfrom=continue-page', { replace: true });
        }
    }

    return { loading, loadCheckoutQuoteAndNavigate };
}
