import { iconLibrary, Icon } from '@shared';
import React from 'react';

// Copied from HAH-Shared React project, but using new <Icon> component
export const StarDisplay = ({ rating, id }: { rating: number; id: number }) => {
    const stars = [];

    if (rating === 0) {
        return (
            <React.Fragment>
                <Icon className='text-yellow-400' icon={iconLibrary.farStar} />
                <Icon className='text-yellow-400' icon={iconLibrary.farStar} />
                <Icon className='text-yellow-400' icon={iconLibrary.farStar} />
                <Icon className='text-yellow-400' icon={iconLibrary.farStar} />
                <Icon className='text-yellow-400' icon={iconLibrary.farStar} />
            </React.Fragment>
        );
    }

    for (let i = 1; i <= 5; i++) {
        if (rating >= i) {
            stars.push(<Icon className='text-yellow-400' icon={iconLibrary.faStar} key={`star-${i}`} />);
        } else if (rating < i && rating > i - 1) {
            stars.push(<Icon className='text-yellow-400' icon={iconLibrary.faStarHalfAlt} key={`star-${i}`} />);
        } else {
            stars.push(<Icon className='text-yellow-400' icon={iconLibrary.farStar} key={`star-${i}`} />);
        }
    }

    return (
        <span title={`Rating: ${rating}`}>
            {stars.map((icon, i) => (
                <span className='mr-1' key={`review-${id}-rating-${i}`}>{icon}</span>
            ))}
        </span>
    );
};