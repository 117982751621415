import { useCheckoutState, useIsLongDistanceUrl, useBrandInfo } from "@hooks";
import { Icon, iconLibrary } from '@shared';
import { BrandType } from "../generated/swaggerClient";

interface BrandPhoneNumberProps {
    showIcon?: boolean;
}

export const BrandPhoneNumber = ({ showIcon = true }: BrandPhoneNumberProps) => {
    const isLongDistance = useCheckoutState(state => state.model.isLongDistance);
    const brandInfo = useBrandInfo();
    const brandPhone = useIsLongDistanceUrl() || isLongDistance ? brandInfo.longDistanceSalesPhoneNumber : brandInfo.salesPhoneNumber;

    return <a className='text-primary-600 text-base font-semibold whitespace-nowrap' href={`tel:${brandPhone?.cleanFormat}`}>
        {/* <span className='hidden md:inline'>Need help?</span> */}
        <span className='text-coolGrey-900 text-lg font-medium'>
            {showIcon && brandInfo.brand !== BrandType.Unknown && <Icon size='sm' className='pe-2' icon={iconLibrary.faPhone} />}
            {brandPhone?.displayFormat}
        </span>
    </a>;
}
