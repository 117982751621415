import { DateTime } from 'luxon';

// FUTURE: this really should come from the cancellation fee breakdown stuff, or be calculated by server in general
export const getCancellationDate = (jobDate: string) => {
    return DateTime.fromISO(jobDate).minus({ days: 2 }).toLocaleString(DateTime.DATE_MED);
};

// FUTURE: this all needs to be server side logic, and not calculated client side
// this is not 100% accurate because it's always using their local date/time, not the one at the job site.
export const isJobDateWithin48Hrs = (jobDateStr: string) => {
    const jobDate = DateTime.fromISO(jobDateStr);
    const now = DateTime.now();
    const diffInHours = now.diff(jobDate, 'hours').as('hours');
    return Math.abs(diffInHours) <= 48;
}