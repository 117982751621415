import { LocationDetailsForm } from "@components";
import { DraftQuoteLocationType, UpdateLocationDetailsDto } from "@generated/swaggerClient";
import { useCheckoutClientFactory, useCheckoutNavigation, useCheckoutState } from "@hooks";
import { sentryWrapper } from "@shared";
import { LocationDetailsFormModel } from "@types";
import { js2 } from "@utils";
import { FormikHelpers } from "formik";

export const UnloadLocationPage = () => {
    const { onNavigate } = useCheckoutNavigation();
    const { model, setModel } = useCheckoutState();
    const { wrapApiCall } = useCheckoutClientFactory();

    const handleChange = async (values: LocationDetailsFormModel, formikHelpers: FormikHelpers<LocationDetailsFormModel>) => {
        setModel({
            unloadAddressDetails: values
        });

        const dto = UpdateLocationDetailsDto.fromJS({
            type: values.propertyType,
            locationType: DraftQuoteLocationType.Destination,
            ...values
        });

        const result = await wrapApiCall((client) => client.locationsUpdateDetails(model.id, dto), formikHelpers);

        if (!result.success) {
            sentryWrapper.logException(result.errorObj, 'error', 'Error updating unload location details', { component: 'UnloadLocationPage' }, { id: model.id, dto: js2(dto) });
        }

        return result.success;
    }

    return <LocationDetailsForm onSubmitted={onNavigate} onChange={handleChange} model={{...model.unloadAddressDetails, street2: model.unloadAddressDetails.street2 ?? model.unloadAddress.street2}} address={model.unloadAddress.fullAddress!} label='Destination Address' imgSrc='/img/unload-location.svg' imgAlt='Unload location icon' />;
}