import { debugLoggerInfo } from '@utils';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Redirects to a path without a trailing slash if one is requested.
 */
export const useRedirectWhenTrailingSlash = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check if the path ends with a trailing slash and doesn't match the root path ("/")
        const hasTrailingSlash = location.pathname.endsWith('/') && location.pathname !== '/';

        if (hasTrailingSlash) {
            debugLoggerInfo('Redirecting to path without trailing slash', { pathName: location.pathname });
            // If a trailing slash exists, redirect to the path without the slash
            const newPath = location.pathname.slice(0, -1) + location.search + location.hash;
            navigate(newPath);
        }
    }, [location.hash, location.pathname, location.search, navigate]);
};
