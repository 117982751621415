import { FooterContent, Headings } from '@components';
import { Flex } from '@shared';


export const LdQuoteConfirmationPage = () => {
    return <div className='form-wrapper'>
        <Flex grow={1} direction='col' className='w-[576px] px-4'>
            <Headings
                primary='We are working on your quote...'
                secondary='We are working on a long-distance moving quote built just for you! A moving specialist will contact you shortly to review options and discuss your needs.'
                imgSrc=''
                imgAlt=''
            />
        </Flex>
        <FooterContent />
    </div>;
}