import { ProviderCard, ProviderSortOrder } from "@generated/swaggerClient";

export const sortingOptions = [
    { label: 'Best Match', value: ProviderSortOrder.BestMatch },
    { label: 'Quality Rating', value: ProviderSortOrder.QualityRatingDescending },
    { label: 'Most Reviewed', value: ProviderSortOrder.ReviewCountDescending },
    { label: 'Price (low to high)', value: ProviderSortOrder.PriceAscending },
    { label: 'Price (high to low)', value: ProviderSortOrder.PriceDescending },
];

export const sortProviders = (providers: ProviderCard[], selectedSort: ProviderSortOrder) => {
    return [...providers].sort((a, b) => {
        // these cannot be undefined, so no use trying to null coalesce
        const sortValueA = a.sortOrder[selectedSort]!;
        const sortValueB = b.sortOrder[selectedSort]!;

        return sortValueA - sortValueB; // Ascending order by default
    });
};
