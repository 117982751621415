import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@shared";

interface IconStackProps {
    firstIcon: IconDefinition;
    secondIcon: IconDefinition;
    containerClassName?: string;
    firstIconClassNames?: string;
    secondIconClassNames?: string;
}

export const IconStack: React.FC<IconStackProps> = ({ firstIcon, secondIcon, firstIconClassNames, secondIconClassNames, containerClassName  }) => {
    return <div className={`fa-stack ${containerClassName}`}>
        <Icon fixedWidth className={`fa-stack-2x ${firstIconClassNames}`} icon={firstIcon} />
        <Icon fixedWidth className={`fa-stack-1x ${secondIconClassNames}`} icon={secondIcon} transform='shrink-6' inverse />
    </div>;
}
