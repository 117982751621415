/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';
import braintree, { Client } from 'braintree-web';
import { TokenizePaymentOptions } from '@types';
import { sentryWrapper, toastError, useEffectOnlyOnce } from '@shared';

export const usePayPalButton = (
    displayName: string,
    merchantAccount: string,
    clientInstance: Client | null,
    grandTotal: string,
    handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void
) => {

    // FUTURE: SG: These being callbacks won't matter if we re-render the whole component every time the payment method changes, but if we want to be more efficient we should probably memoize them
    const setupRegularButton = useCallback(
        (btPaypalCheckoutInstance: braintree.PayPalCheckout) => {
            const tosCheck = document.querySelector('input[name="agreedToTos"') as HTMLInputElement;
            const regularPaypalSettings = {
                fundingSource: paypal.FUNDING.PAYPAL,
                style: {
                    shape: 'rect',
                    size: 'responsive',
                    label: 'pay',
                    height: 40,
                },
                createBillingAgreement: () => {
                    // this MUST be createBillingAgreement if you're doing vault flow
                    return btPaypalCheckoutInstance.createPayment({
                        //intent: 'tokenize',
                        flow: 'vault',
                        amount: grandTotal,
                        currency: 'USD',
                        offerCredit: false,
                        billingAgreementDescription: `You are authorizing us to withdraw funds related to your ${displayName}` + ' order.',
                        enableShippingAddress: false,
                        shippingAddressEditable: false,
                        displayName: displayName,
                    } as never); // The typings are dumb and if i use FlowType.Vault it won't allow it, so i have to cast it as any and use 'vault' as a string
                },
                onInit: function (data: any, actions: { disable: () => void; enable: () => void }) {
                    // Disable the buttons initially
                    actions.disable();

                    // Listen for clicks on the button
                    if (tosCheck) {
                        const observer = new MutationObserver(mutations => {
                            mutations.forEach(mutation => {
                                if (!(mutation.type === 'attributes' && mutation.attributeName === 'checked')) {
                                    return;
                                }
                                const isChecked = tosCheck.checked;
                                // const paypalSelected = paypalPaymentMethodRadio.checked;
                                // if (paypalSelected) {
                                // Enable or disable the PayPal button based on the state
                                if (isChecked) {
                                    actions.enable();
                                } else {
                                    actions.disable();
                                }
                                // }
                            });
                        });
                        observer.observe(tosCheck, { attributes: true, attributeFilter: ['checked'] });
                    }
                },
                onClick: () => {
                    // Check if the checkbox is checked
                    if (!tosCheck.checked) {
                        toastError({ title: 'Please agree to our Terms of Service before booking.' });
                    }
                },
                onApprove: (btTokenPaymentData: TokenizePaymentOptions, actions: unknown) => {
                    sentryWrapper.logInfo('PayPal payment approved', { hook: 'usePayPalButton' });
                    return btPaypalCheckoutInstance.tokenizePayment(btTokenPaymentData, (err, payload: braintree.PayPalTokenizePayload) => {
                        if (err) {
                            sentryWrapper.logException(err, 'error', 'PayPal tokenize payment error', { hook: 'usePayPalButton' });

                            toastError({ title:
                                "We're sorry, there appears to have been a problem with the PayPal payment. You may want to try again, or try another form of payment."
                            });

                            return;
                        }

                        handlePaymentMethodApproved(
                            payload.nonce,
                            payload.details.billingAddress?.postalCode,
                            `${payload.details.firstName} ${payload.details.lastName}`
                        );
                    });
                },
                onCancel: (cancelData: unknown) => {
                    // log cancel event
                    sentryWrapper.logInfo('PayPal payment canceled', { hook: 'usePayPalButton' });
                },
                onError: (err: any) => {
                    sentryWrapper.logException(err, 'error', 'PayPal payment error', { hook: 'usePayPalButton' });
                    toastError({ title:
                        "We're sorry, there appears to have been a problem with the PayPal payment. You may want to try again, or try another form of payment."
                    });
                },
            };

            const paypalBtn = paypal.Buttons(regularPaypalSettings as any) as any;
            //const paypalButtonElement = document.getElementById('paypal-button');

            if (paypalBtn.isEligible()) {
                paypalBtn.render('#paypal-button');
            } else {
                sentryWrapper.logWarn('PayPal button not eligible', { hook: 'usePayPalButton' });

                toastError({ title:
                    "We're sorry, there appears to have been a problem with the PayPal payment. You may want to try again, or try another form of payment."
                });

                /*
				if (paypalButtonElement) {
					paypalButtonElement.innerText = '!!NOT ELIGIBLE FOR PAYPAL!!';
				} */
            }
        },
        [displayName, grandTotal, handlePaymentMethodApproved]
    );

    useEffectOnlyOnce(() => {
        if (!clientInstance) {
            sentryWrapper.logError('PayPal client instance not available', { hook: 'usePayPalButton' });
            toastError({ title: 'PayPal client instance not available' });
            return;
        }
        braintree.paypalCheckout.create({ client: clientInstance, merchantAccountId: merchantAccount }, (err, paypalInstance) => {
            if (err || !paypalInstance) {
                sentryWrapper.logException(err, 'error', 'Error creating PayPal instance', { hook: 'usePayPalButton' });
                toastError({ title: 'Error creating PayPal instance:' + err });
                return;
            }

            paypalInstance.loadPayPalSDK({ currency: 'USD', vault: true }, () => {
                setupRegularButton(paypalInstance);
                //setupPayPalPayLaterButton(paypalInstance, grandTotal, payLaterButtonRef, handlePaymentMethodApproved, logErrorAndAlert, logWarningAndAlert);
            });
        });
    });
};

/**
 * This configures and renders the new "PayPal Pay Later" button.
 */
function setupPayPalPayLaterButton(
    btPaypalCheckoutInstance: braintree.PayPalCheckout,
    grandTotal: string,
    buttonRef: React.MutableRefObject<HTMLDivElement | null>,
    handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void,
    logErrorAndAlert: (message: string, error: any) => void,
    logWarningAndAlert: (message: string, data: object) => void,
    displayName?: string
) {
    const payPalLaterButtonSettings = {
        fundingSource: 'PAYLATER', // FUTURE: this might be incorrect value, typings don't have paylater
        style: {
            shape: 'rect',
            size: 'responsive',
            label: 'pay',
            height: 40,
        },
        createOrder: () => {
            // this MUST be createOrder if you're doing checkout flow
            return btPaypalCheckoutInstance.createPayment({
                //intent: 'tokenize',
                flow: 'checkout', // checkout with vault flow
                requestBillingAgreement: true, // needed for checkout with vault flow
                // billingAgreementDetails: {
                //     description: displayName, // PAYLATER: shannon wants to try not setting this..
                // },
                amount: grandTotal,
                currency: 'USD',
                offerCredit: false,
                billingAgreementDescription: 'You are authorizing us to withdraw funds related to your ' + displayName + ' order.',
                enableShippingAddress: false,
                shippingAddressEditable: false,
                displayName: displayName,
            } as any);
        },
        onApprove: (btTokenPaymentData: TokenizePaymentOptions, actions: any) => {
            return btPaypalCheckoutInstance.tokenizePayment(btTokenPaymentData, (err, payload: braintree.PayPalTokenizePayload) => {
                if (err) {
                    logErrorAndAlert(
                        "We're sorry, there appears to have been a problem with the PayPal PayLater payment. You may want to try again, or try another form of payment.",
                        err
                    );

                    return;
                }

                handlePaymentMethodApproved(
                    payload.nonce,
                    payload.details.billingAddress?.postalCode,
                    `${payload.details.firstName} ${payload.details.lastName}`
                );
            });
        },
        onCancel: (cancelData: any) => {},
        onError: (err: any) => {
            logErrorAndAlert(
                "We're sorry, there appears to have been a problem with the PayPal PayLater payment. You may want to try again, or try another form of payment.",
                err
            );
        },
    };

    const payLaterBtn = paypal.Buttons(payPalLaterButtonSettings as any) as any;

    if (payLaterBtn.isEligible()) {
        payLaterBtn.render('#paypal-paylater-button');
    } else {
        logWarningAndAlert(
            "We're sorry, there appears to have been a problem with the PayPal PayLater payment. You may want to try again, or try another form of payment.",
            { btPaypalCheckoutInstance }
        );

        const paypalButtonElement = document.getElementById('paypal-paylater-button');
        if (paypalButtonElement) {
            paypalButtonElement.innerText = '!!NOT ELIGIBLE FOR PAYLATER!!';
        }
    }
}
