import * as Sentry from '@sentry/react';
import { environmentName, releaseVersion } from './pmgConfig';
import { debugLoggerInfo } from '@utils';

export function initializeSentry() {
    //const isStaging = environmentName === 'Staging';
    const isDev = environmentName === 'Development';

    // we wire up, even for DEV - so we can get full visibility into everything

    const dsnToUse = 'https://ea5d700715c08773e3eb9dc4e3bf4ca9@o98567.ingest.us.sentry.io/4507820815286272';

    debugLoggerInfo('Sentry is being initialized', { environment: environmentName.toLowerCase(), releaseVersion, dsnToUse });

    //const tracesSampleRate = isStaging || isDev ? 1 : 0.1; // 100% for dev or staging; 10% for prod
    //const replaysSessionSampleRate = isDev ? 0 : 0.1; // never on dev; 10% everywhere else
    //const replaysOnErrorSampleRate = isDev ? 0 : isStaging ? 1 : 0.5; // never on dev; 100% for staging; 50% for prod

    // nicholashead-pmg as part of PMG-6542 - just capture everything for now on non-dev
    const tracesSampleRate = isDev ? 0 : 1;
    const replaysSessionSampleRate = isDev ? 0 : 1;
    const replaysOnErrorSampleRate = isDev ? 0 : 1;

    Sentry.init({
        dsn: dsnToUse,
        integrations: [
            Sentry.browserTracingIntegration({ enableInp: true }),
            Sentry.replayIntegration({
                networkDetailAllowUrls: [window.location.origin],
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
                mask: ['.sentry-mask'] // if you want to mask specific elements, add a class to them and list them here
            }),
        ],
        sendDefaultPii: true,
        release: releaseVersion,
        environment: environmentName.toLowerCase(), // always lowercase
        /*       denyUrls: [
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /127\.0\.0\.1:4001\/isrunning/i,
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /sumo\.com/i,
        /wistia\.com/i,
        /gstatic\.com\/call-tracking\//i,
        /bing\.com/i,
        /doubleclick\.net/i,
        /dca0\.com/i,
        /clickcease\.com/i,
        /googletagmanager\.com/i,
    ], */
        /*       beforeSend: (event) => {
        if (event && event.message && event.message.match(/401 Unauthorized/i)) {
        event.level = 'warning'; // Don't log this as an error
        }
        if (event && event.message && event.message.match(/No connection/i)) {
        event.level = 'warning'; // Don't log this as an error
        }
        return event;
    }, */
        tracesSampleRate,
        replaysSessionSampleRate,
        replaysOnErrorSampleRate
    });
}
