import React, { useRef } from 'react';
import { Client } from 'braintree-web';
import { useApplePayButton } from '@hooks';

interface ApplePayButtonProps {
    displayName: string;
    clientInstance: Client;
    grandTotal: string;
    handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void;
}

export const ApplePayButton: React.FC<ApplePayButtonProps> = ({
    displayName,
    clientInstance,
    grandTotal,
    handlePaymentMethodApproved,
}) => {
    // Refs for the ApplePay button containers
    const applePayButtonContainerRef = useRef<HTMLDivElement | null>(null);


    // Use the hook to handle button setup and logic
    useApplePayButton(displayName, clientInstance, grandTotal, applePayButtonContainerRef, handlePaymentMethodApproved);

    return (
        <>
            {/* Container for ApplePay button */}
            <div className='w-[345px] h-[40px] apple-pay-button apple-pay-button-black' id='apple-pay-button' ref={applePayButtonContainerRef} />
        </>
    );
};