import { BraintreeClientPaymentMethod } from "@generated/swaggerClient";

export const mapPaymentMethod = (method: BraintreeClientPaymentMethod): string | null => {
    switch (method) {
        case BraintreeClientPaymentMethod.ApplePay:
            return "apple_pay";
        case BraintreeClientPaymentMethod.AndroidPayCard:
            return "google_pay";
        case BraintreeClientPaymentMethod.PayPal:
        case BraintreeClientPaymentMethod.PayPalPayLater:
            return "paypal";
        case BraintreeClientPaymentMethod.CreditCard:
            return "credit_card";
        default:
            return null; // Unknown or unhandled case
    }
};
