import { useCheckoutPages } from "@hooks";
import { ActionButtonProps, ActionButton } from "@shared";

export interface NextPageProps<T> extends Omit<ActionButtonProps<T>, 'children'> {
    children?: React.ReactNode;
}
export const NextPageButton = <T,>(props: NextPageProps<T>) => {
    const { nextUrl } = useCheckoutPages();

    const children = props.children ?? 'Continue';

    return <div>{nextUrl && <ActionButton type='submit' className='text-xl' shape='pill' {...props} children={children} />}</div>;
}
