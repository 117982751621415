import { ProviderBadge } from '@generated/swaggerClient';
import { Badge } from '@lemonsqueezy/wedges';
import { Icon, iconLibrary } from '@shared';

interface ProviderBadges {
	badges: ProviderBadge[];
	recommended: boolean;
}

export const ProviderBadges = (props: ProviderBadges) => {
	return (
		<div>
			{props.badges.map(b => {
				if (b === 'TopRated') {
					return (
						<Badge key={b} before={<Icon icon={iconLibrary.faGem} />} color='blue'>
							Top Rated
						</Badge>
					);
				}
				if (b === 'LowestPrice') {
					return (
						<Badge key={b} before={<Icon icon={iconLibrary.faSackDollar} />} color='green'>
							Lowest Price
						</Badge>
					);
				}
			})}
			{props.recommended && (
				<Badge before={<Icon icon={iconLibrary.farCircleCheck} />} color='green'>
					Recommended
				</Badge>
			)}
		</div>
	);
};
