import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from '@components';
import './styles/app.scss';
import { initializeSentry } from './sentryInit';
import { usePageTitles, useRedirectWhenTrailingSlash } from '@hooks';

// Initialize Sentry
initializeSentry();

function App() {
    usePageTitles('Checkout Home');
    useRedirectWhenTrailingSlash();
    // Example for Optimizely bucketing
    // const optimizelyInitialized = !!(window.optimizely && window.optimizely.initialized);
    // const [optimizelyBucketed, setOptimizelyBucketed] = useState(false);

    // useEffectOnlyOnceAsync(async () => {
    //     if (optimizelyInitialized && !optimizelyBucketed) {
    //         setOptimizelyBucketed(true);
    //         try {
    //             const variant = await bucketAndCheckForOptimizelyVariant(window.optimizely, 'mp_checkout_test', '5490549340241920');
    //             if (variant === '5623512627412992') {
    //                 console.info('🧪 index: user is in variation 1');
    //             }
    //             else {
    //                 console.info('🧪 index: optimizely did not bucket us for the variant, so doing nothing', { variant });
    //             }
    //         } catch (err) {
    //             console.error('🧪 Error checking for Optimizely variant', { err });
    //         }
    //     };
    // });

    return (
        <Layout>
            <Routes>
                {AppRoutes.map(route => {
                    if (route.index) {
                        return <Route key='index' index element={route.element} />;
                    }
                    return <Route key={route.path} path={route.path} element={route.element} />;
                })}
            </Routes>
        </Layout>
    );
}
export default App;
