import React from 'react';
import { BrandedNav, ProgressNav, SavePlaceModal, ScrollToTop } from '@components';
import { useBrandInfo, useCheckoutState, useRedirectWhenQuoteNotFound } from '@hooks';
import { Flex, Spinner } from '@shared';
import { ToastContainer } from 'react-toastify';
import { ProspectVerify } from '@components';
import { BrandType } from '../generated/swaggerClient';
import { useLocation } from 'react-router-dom';
import { Urls } from '@utils';

/**
 *
 * @param props
 * @returns
 */
export const Layout = (props: React.PropsWithChildren) => {
    const { isFetching } = useCheckoutState();
    const { pathname } = useLocation();
    // MovingPlanV1 / alternate quote page has it's own loading spinner so we don't want to show the global one
    const isOnQuote = pathname === Urls.quote.index;

    useRedirectWhenQuoteNotFound();
    const brandInfo = useBrandInfo();

    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    if (favicon) {
        if (brandInfo.brand === BrandType.MovingPlace) {
            favicon.href = "/img/" + brandInfo.brand.toLowerCase() + "/favicon.svg";
            favicon.type = "image/svg+xml";
        }
        else {
            favicon.href = "/img/" + brandInfo.brand.toLowerCase() + "/favicon.ico";
            favicon.type = "image/ico";
        }
    }

    const appleTouchIcon = document.getElementById('apple-touch-favicon') as HTMLLinkElement;
    if (appleTouchIcon) {
        appleTouchIcon.href = "/img/" + brandInfo.brand.toLowerCase() + "/apple-touch-icon.png";
        appleTouchIcon.type = "image/png";
    }

    return (
        <Flex direction='col' align='center' className={`${brandInfo!.abbrev} min-h-screen font-body bg-gradient-to-b to-neutralGrey-50 from-white`}>
            {isFetching && !isOnQuote && <Spinner fixed={true} verticallyCentered={true} />}
            <ScrollToTop /> {/* Ensures scrolling on every route change */}
            <BrandedNav brandInfo={brandInfo!} />
            <ProgressNav />
            <ProspectVerify />
            <SavePlaceModal />
            <Flex className='w-full' grow={1} direction='col' align='center' role='main'>
                {props.children}
                <ToastContainer className='px-3 md:mb-20 md:px-0' />
            </Flex>
        </Flex>
    );
};
