import classnames from 'classnames';
import * as React from 'react';
import '../styles/spinner.scss';

interface SpinnerProps {
    height?: number;
    width?: number;
    text?: string;

    className?: string;
    /**
     * Fixed position so it stays in middle of browser even when scrolled way down the page.
     */
    fixed?: boolean;
    /**
     * Set spinner to be horizonally AND vertically centered it it's container.
     */
    verticallyCentered?: boolean;
    /**
     * Delay in milliseconds before spinner is shown. Default is 0.
     */
    delay?: number;
}

const spinnerBars = arrayRange(12).map(i => {

    const barStyle: React.CSSProperties = {};
    barStyle.WebkitAnimationDelay = barStyle.animationDelay = (i - 12) / 10 + 's';

    barStyle.WebkitTransform = barStyle.transform = 'rotate(' + (i * 30) + 'deg) translate(146%)';

    return <div style={barStyle} className="react-spinner_bar" key={i} />;
});

export const Spinner: React.FC<SpinnerProps> = props => {
    const { fixed, verticallyCentered, width = 32, height = 32, delay, text = "Loading..." } = props;
    const [show, setShow] = React.useState(!delay || delay === 0);

    React.useEffect(() => {
        if (delay && delay > 0) {
            const timer = setTimeout(() => {
                setShow(true);
            }, delay);
            return () => clearTimeout(timer);
        }
    }, [delay]);

    return show && <>
        <div className={classnames(`react-spinner-container ${verticallyCentered ? 'flex items-center justify-center' : ''}`, { fixed })}>
            <div className="react-spinner-overlay" />

            <div className="react-spinner-content">
                <div style={{ width: width, height: height }} className={(props.className || '') + ' react-spinner'}>
                    {spinnerBars}
                </div>

                {text && <div className="react-spinner-text">{text}</div>}
            </div>

        </div>
    </>;

};

export const SpinnerNew: React.FC<SpinnerProps> = props => {
    const { fixed, verticallyCentered, width = 40, height = 40, delay, text = "Loading..." } = props;
    const [show, setShow] = React.useState(!delay || delay === 0);

    React.useEffect(() => {
        if (delay && delay > 0) {
            const timer = setTimeout(() => {
                setShow(true);
            }, delay);
            return () => clearTimeout(timer);
        }
    }, [delay]);

    return show && <>
        <div className={classnames(`react-spinner-container ${verticallyCentered ? 'flex items-center justify-center' : ''}`, { fixed })}>
            <div className="react-spinner-overlay bg-neutralGrey-400" />

            <div className="w-56 h-56 p-4 bg-white rounded-[32px] flex-col justify-start items-center gap-10 inline-flex overflow-hidden react-spinner-content-new">
                {text && <div className="text-coolGrey-600 text-lg font-medium tracking-tight mt-2">{text}</div>}
                <div style={{ width: width, height: height }} className={(props.className || '') + ' react-spinner'}>
                    <div className="lds-ring"><div /><div /><div /><div /></div>
                </div>
            </div>

        </div>
    </>;

};

interface SpinnerBasicProps {
    height?: number;
    width?: number;
}

export const SpinnerBasic: React.FC<SpinnerBasicProps> = props => {
    const { width = 32, height = 32 } = props;

    return (
        <div style={{ width: width, height: height }} className="react-spinner">
            {spinnerBars}
        </div>

    );

};

interface SpinnerInlineProps {
    height?: number;
    width?: number;
    text?: string;

    className?: string;
}

export const SpinnerInline: React.FC<SpinnerInlineProps> = props => {
    const { width = 32, height = 32, text = "Loading..." } = props;
    return [<div key="spinner" style={{ width: width, height: height }} className={(props.className || '') + ' react-spinner-inline'}>
        {spinnerBars}
    </div>, text && <div className="react-spinner-inline-text" key="spinner-text">{text}</div>];

};

function arrayRange(length: number): number[] {
    return [...new Array(length)].map((m, i) => i);
}
