import { UpdateLocationsDto } from "@generated/swaggerClient";
import { useCheckoutClientFactory, useCheckoutNavigation, useCheckoutState, defaultState } from "@hooks";
import { LocationsFormModel } from "@types";
import { LocationsForm } from "@components";
import { FormikHelpers } from "formik";
import { getFullAddress, performDataLayerLog, sentryWrapper, toastError, useEffectOnlyOnceAsync } from "@shared";
import { useSearchParams } from "react-router-dom";
import { js2 } from "@utils";

export const LocationsPage = () => {
    const { onNavigate } = useCheckoutNavigation();
    const { setModel } = useCheckoutState();
    const { wrapApiCall } = useCheckoutClientFactory();
    const [searchParams] = useSearchParams();

    useEffectOnlyOnceAsync(async () => {
        if (searchParams.get('redirectedfrom') == 'continue-page') {
            setModel(defaultState.model);
            if (searchParams.get('error') == 'expired') {
                toastError({ title: 'We apologize, your move quote has expired and is no longer available. We would still love to help you book your perfect move. Please begin a new quote.' });
            }
            else {
                toastError({ title: 'We apologize, something went wrong. Please try after some time, if issue persists please begin a new quote.' });
            }
        }
    });

    const handleChange = async (values: LocationsFormModel, formikHelpers: FormikHelpers<LocationsFormModel>) => {
        const fullLoadAddress = values.manualAddressEntry ? getFullAddress(values.loadAddress) : values.loadAddress.fullAddress;
        const fullUnloadAddress = values.manualAddressEntry ? getFullAddress(values.unloadAddress) : values.unloadAddress.fullAddress;

        setModel({
            loadAddress: { ...values.loadAddress, fullAddress: fullLoadAddress, placeId: values.loadAddress.placeId },
            unloadAddress: { ...values.unloadAddress, fullAddress: fullUnloadAddress, placeId: values.loadAddress.placeId },
            manualAddressEntry: values.manualAddressEntry,
        });

        const dto = UpdateLocationsDto.fromJS(values);
        const result = await wrapApiCall((client) => client.locationsCreateDraftQuote(dto), formikHelpers);
        let isLongDistance = false;

        if (result.success) {
            setModel({ id: result.result?.id });

            const isLongDistanceResult = await wrapApiCall((client) => client.locationsIsLongDistance(values.loadAddress.zip, values.unloadAddress.zip), formikHelpers);

            if (isLongDistanceResult.success) {
                setModel({ isLongDistance: isLongDistanceResult.result });
                isLongDistance = isLongDistanceResult.result ?? false;

                // update ga4 data - per John B wants to know immediately
                performDataLayerLog({
                    is_long_distance: isLongDistanceResult.result
                }, true);
            } else {
                // FUTURE: what if it wasnt successful?? do we not let them proceed?
                // i guess log to sentry for now..
                sentryWrapper.logException(isLongDistanceResult.errorObj, 'error', 'locationsIsLongDistance came back non-successful', { component: 'LocationsPage' }, { loadZip: values.loadAddress.zip, unloadZip: values.unloadAddress.zip });
            }
        }
        else {
            sentryWrapper.logException(result.errorObj, 'error', 'locationsCreateDraftQuote came back non-successful', { component: 'LocationsPage' }, { dto: js2(dto) });

            toastError({ title: 'There was an error creating the quote. Please try again or give us a call.' });
        }

        return {saveSuccess: result.success, isLongDistance};
    }

    return <LocationsForm onSubmitted={onNavigate} onChange={handleChange} />;
}
