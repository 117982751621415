import { useGetQuote } from "@hooks";
import { Spinner, useEffectOnlyOnceAsync } from "@shared";

export const ContinuePage = () => {
    const { loading, loadCheckoutQuoteAndNavigate } = useGetQuote();

    useEffectOnlyOnceAsync(async () => {
        loadCheckoutQuoteAndNavigate();
    });

    return <>{loading && <Spinner delay={0} />}</>;
};
