import * as Sentry from '@sentry/react';
import { debugLoggerError, debugLoggerInfo, debugLoggerWarn, js2 } from '@utils';

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

type AllowedTags = 'hook' | 'component';

/**
 * A wrapper around Sentry-related error logging, so we can easily add more information or tweaks as needed.
 * These will all also call the debugLogger functions, so you can see the logs in the console as well.
 */
export const sentryWrapper = {
    /**
     * Logs an exception to Sentry. If the exception is an Error, it will be captured as an exception. Otherwise, it will be captured as an error-level message.
     * @param exception The exception to log. Should be instanceof Error if possible.
     * @param asFatal Whether to mark the exception as fatal. Defaults to false.
     * @param message Message to include with the log.
     * @param tags Tags to include with the log.
     * @param extra Extra information to include with the log.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logException: (exception: any, level: Sentry.SeverityLevel = 'info', message: string, tags: Partial<Record<AllowedTags, Primitive>>, extra?: Record<string, Primitive>) => {
        if (Sentry) {
            if (exception instanceof Error) {
                Sentry.captureException(exception, {
                    level,
                    tags,
                    extra: {
                        message,
                        ...extra
                    },
                });
            } else {
                const messageClean = message || 'No message provided';

                // sentry doesn't really like you calling captureException with a non-Error, so lets log it a different way
                sentryWrapper.logGeneric(level, `Exception occurred (non-Error JS Type): ${messageClean}`, tags, { exceptionJson: js2(exception), ...extra });
            }
        }

        debugLoggerError(`sentryWrapper: logException called: ${message}`, { exception, level, message, tags, extra });
    },

    logGeneric: (level: Sentry.SeverityLevel, message: string, tags: Partial<Record<AllowedTags, Primitive>>, extra?: Record<string, Primitive>) => {
        if (Sentry) {
            Sentry.captureMessage(message, {
                level,
                tags,
                extra,
            });
        }

        switch (level) {
            case 'error':
                debugLoggerError(`sentryWrapper: logGeneric called: ${message}`, { level, message, tags, extra });
                break;
            case 'warning':
                debugLoggerWarn(`sentryWrapper: logGeneric called: ${message}`, { level, message, tags, extra });
                break;
            default:
                debugLoggerInfo(`sentryWrapper: logGeneric called: ${message}`, { level, message, tags, extra });
        }
    },

    logInfo: (message: string, tags: Partial<Record<AllowedTags, Primitive>>, extra?: Record<string, Primitive>) => {
        sentryWrapper.logGeneric('info', message, tags, extra);
    },

    logWarn: (message: string, tags: Partial<Record<AllowedTags, Primitive>>, extra?: Record<string, Primitive>) => {
        sentryWrapper.logGeneric('warning', message, tags, extra);
    },

    logError: (message: string, tags: Partial<Record<AllowedTags, Primitive>>, extra?: Record<string, Primitive>) => {
        sentryWrapper.logGeneric('error', message, tags, extra);
    },

    logFatal: (message: string, tags: Partial<Record<AllowedTags, Primitive>>, extra?: Record<string, Primitive>) => {
        sentryWrapper.logGeneric('fatal', message, tags, extra);
    },

    logBreadcrumb: (message: string, category?: string, level: Sentry.SeverityLevel = 'info') => {
        if (Sentry) {
            Sentry.addBreadcrumb({ category, message, level });
        }
        else {
            // only log to console if we're not using Sentry/isnt loaded - otherwise we just double up messages
            debugLoggerInfo(`sentryWrapper: logBreadcrumb called: ${message}`, { message, category, level });
        }
    },
};
