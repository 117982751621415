import { ReviewsSummary } from "@generated/swaggerClient";
import { ProgressBar } from "@lemonsqueezy/wedges";
import { Flex, StarDisplay } from "@shared";

export const ReviewsSummaryBreakdown = ({ reviewsSummary }: { reviewsSummary: ReviewsSummary }) => {
	return (
		<Flex className='gap-2 md:gap-6 flex-col xs:flex-row'>
			<div className='flex-grow order-2 xs:order-1'>
				<ProgressBar
					//afterIndicator={<SpinnerIcon className="animate-spin" />}
					//tooltip="A tooltip is a small box that appears when hovering over a UI element, providing additional information."
					//indicator="50%"
					variant='inline'
					color='yellow'
					label={'5'}
					value={reviewsSummary.fiveStarPercent}
                    className='mb-3 xs:mb-0'
				/>
				<ProgressBar variant='inline' color='yellow' label={'4'} value={reviewsSummary.fourStarPercent} className='mb-3 xs:mb-0' />
				<ProgressBar variant='inline' color='yellow' label={'3'} value={reviewsSummary.threeStarPercent} className='mb-3 xs:mb-0' />
				<ProgressBar variant='inline' color='yellow' label={'2'} value={reviewsSummary.twoStarPercent} className='mb-3 xs:mb-0' />
				<ProgressBar variant='inline' color='yellow' label={'1'} value={reviewsSummary.oneStarPercent} className='mb-3 xs:mb-0' />
			</div>
			<Flex direction='col' align='center' justify='center' className='px-2 gap-1 order-1 xs:order-2'>
				<div className='text-neutralGrey-900 text-4xl font-medium'>{reviewsSummary.average}</div>
				<StarDisplay rating={reviewsSummary.average} id={reviewsSummary.average} />
				<div className='text-primary-600 text-sm underline'>{reviewsSummary.total} reviews</div>
			</Flex>
		</Flex>
	);
};