import { Headings } from '@components';
import { UpdateContactDto } from '@generated/swaggerClient';
import { useCheckoutClientFactory, useCheckoutState, useInactivity } from '@hooks';
import { savePlaceValidationSchema } from '@modules';
import { ActionButton, Card, ErrorFocus, Flex, HahFormikCheckbox, HahFormikFieldGroup, Icon, iconLibrary, InputGroup, Modal, performDataLayerLog, SavePlaceModalInactivityTime, sentryWrapper, toastError } from '@shared';
import { SavePlaceFormModel } from '@types';
import { js2, Urls } from '@utils';
import { FormikHelpers, Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { useLocation } from 'react-router-dom';

enum ModalState {
    InProgress,
    Success,
    Error
}

const allowedPathsForSavePlaceModal = [
    Urls.locations.index,
    Urls.locations.load,
    Urls.locations.unload,
    Urls.items,
    Urls.date,
];
/**
 * Modal to save place - triggered by inactivity
 */

export const SavePlaceModal = () => {

    const { wrapApiCall } = useCheckoutClientFactory();
    const { pathname } = useLocation();
    const { model: { contact, id }, setModel } = useCheckoutState();
    const [currentState, setCurrentState] = React.useState(ModalState.InProgress);
    const [show, setShow] = React.useState(false);
    const isInactive = useInactivity(SavePlaceModalInactivityTime);

    // flag so the modal does not open again after closing once
    const modalOpenedOnce = React.useRef<boolean>(false);

    React.useEffect(() => {
        if (isInactive && allowedPathsForSavePlaceModal.includes(pathname) && !modalOpenedOnce.current) {
            showModal();
        }
    }, [isInactive, pathname]);

    React.useEffect(() => {
        //  if we're showing the modal, then log the saved form start event
        if (show) {
            //FUTURE: Currently using same event name as SaveQuoteModal. Maybe this should be a separate event?
            performDataLayerLog({ event: 'saved_form_start' }, true);
        }
    }, [show]);

    const handleSavePlace = async (values: SavePlaceFormModel, formikHelpers: FormikHelpers<SavePlaceFormModel>) => {
        const savePlaceDto = UpdateContactDto.fromJS(values.contact);

        // Sending empty id (should typically happen on the first /locations page) so that the API can create a new quote and save the contact info
        const result = await wrapApiCall(client => client.quoteSavePlace(id ? id : '00000000-0000-0000-0000-000000000000', savePlaceDto), formikHelpers);
        if (result.success) {
            //FUTURE: Currently using same event name as SaveQuoteModal. Maybe this should be a separate event?
            performDataLayerLog({ event: 'saved_form_submit' }, true);
            setModel({ contact: values.contact });
            setCurrentState(ModalState.Success);
        }
        else {
            // FUTURE: improve this. we should gracefully handle if the quote couldn't be saved for some reason, and tell the user what to do? or take them to an error page?

            sentryWrapper.logException(result.errorObj, 'error', 'quoteSavePlace API endpoint had an error', { component: 'SavePlaceModal' }, { id, dto: js2(savePlaceDto) });

            toastError({ title: 'There was an unexpected error saving your information. Please refresh and try again. If you continue to experience difficulties, please contact us.' });
        }
    };

    const initialValues: SavePlaceFormModel = {
        contact: contact || {
            email: '',
            firstName: '',
            lastName: '',
            phone: ''
        },
        agreeToPrivacyPolicy: false
    };

    const showModal = () => {
        setCurrentState(ModalState.InProgress);
        modalOpenedOnce.current = true;
        setShow(true);
    }

    const closeModal = () => {
        setShow(false);
    }

    const headerElement = (
        <div className='text-center'>
            <span className='fa-layers fa-fw fa-3x mt-4 mb-3'>
                <Icon className='text-primary-100' icon={iconLibrary.faCircle} />
                <Icon className='text-primary-500' icon={currentState === ModalState.InProgress ? iconLibrary.farBookmark : iconLibrary.farCircleCheck} transform='shrink-7' />
            </span>
        </div>
    );

    return (
        <Modal
            open={show}
            setOpen={setShow}
            title={currentState == ModalState.InProgress ? 'Save Your Progress!' : 'Your Progress is Saved!'}
            triggerButton={<></>}
            headerElement={headerElement}
            centeredTitle
            preventCloseOnOutsideClick>
            {currentState === ModalState.InProgress && <InProgressContent handleSubmit={handleSavePlace} closeModal={closeModal} initialValues={initialValues} />}
            {currentState === ModalState.Success && <SuccessContent closeModal={closeModal} />}
        </Modal>
    );
}

const SuccessContent = ({ closeModal }: { closeModal: () => void }) => {
    return <>
        <div className='mb-4'>
            <p className='mb-4'>
                Check your inbox! Your quote progress was successfully saved and sent to your email.
            </p>
            <p className='mb-4'>
                If you provided your phone number, an agent will reach out within the next 30 minutes, during business hours.
            </p>
            <Card className='mb-0' iconLeft={iconLibrary.faCircleInfo} title='Your quote will be saved for 7 days' bgColor='bg-primary-50' titleTextColor='text-primary-700' />
        </div>
        <ActionButton variant='transparent' className='w-full text-primary-500' onClickAction={closeModal}>
            Close this Window
        </ActionButton>
    </>;
}

const InProgressFooter = ({ closeModal }: { closeModal: () => void }) => {
    const formikContext = useFormikContext();

    const handleSubmit = () => {
        if (formikContext.isSubmitting) return;
        formikContext.submitForm();
    }

    return (
        <div className='mt-4'>
            <ActionButton variant='primary' className='w-full' disabled={!formikContext.isValid || formikContext.isSubmitting} onClickAction={handleSubmit}>
                Save Quote
            </ActionButton>
            <div className='mt-4 text-center '>
                <ActionButton variant='transparent' className='w-full text-slate-300' disabled={formikContext.isSubmitting} onClickAction={closeModal}>
                    No, thanks for now.
                </ActionButton>
                <span className='w-full text-slate-300 mt-2 text-xs'>
                    <Icon className='me-1' icon={iconLibrary.faLock} />
                    We will never share your info with anyone else.
                </span>
            </div>
        </div>
    );
}

const InProgressContent = ({ handleSubmit, closeModal, initialValues }: { handleSubmit: (values: SavePlaceFormModel, formikHelpers: FormikHelpers<SavePlaceFormModel>) => void, closeModal: () => void, initialValues: SavePlaceFormModel }) => {
    const parentName = 'contact';

    const agreeToPrivacyPolicyLabel = <>I agree to the <a href='https://www.porchmovinggroup.com/legal/privacy/' target='_blank' className='text-blue-600 underline'>Privacy Policy</a></>;

    return <>
        {/* <p className='mb-4'>
            Enter your email, name and phone and we will send you a link to continue your move quote and give you a call to help with your move.
        </p> */}
        <Formik
            initialValues={initialValues}
            validationSchema={savePlaceValidationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
            enableReinitialize
            onSubmit={handleSubmit}>
            <Form className='form-wrapper' noValidate>
                <Flex grow={1} direction='col' className='w-[576px] px-4'>
                    <ErrorFocus />
                    <Headings
                        secondary='Enter your email, name and phone and we will send you a link to continue your move quote and give you a call to help with your move.'
                    />
                    <InputGroup rows={3} cols={2} className='mb-4'>
                        <HahFormikFieldGroup
                            parentName={parentName}
                            name='email'
                            rounded={['topLeft', 'topRight']}
                            placeholder='Email Address'
                            cols={2}
                            iconLeft={<Icon icon={iconLibrary.faEnvelope} />}
                            required
                        />
                        <HahFormikFieldGroup parentName={parentName} name='firstName' placeholder='First Name' required />
                        <HahFormikFieldGroup parentName={parentName} name='lastName' placeholder='Last Name' required />
                        <HahFormikFieldGroup rounded={['bottomLeft', 'bottomRight']} parentName={parentName} name='phone' placeholder='Phone Number (Optional)' cols={2} />
                        <HahFormikCheckbox className='mt-4 col-span-2' name='agreeToPrivacyPolicy' label={agreeToPrivacyPolicyLabel} required />
                    </InputGroup>
                    <span className='text-start text-sm text-destructive mt-2'>
                        <ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.email`} />
                        <ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.phone`} />
                        <ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.firstName`} />
                        <ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.lastName`} />
                        <ErrorMessage className='text-red-600 mb-2' component='div' name='agreeToPrivacyPolicy' />
                    </span>
                    <InProgressFooter closeModal={closeModal} />
                </Flex>
            </Form>
        </Formik>
    </>;
}
