import React, { useState } from "react";
import { Button, ButtonProps } from "@lemonsqueezy/wedges";
import { sentryWrapper } from "@shared";

export interface ActionButtonProps<TClickArgs> extends Omit<ButtonProps, 'onClick'> {
    onClickAction: ((args: TClickArgs) => void | Promise<void>) | (() => void | Promise<void>);
    args?: TClickArgs;
    children: React.ReactNode;
    disabled?: boolean;
    afterAction?: () => void;
}

export function ActionButton<TClickArgs>({
    onClickAction: action,
    args,
    children,
    disabled,
    afterAction,
    type = 'button',
    ...rest
}: ActionButtonProps<TClickArgs>) {

    const isExecuting = React.useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleClick = async () => {
        if (isExecuting.current || disabled) return;

        setIsLoading(true);
        isExecuting.current = true;
        try {
            let result;
            if (args !== undefined) { // Check if args is actually provided
                result = action(args);
            } else {
                result = (action as () => void | Promise<void>)();
            }

            if (result instanceof Promise) {
                await result; // Handle asynchronous calls
            }
            if (afterAction) {
                afterAction();
            }
        } catch (e) {
            sentryWrapper.logException(e, 'error', 'ActionButton handleClick had an exception', { component: 'ActionButton' });
        } finally {
            setIsLoading(false);
            isExecuting.current = false;
        }
    };

    return (
        <Button type={type} onClick={handleClick} disabled={isLoading || disabled}
            style={{ cursor: isLoading || disabled ? 'not-allowed' : 'pointer' }} // Visual cue for disabled state
            {...rest}>
            {children}
        </Button>
    );
}
