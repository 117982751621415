import { Footer, Headings, PorchHomeProtectionPlan } from "@components";
import { locationDetailsValidationSchema } from "@modules";
import { enumValuesToOptions, ErrorFocus, Flex, HahFormikInputField, HahFormikSelectField } from "@shared";
import { LocationDetailsFormModel } from "@types";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { Input, Label } from "@lemonsqueezy/wedges";
import { PropertyType } from "@generated/swaggerClient";
import { AddressTypeDescription } from "@shared";
import React from "react";
import { useLocation } from "react-router-dom";
import { Urls } from "@utils";
import { useCheckoutState } from "@hooks";

type TModel = LocationDetailsFormModel;
interface Props {
    onSubmitted: (action?: string) => void;
    onChange: (values: TModel, formikHelpers: FormikHelpers<TModel>) => Promise<boolean>;
    model: TModel;
    address: string;
    label: string
    imgSrc?: string;
    imgAlt?: string;
}

const availableOptions = [PropertyType.House, PropertyType.CondoApt];
const propertyTypeOptions = enumValuesToOptions(availableOptions, AddressTypeDescription);
const bedroomsOptions = [...Array(11)].map((_, i) => ({ label: (i === 0 ? 'No' : i) + ' Bedroom' + (i == 1 ? '' : 's'), value: i }));
const stairsOptions = [...Array(21)].map((_, i) => {
    const floors = i + 1;
    const stairs = i === 0 ? 'No Stairs' : `${i} Flight${i > 1 ? 's' : ''} of Stairs`;
    return { label: `${floors} Floor${floors > 1 ? 's' : ''} / ${stairs}`, value: i };
});


export const LocationDetailsForm = ({ onSubmitted, onChange, model, address, label, imgSrc, imgAlt }: Props) => {
    const { pathname } = useLocation();
    const { model: { fastMode } } = useCheckoutState();
    const handleSubmit = async (values: TModel, formikHelpers: FormikHelpers<TModel>) => {
        if (await onChange(values, formikHelpers)) {
            onSubmitted(values.action);
        }
    };

    const porchHomeProtectionContent = (
        <>
            <div className='text-neutralGrey-900 mb-2'>
                <span className='font-bold'>Limited time offer:</span> Protect your new home for <span className='font-bold'>FREE</span>!
            </div>
            <div className='text-neutralGrey-900'>Book today to redeem a free 60-day home warranty with your move.</div>
        </>
    );

    const onLoadingPage = pathname == Urls.locations.load;

    return (
        <Formik
            initialValues={model}
            validationSchema={locationDetailsValidationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
            enableReinitialize
            onSubmit={handleSubmit}>
            <Form className='form-wrapper' noValidate>
                <Flex direction='col' className='w-[576px] px-4'>
                    <ErrorFocus />
                    <Headings
                        className='mb-6'
                        primary={
                            <span>
                                Please provide the details about your <span className='text-wg-blue-700'>{label.toLowerCase()}</span>
                            </span>
                        }
                        imgSrc={imgSrc}
                        imgAlt={imgAlt}
                    />
                    <Label className='mb-2' required>
                        {label}
                    </Label>
                    <Input value={address} readOnly />
                    <p className='subhead my-5'>Tell us a little bit more about this property</p>
                    <HahFormikSelectField name='propertyType' label='Property Type' required options={propertyTypeOptions} />
                    <HahFormikSelectField className='mt-4' name='bedrooms' label='Amount of bedrooms' required options={bedroomsOptions} />
                    <HahFormikSelectField className='mt-4' name='stairs' label='Floors/Stairs' required options={stairsOptions} />
                    <ApartmentFieldsComponent />
                    {onLoadingPage && <PorchHomeProtectionPlan content={porchHomeProtectionContent} />}
                </Flex>
                <Footer goBackToRoot={onLoadingPage && fastMode} />
            </Form>
        </Formik>
    );
}

const elevatorOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

const ApartmentFieldsComponent = () => {
    const formikContext = useFormikContext<TModel>();
    const isApartment = React.useMemo(() => formikContext.values.propertyType === PropertyType.CondoApt, [formikContext.values.propertyType]);

    return <>
        {isApartment && <HahFormikInputField className="mt-3" name="street2" label="Unit #" maxLength={50} required />}
        {isApartment && <HahFormikSelectField className="mt-3" name="hasElevator" label="Elevator?" required options={elevatorOptions} />}
    </>
}
