import React, { useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Flex, Icon, iconLibrary } from '@shared';

interface HahCollapsibleProps {
    title: string;
    subtitle: string | React.ReactNode;
    children?: React.ReactNode;
    showDivider?: boolean;
    onStateChange?: (isOpen: boolean) => void;
    justify: 'center' | 'start' | 'end' | 'between' | 'around';
}

export const HahCollapsible: React.FC<HahCollapsibleProps> = ({
    title,
    subtitle,
    children,
    showDivider,
    onStateChange,
    justify
}) => {
    const [open, setOpen] = useState(false);

    const handleStateChange = (isOpen: boolean) => {
        setOpen(isOpen);
        onStateChange?.(isOpen);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleStateChange(!open);
        }
    };

    return (
        <Collapsible.Root className="p-2" open={open} onOpenChange={handleStateChange}>
            <Collapsible.Trigger asChild>
                <Flex align="start"  justify={justify ?? 'between'} className="gap-2" tabIndex={0} onKeyDown={handleKeyDown} aria-expanded={open} role="button">
                    <div>
                        <h2 className="mb-0">{title}</h2>
                        <p className="text-sm">{subtitle}</p>
                    </div>
                    {open ? <Icon size="lg" icon={iconLibrary.faChevronUp} /> : <Icon size="lg" icon={iconLibrary.faChevronDown} />}
                </Flex>
            </Collapsible.Trigger>
            {showDivider && <hr className="border-hahGrey mt-4" />}
            <Collapsible.Content
                className="overflow-hidden data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp"
            >
                {children}
            </Collapsible.Content>
        </Collapsible.Root>
    );
};
