import { useCheckoutPages } from '@hooks';
import { ActionButton, ActionButtonProps } from '@shared';

export interface PreviousPageProps<T> extends Omit<ActionButtonProps<T>, 'children'> {
	children?: React.ReactNode;
}
export const PreviousPageButton = <T,>(props: PreviousPageProps<T>) => {
	const { previousUrl } = useCheckoutPages();
	const children = props.children ?? 'Back';

	return (
		<div className='flex-grow'>
			{previousUrl && <ActionButton variant='transparent' className='text-neutralGrey-600 text-xl font-medium' {...props} children={children} />}
		</div>
	);
};
