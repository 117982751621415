import { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { HostedFields } from 'braintree-web';
import {
    iconLibrary,
    HahFormikInputField,
    HostedFieldWithLabel,
    Icon,
    InputGroup,
    Spinner,
    Flex,
    HahFormikRadio,
    HahFormikRadioGroup,
    allValidPaymentMethods,
    sentryWrapper,
    HahTooltip,
    isApplePayUnavailable,
    performDataLayerLog,
    mapPaymentMethod,
    toastError,
} from '@shared';
import { CheckoutFormModel } from '@types';
import { useFormikContext } from 'formik';
import { Braintree, BraintreeContext, HostedField } from 'react-braintree-fields';
import { useBtClientState } from '@hooks';
import { BraintreeClientPaymentMethod } from '@generated/swaggerClient';
import { checkoutContactValidationSchema } from '@modules';
import { debugLoggerInfo } from '@utils';

export interface CardType {
    niceType: string;
    type: string;
    code: { name: string; size: number };
    supported: boolean;
}

type tokenizeFunc = HostedFields['tokenize'];

interface BtPaymentInfoProps {
    tokenizeRef: React.MutableRefObject<tokenizeFunc | undefined>;
    btClientToken: string;
    deviceData: React.MutableRefObject<string | null>;
}

export const BtPaymentInfo = ({ tokenizeRef, btClientToken, deviceData }: BtPaymentInfoProps) => {
    const [cardType, setCardType] = useState('');
    const [btAuthSuccess, setbtAuthSuccess] = useState(false);
    const onCardTypeChange = ({ cards }: { cards: CardType[] }) => {
        if (1 === cards.length) {
            const [card] = cards;

            setCardType(card.type);
        } else {
            setCardType('');
        }
    };

    const handleBtInitError = (error: unknown) => {
        toastError({ title: 'There was an error with the payment gateway. Please try again.' });
        //console.error('Error initializing Braintree:', error);

        // this is a pretty big deal, so log as fatal
        sentryWrapper.logException(error, 'fatal', 'Could not initialize braintree at all', { component: 'BtPaymentInfo.init' });
    };

    const onDataCollectorInstanceReady = (err: unknown, dataCollectorInstance: braintree.DataCollector) => {
        if (err) {
            sentryWrapper.logException(err, 'warning', 'Could not initialize braintree data collector', { component: 'BtPaymentInfo.onDataCollectorInstanceReady' });
            // Don't show the user an error if the data collector fails to initialize - they should still be able to checkout
            return;
        }
        deviceData.current = dataCollectorInstance.deviceData;
    };

    return (
        <Braintree
            className='payment-info'
            authorization={btClientToken}
            onError={(error: unknown) => handleBtInitError(error)}
            onCardTypeChange={onCardTypeChange}
            getTokenRef={(ref: tokenizeFunc) => (tokenizeRef.current = ref)}
            onDataCollectorInstanceReady={onDataCollectorInstanceReady}
            onAuthorizationSuccess={() => setbtAuthSuccess(true)}
            styles={{
                input: { 'font-size': '16px', 'padding-left' : '0.25rem' },
                'input::placeholder': {
                    color: 'rgb(108, 108, 137)',  // Set placeholder color
                },
            }}>
            {!btAuthSuccess && <Spinner />}
            <PaymentMethods cardType={cardType} />
        </Braintree>
    );
};

function getCardTypeIcon(cardType: string) {
    switch (cardType) {
        case 'visa':
            return iconLibrary.faCcVisa;
        case 'master-card':
            return iconLibrary.faCcMastercard;

        case 'american-express':
            return iconLibrary.faCcAmex;

        case 'discover':
            return iconLibrary.faCcDiscover;
    }
    return iconLibrary.faCreditCard;
}

interface PaymentMethodsProps {
    cardType: string;
}

const PaymentMethods = ({ cardType }: PaymentMethodsProps) => {
    const { btClientInstance, setBtClientInstance } = useBtClientState();
    const formikContext = useFormikContext<CheckoutFormModel>();
    const { clientInstance } = useContext(BraintreeContext);
	const [isContactValid, setIsContactValid] = useState(true);

    useEffect(() => {
        debugLoggerInfo('PaymentInfo: useEffect to validate contact info');
        const validateContact = async () => {
            const contactIsValid = await checkoutContactValidationSchema.isValid(formikContext.values.contact);
            setIsContactValid(contactIsValid);
        };

        validateContact();
    }, [formikContext.values.contact]); // Re-run validation whenever contact info changes

    useEffect(() => {
        const paymentType = mapPaymentMethod(formikContext.values.paymentMethod);

        if (!paymentType) {
            return;
        }

        performDataLayerLog({ event: 'add_payment_info', payment_type: paymentType }, true);
    }, [formikContext.values.paymentMethod]);

    useEffect(() => {
        if (!clientInstance) {
            return;
        }
        if (!btClientInstance) {
            setBtClientInstance(clientInstance);
        }
    });

    let paymentMethods = allValidPaymentMethods.filter(e => e.value !== BraintreeClientPaymentMethod.PayPalPayLater);
    if (isApplePayUnavailable()) {
        // This device does not support version 3 of Apple Pay.
        paymentMethods = paymentMethods.filter(e => e.value !== BraintreeClientPaymentMethod.ApplePay);
    }

    if (!window.google.payments.api.PaymentsClient) {
        // This device does not support Google Pay.
        paymentMethods = paymentMethods.filter(e => e.value !== BraintreeClientPaymentMethod.AndroidPayCard);
    }

    const PaymentMethodInfo = (buttonText: string, brand: string, value: BraintreeClientPaymentMethod) => (
        <div
            className={`payment-method-info bg-neutralGrey-50 p-4 border-x border-neutralGrey-100 animate-slideFadeDown ${
                formikContext.values.paymentMethod == value ? 'block' : 'hidden'
            }`}>
            After clicking "{buttonText}", you will be redirected to {brand} to complete your purchase securely.
        </div>
    );

    return (
        <HahTooltip tooltipContent='Please provide your contact information before payment.' triggerClassNames='w-full' disabled={isContactValid}>
            <Flex direction='col'>
                <HahFormikRadioGroup name={'paymentMethod'} className='group'>
                    {paymentMethods.map(pt => {
                        if (pt.value === BraintreeClientPaymentMethod.CreditCard) {
                            return (
                                <div className='radioBtnGroupItem' key={pt.value}>
                                    <HahFormikRadio
                                        disabled={!isContactValid}
                                        name='paymentMethod'
                                        id={`paymentMethod-${pt.value}`}
                                        label={pt.label}
                                        checkedValue={pt.value}
                                        icon={<img className='w-16 max-w-full' src='/img/CreditCard.svg' alt='CC Mark' />}
                                    />
                                    <div
                                        className={`border-x border-neutralGrey-100 animate-slideFadeDown ${
                                            formikContext.values.paymentMethod == pt.value ? 'block' : 'hidden'
                                        }`}>
                                        <BtHostedFields cardType={cardType} />
                                    </div>
                                </div>
                            );
                        }
                        return (
                            <div className='radioBtnGroupItem' key={pt.value}>
                                <HahFormikRadio
                                    key={pt.value}
                                    disabled={!isContactValid}
                                    name='paymentMethod'
                                    id={`paymentMethod-${pt.value}`}
                                    label={pt.label}
                                    checkedValue={pt.value}
                                    icon={<img className='w-12 max-w-full' src={`/img/${pt.value}.svg`} alt={`${pt.label} Logo`} />}
                                />
                                {PaymentMethodInfo(pt.buttonText, pt.label, pt.value)}
                            </div>
                        );
                    })}
                </HahFormikRadioGroup>
            </Flex>
        </HahTooltip>
    );
};

interface BtHostedFieldsProps {
    cardType: string;
}

const BtHostedFields = ({ cardType }: BtHostedFieldsProps) => {
    const btFieldRefs = useRef<{
        cardholderName?: HostedField | null;
        number?: HostedField | null;
        expirationDate?: HostedField | null;
        cvv?: HostedField | null;
    }>({});

    const {
        values: { paymentInfo: values },
        setFieldValue: setFieldValueParent,
        setFieldTouched,
        errors: { paymentInfo: errors = {} },
    } = useFormikContext<CheckoutFormModel>();

    const setFieldValue = useCallback(
        async (field: string, value: string) => {
            // This should not validate the field, as the hosted fields will handle that
            await setFieldValueParent(`paymentInfo.${field}`, value, false);
        },
        [setFieldValueParent]
    );

    return (
        <div className='bg-neutralGrey-50 px-4 pt-4 pb-1'>
            <InputGroup rows={2} cols={2}>
                <HostedFieldWithLabel
                    rounded={['topLeft', 'topRight']}
                    className='sentry-mask'
                    cols={2}
                    setRef={r => (btFieldRefs.current.number = r)}
                    type='number'
                    name='number'
                    placeholder='Card number'
                    value={values.number}
                    onChange={async value => await setFieldValue('number', value)}
                    onBlur={() => setFieldTouched(`number`, true, true)}
                    error={errors.number}
                    showError={false}
                    iconRight={<Icon icon={getCardTypeIcon(cardType)} size='lg' />}
                />
                <HostedFieldWithLabel
                    rounded={['bottomLeft']}
                    className='sentry-mask'
                    cols={1}
                    setRef={r => (btFieldRefs.current.expirationDate = r)}
                    type='expirationDate'
                    name='expirationDate'
                    placeholder='Expiry (MM/YY)'
                    value={values.expirationDate}
                    onChange={async value => await setFieldValue('expirationDate', value)}
                    onBlur={async () => await setFieldTouched(`expirationDate`, true, true)}
                    showError={false}
                    error={errors.expirationDate}
                />
                <HostedFieldWithLabel
                    rounded={['bottomRight']}
                    className='sentry-mask'
                    cols={1}
                    setRef={r => (btFieldRefs.current.cvv = r)}
                    type='cvv'
                    name='cvv'
                    placeholder='CVV Code'
                    value={values.cvv}
                    onChange={async value => await setFieldValue('cvv', value)}
                    onBlur={async () => await setFieldTouched(`cvv`, true, true)}
                    showError={false}
                    error={errors.cvv}
                />
                <span className='text-start text-sm text-destructive mt-2'>
                    {errors.cvv ? <div className='text-red-600 mb-2'>{errors.cvv}</div> : <></>}
                    {errors.number ? <div className='text-red-600 mb-2'>{errors.number}</div> : <></>}
                    {errors.expirationDate ? <div className='text-red-600 mb-2'>{errors.expirationDate}</div> : <></>}
                </span>
            </InputGroup>

            <HostedFieldWithLabel
                setRef={r => (btFieldRefs.current.cardholderName = r)}
                type='cardholderName'
                name='cardholderName'
                placeholder={'Cardholder\'s name'}
                value={values.cardholderName}
                onChange={async value => await setFieldValue('cardholderName', value)}
                onBlur={async () => await setFieldTouched(`cardholderName`, true, true)}
                error={errors.cardholderName}
                className='mb-4 sentry-mask'
                rounded={['topLeft', 'topRight', 'bottomLeft', 'bottomRight']}
            />

            <HahFormikInputField className='mb-4 sentry-mask' parentName={'paymentInfo'} id='billingZip' name='billingZip' placeholder='Enter billing zipcode' />
        </div>
    );
};
