import React, { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Icon, iconLibrary } from '@shared';

interface HahTooltipProps {
	tooltipContent: React.ReactNode;
	children?: React.ReactNode;
	triggerClassNames?: string;
	disabled?: boolean;
}

/**
 * Props for the HahTooltip component.
 *
 * @prop tooltipContent - The content to display inside the tooltip.
 * @prop children - Optional child elements that the tooltip wraps.
 * @prop triggerClassNames - Optional string for extra CSS class names on the tooltip trigger element.
 * @prop disabled - Optional boolean to disable the tooltip functionality.
 */
export const HahTooltip = ({ tooltipContent, children, triggerClassNames, disabled = false }: HahTooltipProps) => {
	const [open, setOpen] = useState(false);
	const tooltipClass = `
        select-none rounded p-4 bg-coolGrey-900 text-white text-sm font-normal leading-[21px] rounded
        shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]
        will-change-[transform,opacity]
        data-[state=delayed-open]:data-[side=bottom]:animate-wg-fade-in-up
        data-[state=delayed-open]:data-[side=left]:animate-wg-fade-in-up
        data-[state=delayed-open]:data-[side=right]:animate-wg-fade-in-up
        data-[state=delayed-open]:data-[side=top]:animate-wg-fade-in-up
        max-w-[320px] z-50
    `;

	return (
		<Tooltip.Provider>
			<Tooltip.Root delayDuration={0} open={disabled ? false : open}>
				<Tooltip.Trigger
					asChild
					onMouseEnter={() => setOpen(true)}
					onMouseLeave={() => setOpen(false)}
					onFocus={() => setOpen(true)}
					onBlur={() => setOpen(false)}>
					<span className={`focus:outline-none${triggerClassNames ? ` ${triggerClassNames}` : ''}`}>
						{children ? children : <Icon icon={iconLibrary.faCircleQuestion} className={'text-neutralGrey-700 align-middle text-base'} />}
					</span>
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content className={tooltipClass} sideOffset={5}>
						{tooltipContent}
						<Tooltip.Arrow className='fill-coolGrey-900' />
					</Tooltip.Content>
				</Tooltip.Portal>
			</Tooltip.Root>
		</Tooltip.Provider>
	);
};
