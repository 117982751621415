import { useState, useRef, useEffect, useCallback } from 'react';
import { iconLibrary, Icon, HahInput, sentryWrapper, ParsedAddress, toastError } from '@shared';
import { useCheckoutState } from '@hooks';
import { AutocompleteAddress, emptyAddress } from '@types';
import { debugLoggerInfo } from '@utils';

interface Props {
    label?: string;
    placeholder?: string;
    fullAddressModel: string;
    isUnloadAddress: boolean;
}

export const AddressAutoComplete = ({ label, placeholder, fullAddressModel, isUnloadAddress }: Props) => {
    const [error, setError] = useState<string>('');
    const [fullAddress, setFullAddress] = useState<string>(fullAddressModel);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const { model, setModel } = useCheckoutState();

    const useFetchAutocompleteSuggestions = (input: string) => {
        const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);

        // Function to clear suggestions
        const clearSuggestions = () => setSuggestions([]);

        useEffect(() => {
            if (!input) return;

            const autocompleteService = new google.maps.places.AutocompleteService();

            const request = {
                input: input,
                fields: ['geometry', 'address_components'],
                types: ['address'],
                componentRestrictions: { country: ['us'] },
            };

            autocompleteService.getPlacePredictions(request, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setSuggestions(predictions);
                } else {
                    clearSuggestions();
                }
            });
        }, [input]);

        return { suggestions };
    };

    const handlePlaceSelection = useCallback(
        async (place: google.maps.places.PlaceResult) => {
            const parsedAddress = new ParsedAddress(place);
            if (parsedAddress.invalid) {
                console.error('Google AutoComplete place could not be parsed', { place, reason: parsedAddress.invalidReason });
                sentryWrapper.logError(
                    'Google AutoComplete place could not be parsed',
                    { component: 'AddressAutoComplete' },
                    { formatted_address: place.formatted_address, place_id: place.place_id, reason: parsedAddress.invalidReason }
                );
                setError('The chosen address does not seem to be a full address. If experiencing difficulty, use the "Enter Addresses Manually" button below.');
                setFullAddress('');
                return;
            }

            setError('');
            setFullAddress(parsedAddress.placeFormattedAddress);

            debugLoggerInfo('handlePlaceSelection; setting address', { place, parsedAddress });

            setModel({
                ...model,
                [isUnloadAddress ? 'unloadAddress' : 'loadAddress']: {
                    ...model[isUnloadAddress ? 'unloadAddress' : 'loadAddress'],
                    fullAddress: parsedAddress.placeFormattedAddress,
                    placeId: parsedAddress.placeId,
                    city: parsedAddress.city,
                    state: parsedAddress.state,
                    zip: parsedAddress.zip,
                    street: parsedAddress.street,
                    street2: parsedAddress.street2,
                } as AutocompleteAddress,
            });
        },
        [isUnloadAddress, model, setModel]
    );

    const { suggestions } = useFetchAutocompleteSuggestions(inputRef.current?.value ?? '');

    const handlePredictionSelection = useCallback(
        (place: google.maps.places.AutocompletePrediction) => {
            setShowSuggestions(false);
            if (!inputRef?.current || !inputRef.current?.value) {
                return;
            }
            const service = new google.maps.places.PlacesService(inputRef.current!);
            service.getDetails({ placeId: place.place_id }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                    handlePlaceSelection(place);
                } else {
                    // log to sentry and show toastr
                    toastError({ title: 'There was an error looking up the address. Please try again, or use the "Enter Addresses Manually" button.' });
                    sentryWrapper.logError(
                        'Google AutoComplete place could not be fetched',
                        { component: 'AddressAutoComplete' },
                        { place_id: place?.place_id, status }
                    );
                }
            });
        },
        [handlePlaceSelection]
    );

    useEffect(() => {
        const inputElement = inputRef.current;

        const handleBlur = () => {
            if (inputElement && showSuggestions) {
                // If no place is selected, select the first suggestion
                if (suggestions[0]?.description && inputElement.value !== '') {
                    handlePredictionSelection(suggestions[0]);
                } else {
                    //inputElement.value = '';
                    setShowSuggestions(false);
                    setError('Please enter a valid address.');
                    setModel({
                        ...model,
                        [isUnloadAddress ? 'unloadAddress' : 'loadAddress']: {
                            ...model[isUnloadAddress ? 'unloadAddress' : 'loadAddress'],
                            fullAddress: '',
                            placeId: '',
                        },
                    });
                }
            }
        };

        inputElement?.addEventListener('blur', handleBlur);

        return () => {
            // Cleanup: remove the blur event listener
            inputElement?.removeEventListener('blur', handleBlur);
        };
    }, [handlePredictionSelection, isUnloadAddress, model, setModel, showSuggestions, suggestions]);

    const handleClear = () => {
        setShowSuggestions(false);
        setFullAddress('');
        setError('');
        setModel({
            ...model,
            [isUnloadAddress ? 'unloadAddress' : 'loadAddress']: {
                ...emptyAddress,
            },
        });
    };

    return (
        <div className='autocomplete-container relative mb-6'>
            {/* per google, dont put anything like address, etc in the name/id - so browser autofill doesnt bork it */}
            <HahInput
                className='pe-6'
                autoComplete='off'
                label={label}
                ref={inputRef}
                name='autocomplete-a'
                maxLength={500}
                placeholder={placeholder}
                required
                destructive={error !== ''}
                helperText={error}
                onClear={handleClear}
                onChange={e => {
                    setShowSuggestions(true);
                    // scroll input box into view, to improve mobile experience
                    setTimeout(() => {
                        if (inputRef.current) {
                            const offset = 50;
                            const elementTop = inputRef.current.getBoundingClientRect().top;
                            const offsetPosition = elementTop + window.scrollY - offset;
                            if (offsetPosition !== window.scrollY) {
                                window.scrollTo({
                                    top: offsetPosition,
                                    behavior: 'smooth'
                                });
                            }
                        }
                    }, 250); // delay so it has chance to open up before we scroll
                    setFullAddress(e.currentTarget.value);
                }}
                value={fullAddress}
            />
            {suggestions && suggestions.length > 0 && showSuggestions ? (
                <div className='absolute z-10 bg-white rounded-[5px] shadow border border-[#dedede] flex-col justify-start items-start inline-flex top-[76px]'>
                    <div className='h-11 justify-start items-center inline-flex  w-full'>
                        <div className='grow h-11 p-3'>
                            <div className='text-neutralGrey-400 text-sm font-medium leading-[21px]'>SUGGESTIONS</div>
                        </div>
                        <a className='p-3 cursor-pointer'>
                            <Icon className='text-neutralGrey-400' icon={iconLibrary.faTimes} />
                        </a>
                    </div>
                    {suggestions.map(suggestion => {
                        const { description, matched_substrings } = suggestion;
                        const match = matched_substrings[0]; // Assuming the first match for simplicity

                        if (!match) {
                            return null;
                        }

                        // Split the description into parts: before, match, and after
                        const beforeMatch = description.slice(0, match.offset);
                        const matchedText = description.slice(match.offset, match.offset + match.length);
                        const afterMatch = description.slice(match.offset + match.length);

                        return (
                            <div
                                className='hover:bg-neutralGrey-100 h-11 p-3 text-neutralGrey-400 w-full cursor-pointer'
                                key={suggestion.place_id}
                                onMouseDown={e => {
                                    e.preventDefault();
                                    handlePredictionSelection(suggestion);
                                }}>
                                {beforeMatch}
                                <span className='text-black'>{matchedText}</span>
                                {afterMatch}
                                <br />
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};
