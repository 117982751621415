import { checkoutClientFactory } from '@hooks';
import { getOrchestratorRootUrl } from '../../pmgConfig';

/**
 * Logs an Optimizely experiment variant to the session.
 * @param experimentID ID of the experiment
 * @param variantID variant bucked to the session
 * @param afterFunc A function to run afterward, regardless of success, non-success or an error during AJAX call.
 */
export function logExperimentVariant(experimentVendorId: string, variantVendorId: string, afterFunc?: (variantID: string) => void) {
    console.info('🧪 logExperimentVariant', experimentVendorId, variantVendorId);

    const pmgRootUrl = getOrchestratorRootUrl();

    const client = checkoutClientFactory();

    client.checkoutGetVisitorInfo()
        .then(response => {
            const visitorGuid = response.uid;
            // This should send a POST request with VisitorGuid, ExperimentVendorId, VariantVendorId
            return fetch(`${pmgRootUrl}/experiments/log-optimizely-experiment-variant`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ experimentVendorId, variantVendorId, visitorGuid }),
            });
        })
        .then(response => response.json())
        .then((result: { experimentUniqueID: string; sessionID: number; variantUniqueID: string; visitorID: number }) => {
            if (!result.variantUniqueID) {
                console.error('🧪 logExperimentVariant - failed to log experiment variant', result);
            }
            if (afterFunc) {
                console.info('🧪 logExperimentVariant had an afterFunc - firing', experimentVendorId, experimentVendorId);
                afterFunc(result.variantUniqueID ?? `${experimentVendorId}-1`);
            }
        })
        .catch((error: { message: string }) => {
            console.error('🧪 logExperimentVariant - fatal HTTP error while logging', error);
            if (afterFunc) {
                console.info('🧪 logExperimentVariant had an afterFunc - firing', experimentVendorId, experimentVendorId);
                afterFunc(variantVendorId);
            }
        });
}
