import { millisecondsToRead } from '@shared';
import React from 'react';
import { ToastContent, TypeOptions, toast } from 'react-toastify';

export type ToastMessageType = 'success' | 'info' | 'warning' | 'error';

export interface ToastMessage {
    position?: 'top-left' | 'top-right' | 'top-center' | 'bottom-left' | 'bottom-right' | 'bottom-center';
    autoClose?: number;
    hideProgressBar?: boolean;
    newestOnTop?: boolean;
    closeOnClick?: boolean;
    pauseOnFocusLoss?: boolean;
    draggable?: boolean;
    pauseOnHover?: boolean;
    className?: string;
    type: ToastMessageType;
    iconClassName?: 'string';
    closeButton?: boolean;
    title?: React.ReactNode;
    titleClassName?: string;
    message?: React.ReactNode;
    messageClassName?: string;
    uid: number | string;
    onRemove?: () => void;
}

export type ToastMessageOptions = Partial<Omit<ToastMessage, 'type'>>;

export const toastShow = (opts: ToastMessageOptions, type: TypeOptions) => {
    let content: ToastContent = opts.message ?? opts.title;
    if (opts.title && opts.message) {
        content = (
            <div>
                <div><b>{opts.title}</b></div>
                {opts.message}
            </div>
        );
    }

    let duration = opts.autoClose;
    if (!duration) {
        // This can be a ReactNode, so we need to check if it's a string before calculating the duration
        // If it's not a string, we can set a default duration
        duration = typeof content === 'string' ? millisecondsToRead(content) : 5000;
    }

    // Adjust position of toastr based on if we're mobile and if the screen is all the way to the bottom.
    const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;
    const additionalMarginClass = isAtBottom ? 'toast-bottom-margin-docked' : 'toast-bottom-margin-default';

    const defaultOpts = {
        type: type,
        toastId: opts.uid,
        autoClose: duration,
        position: opts.position ?? 'bottom-center',
        className: additionalMarginClass
    };

    toast(content, {
        ...defaultOpts,
        ...opts
    });
};

export function toastSuccess(opts: ToastMessageOptions) {
    return toastShow(opts, 'success');
}

export function toastError(opts: ToastMessageOptions) {
    return toastShow(opts, 'error');
}

export function toastWarning(opts: ToastMessageOptions) {
    return toastShow(opts, 'warning');
}

export function toastInfo(opts: ToastMessageOptions) {
    return toastShow(opts, 'info');
}

export const toastHide = (uid: number | string) => toast.dismiss(uid);
