import { ProofPoint } from '@components';
import { Footer, Headings, HeavyItemsList } from '@components';
import { BrandType } from '@generated/swaggerClient';
import { useBrandInfo, useCheckoutState } from '@hooks';
import { heavyItemsValidationSchema } from '@modules';
import { ErrorFocus, Flex, HahTooltip } from '@shared';
import { HeavyItemsFormModel } from '@types';
import { Form, Formik, FormikHelpers } from 'formik';
type TModel = HeavyItemsFormModel;

interface Props {
	onSubmitted: (action?: string) => void;
	onChange: (values: TModel, formikHelpers: FormikHelpers<TModel>) => Promise<boolean>;
	model?: TModel;
}
export const ItemsForm = ({ onSubmitted, onChange }: Props) => {
    const { model } = useCheckoutState();
    const brandInfo = useBrandInfo();
	const isHireAHelper = brandInfo.brand === BrandType.HireAHelper;
    const initialValues: TModel = {
        heavyItemOptions: { ...model.heavyItems.heavyItemOptions },
	};

    const handleSubmit = async (values: TModel, formikHelpers: FormikHelpers<TModel>) => {
        if(await onChange(values, formikHelpers)) {
			onSubmitted(values.action);
		}
	};

	//Want to show the proof point for HAH brand orders MP still gets the footer w the three features listed in a row above footer
	const proofPointContent = isHireAHelper ? <ProofPoint content={<>
        <div className='text-neutralGrey-900 mb-2'>
            <span className='font-bold'>Lighten your worries</span>
        </div>
        <div className='text-neutralGrey-900 text-sm'>95% of moves don't include items over 300lbs. Most furniture and appliances weigh less.</div>
    </>} imgSrc={'/img/lightenWorriesProofPoint.svg'} bgColor='bg-wg-blue-50' /> : null;

    const tooltip = <HahTooltip tooltipContent={<>Did you know that items over 300 pounds may require more movers? We use this extra heavy items data along with the previous information you provided, like the number of floors you have, to determine the details of your personalized moving quote. Your average couch, dresser, refrigerator, or similar items typically do not weigh more than 300 pounds.<br/><br/>NOTE: A full inventory of your items is not necessary at {brandInfo.companyName}. </>} />;
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={heavyItemsValidationSchema}
			validateOnBlur={false}
			validateOnChange
            validateOnMount
			enableReinitialize
			onSubmit={handleSubmit}>
				<Form className='form-wrapper' noValidate>
					<Flex direction='col' className='w-[576px] px-4'>
						<ErrorFocus />

						<Headings
							className={isHireAHelper ? 'mb-1 mt-5' : ''}
							primary={isHireAHelper ? 'Any Oversized Items?' : 'Any Oversized or Items Needing Special Attention?'}
							secondary={<span className="text-sm">Items over 300 pounds may require more labor {tooltip}</span>}
							imgSrc={brandInfo.brand !== BrandType.HireAHelper ? '/img/LDumbbell.svg' : undefined}
							imgAlt='Dumbbell icon'
						/>

						<HeavyItemsList />

						{proofPointContent}
					</Flex>
					<Footer />
				</Form>
		</Formik>
	);
};
