import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Headings, Footer, FatalErrorForm, PriceIncludesList, MovingPlanProviderSummary } from '@components';
import { ProviderCard, ProviderCrewSizeOptions } from '@generated/swaggerClient';
import { ApiCallResult, useCheckoutClientFactory, useCheckoutState } from '@hooks';
import {
    Flex,
    ErrorFocus,
    performDataLayerLog,
    sentryWrapper,
    useEffectAsync,
    PlusMinusButtons,
    Icon,
    iconLibrary,
    ActionButton,
    HahTooltip,
    IconStack,
    SpinnerNew,
    toastError,
} from '@shared';
import { NumHelpersAndHours, QuoteFormModel } from '@types';
import { debugLoggerInfo, formatCurrency, Urls } from '@utils';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
interface MovingPlanV1Props {
    isFetching: boolean;
    onSubmitted: (action?: string) => void;
    onChange: (values: QuoteFormModel, formikHelpers: FormikHelpers<QuoteFormModel>) => Promise<boolean>;
    selectedProvider: ProviderCard;
    bookableProviderCount: number;
    estimateNumHelpersAndHours: NumHelpersAndHours;
}

export const MovingPlanV1: React.FC<MovingPlanV1Props> = props => {
    const { onSubmitted, onChange, isFetching, estimateNumHelpersAndHours } = props;
    const { wrapApiCall } = useCheckoutClientFactory();
    const { model, setModel } = useCheckoutState();
    const { selection } = model;
    const [editing, setEditing] = useState(false);
    const [isFatalError, setIsFatalError] = useState(false);
    const [crewSizes, setCrewSizes] = useState<ApiCallResult<ProviderCrewSizeOptions[]>>();
    const navigate = useNavigate();

    const handleSubmit = async (values: QuoteFormModel, formikHelpers: FormikHelpers<QuoteFormModel>) => {
        if (await onChange(values, formikHelpers)) {
            performDataLayerLog(
                { event: 'add_to_cart', service_type: model.isLongDistance ? 'long_distance' : 'local_full_service', service_price: values.grandTotal },
                true
            );
            onSubmitted(values.action);
        }
    };

    useEffectAsync(async () => {
        debugLoggerInfo('QuoteForm.tsx - firing off useEffectAsync', { id: model.id, movers: model.recommendation.movers, selection });

        const crewSizeData = await wrapApiCall(client =>
            client.providersAvailableCrewSizes(model.id, selection.providerLocationID!, selection.transportOptionId!)
        );

        if (!crewSizeData.success) {
            // could not retrieve crew size data at all. need to show error to user/abort page
            sentryWrapper.logException(
                crewSizeData.errorObj,
                'error',
                'Could not retrieve crew size data',
                { component: 'QuoteForm' },
                { draftQuoteId: model.id, providerLocationID: selection.providerLocationID!, transportOptionID: selection.transportOptionId! }
            );

            toastError({ title:
                'There was an error loading the information for this step. Please refresh and try again, or contact us if you continue to experience difficulties.'
            });

            setIsFatalError(true);

            return;
        }

        if (crewSizeData.result!.length === 0) {
            // This should not happen but just in case, we should log it and probably set the selection back to the recommendation?
            sentryWrapper.logError(
                'No crew sizes available for selected provider',
                { component: 'QuoteForm' },
                { id: model.id, providerLocationId: selection.providerLocationID!, transportOptionId: selection.transportOptionId! }
            );

            toastError({ title: 'Your selected provider does not offer the number of crew selected.'});
            setModel({ selection: { ...selection, movers: model.recommendation.movers } });
            return;
        }

        setCrewSizes(crewSizeData);
    }, [model.id, model.recommendation.movers, selection.providerLocationID, selection.transportOptionId]);

    if (isFatalError) {
        return <FatalErrorForm />;
    }

    if (crewSizes == null) {
        // crew sizes aren't loaded yet, show spinner
        return <SpinnerNew fixed verticallyCentered text='Finding best match...' />;
    }

    if (crewSizes != null && !crewSizes.success) {
        // page cannot proceed; we've shown the user a message (see above)
        return <FatalErrorForm />;
    }

    if (!selection.grandTotal || !selection.hours || !selection.movers) {
        // This should not happen
        return <SpinnerNew fixed verticallyCentered text='Finding best match...' />;
    }

    const crewSizesSolid = crewSizes.result!;

    const initialValues: QuoteFormModel = {
        providerLocationID: selection.providerLocationID!,
        hours: selection.hours!,
        movers: selection.movers!,
        grandTotal: selection.grandTotal,
    };

    const handleChangeHours = (val: number) => {
        const isIncrease = selection.hours && val > selection.hours;
        performDataLayerLog({ event: 'customize_quote', customize_hours: isIncrease ? 'increase' : 'decrease' }, true);
        setModel({ selection: { ...selection, hours: val } });
    };

    const handleChangeCrew = (val: number) => {
        const isIncrease = selection.movers && val > selection.movers;
        performDataLayerLog({ event: 'customize_quote', customize_movers: isIncrease ? 'increase' : 'decrease' }, true);
        setModel({ selection: { ...selection, movers: val } });
    };

    const handleClickViewOtherProviders = () => {
        performDataLayerLog({ event: 'view_more_msp' }, true);
        navigate(Urls.quote.providers);
    };

    const isNextDay = DateTime.fromISO(model.jobDate!).diffNow('days').days <= 1;

    // Get just the truck size number from the transport option name
    const truckSize = model.selection.transportOptionName?.match(/\d+/)?.[0];

    // BUG: we need to calculate server-side, for the selected provider, the "can go up" and "can go down" properties for crew size.
    // you have to calculate this based on real provider list queries, because of the auto-adjustment of crew sizes.
    // we went through a lot of work/effort on the checkout v2 recommendation logic, so we need that same logic here.

    // get the minimum "size" value from crewSizesSolid
    let minAllowedCrewSize = Math.min(...crewSizesSolid.map(z => z.size));
    // never allow them to go below our estimate/recommendation
    if (minAllowedCrewSize < props.estimateNumHelpersAndHours.numHelpers) {
        minAllowedCrewSize = props.estimateNumHelpersAndHours.numHelpers;
    }

    // min allowed hours can always be based of the provider's min hours.
    // get the min allowed hours based on that min crew size
    let minAllowedLaborHours = crewSizesSolid.find(z => z.size == minAllowedCrewSize)!.minimumHours;
    // never allow them to go below our estimate/recommendation
    if (minAllowedLaborHours < props.estimateNumHelpersAndHours.laborHours) {
        minAllowedLaborHours = props.estimateNumHelpersAndHours.laborHours;
    }

    // Set them back to the original recommended provider, crew size, hours, etc
    const setSelectionToRecommended = () => {
        // our recommended provider's info will never be below our recommended hours/crew size (because we enforce it in snowpiercer)
        // so it's safe to alway set this back to the recommended provider's values
        // BUG: Because we don't actually check what the provider is returning or their minimum crew size for the estimation logic - we can technically get back an estimate of say 2x2 but there is a heavy item or something that requires say 4x2. So we should actually be looking at the actualCrewSize returned from the query to determine what we can use. That should also probably determine our minimum?
        setModel({
            selection: {
                ...model.recommendation,
                hours: estimateNumHelpersAndHours.laborHours,
                movers: estimateNumHelpersAndHours.numHelpers,
            },
        });
    };

    return (
        <>
        <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
            {formik => (
                <Form className='form-wrapper' noValidate>
                    <Flex direction='col' className='w-[576px] px-4 mx-auto'>
                        <ErrorFocus />
                        <Headings primary='We recommend this moving plan' secondary='Customize your moving plan below to see your estimated total.' />

                        {/* This section replaces all markup past <Headings> with the MovingPlanV1.cshtml look */}
                        <div className='moving-plan-card'>
                            <div className='bg-white shadow-[0px_2px_8px_0px_rgba(0,0,0,0.15)] rounded p-3 mb-6'>
                                {/* Estimated Total Block */}
                                {isFetching ? (
                                    <div className='text-center'>
                                        <div className='text-neutralGrey-500 text-xl text-center mb-3'>Calculating estimate &hellip;</div>
                                        <div className='lds-ring'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='text-center'>
                                        <div className='text-neutralGrey-500 text-xl text-center'>Estimated total:</div>
                                        {selection.grandTotal! > 0 && (
                                            <div className='text-center font-medium text-5xl text-primary-700 mb-1'>{formatCurrency(selection.grandTotal!)}</div>
                                        )}
                                        {props.selectedProvider.serviceDetails && (
                                            <div className='text-center mb-3'>
                                                <HahTooltip tooltipContent={<PriceIncludesList serviceDetails={props.selectedProvider.serviceDetails} />}>
                                                    <a href='#price-includes' className='underline text-primary-700 text-sm'>
                                                        <Icon className='pe-1' icon={iconLibrary.faCircleInfo} />
                                                        Price Includes
                                                    </a>
                                                </HahTooltip>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Controls Row: Hours, Movers and Truck Size */}
                                <div className='flex flex-row justify-between mb-4'>
                                    {/* Hours */}
                                    <div className='text-center w-1/3'>
                                        <HahTooltip tooltipContent='Amount of hours for your move'>
                                            <IconStack
                                                firstIcon={iconLibrary.faCircle}
                                                secondIcon={iconLibrary.faClock}
                                                firstIconClassNames='text-primary-50'
                                                secondIconClassNames='text-primary-600 fa-xl'
                                                containerClassName='fa-2x'
                                            />
                                        </HahTooltip>
                                        <div className='text-primary-700 font-bold text-lg'>{selection.hours} hours</div>
                                        {editing && (
                                            <div className='mt-2'>
                                                <PlusMinusButtons
                                                    isLoading={isFetching}
                                                    canDecrease={formik.values.hours > minAllowedLaborHours}
                                                    canIncrease={crewSizesSolid.some(x => x.maximumHours > formik.values.hours) ?? false}
                                                    name='hours'
                                                    editing={editing}
                                                    onChange={(val: number) => handleChangeHours(val)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* Movers */}
                                    <div className='text-center w-1/3'>
                                        <HahTooltip tooltipContent='Crew member count for your move'>
                                            <IconStack
                                                firstIcon={iconLibrary.faCircle}
                                                secondIcon={iconLibrary.faPeopleCarryBox}
                                                firstIconClassNames='text-primary-50'
                                                secondIconClassNames='text-primary-600 fa-xl'
                                                containerClassName='fa-2x'
                                            />
                                        </HahTooltip>
                                        <div className='text-primary-700 font-bold text-lg'>{selection.movers} movers</div>
                                        {editing && (
                                            <div className='mt-2'>
                                                <PlusMinusButtons
                                                    isLoading={isFetching}
                                                    canDecrease={formik.values.movers > minAllowedCrewSize}
                                                    canIncrease={crewSizesSolid.some(x => x.size > formik.values.movers) ?? false}
                                                    name='movers'
                                                    editing={editing}
                                                    onChange={(val: number) => handleChangeCrew(val)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* Truck Size */}
                                    <div className='text-center w-1/3'>
                                        <HahTooltip tooltipContent='Truck size required for your move'>
                                            <IconStack
                                                firstIcon={iconLibrary.faCircle}
                                                secondIcon={iconLibrary.faTruck}
                                                firstIconClassNames='text-primary-50'
                                                secondIconClassNames='text-primary-600 fa-xl'
                                                containerClassName='fa-2x'
                                            />
                                        </HahTooltip>
                                        <div className='text-primary-700 font-bold text-lg'>1 Truck</div>
                                        {editing && (
                                            <div className='mt-2 font-medium'>
                                                <HahTooltip tooltipContent='Truck size is determined by your needs and provider availability.'>
                                                    {truckSize} ft
                                                </HahTooltip>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Buttons: Customize Your Plan & Back to Recommendation */}
                                <div className='flex justify-center mt-2'>
                                    {!editing && (
                                        <ActionButton
                                            type='button'
                                            className='px-6 mb-2 text-base font-medium border-coolGrey-700 text-coolGrey-700 hover:bg-coolGrey-700 hover:text-white'
                                            variant='outline'
                                            shape='pill'
                                            onClickAction={setEditing}
                                            args={true}>
                                            Customize your plan
                                        </ActionButton>
                                    )}
                                    {editing && (
                                        <ActionButton
                                            type='button'
                                            className='px-5 mb-2 text-base font-medium border-coolGrey-700 text-coolGrey-700 hover:bg-coolGrey-700 hover:text-white'
                                            variant='outline'
                                            shape='pill'
                                            disabled={isFetching}
                                            onClickAction={setSelectionToRecommended}>
                                            &#8592; Back to recommendation
                                        </ActionButton>
                                    )}
                                </div>

                                {/* Optionally show loading animation if querying */}
                                {isFetching && (
                                    <div className='text-center'>
                                        <div className='lds-ring my-6'>
                                            <div />
                                            <div />
                                            <div />
                                            <div />
                                        </div>
                                    </div>
                                )}

                                {!isFetching && <MovingPlanProviderSummary provider={props.selectedProvider} />}

                                {/* Select a different provider button */}
                                {props.bookableProviderCount > 1 && (
                                    <div className='flex justify-center mt-2'>
                                        <ActionButton
                                            className='px-6 mb-2 text-base font-medium border-coolGrey-700 text-coolGrey-700 hover:bg-coolGrey-700 hover:text-white'
                                            variant='outline'
                                            shape='pill'
                                            disabled={isFetching}
                                            onClickAction={handleClickViewOtherProviders}>
                                            Select a different provider
                                        </ActionButton>
                                    </div>
                                )}
                            </div>

                            {/* Due Today and Guarantee Section */}
                            <div className='mt-4 sm:px-8'>
                                {!isNextDay && (
                                    <>
                                        <div className='flex flex-row justify-between text-sm'>
                                            <div className='flex items-center mb-3 md:mb-0'>
                                                <h5 className='text-primary-700'>Due today</h5>
                                            </div>
                                            <div className='flex items-center md:justify-end mb-3 md:mb-0'>
                                                <h5 className='text-primary-700'>$0</h5>
                                            </div>
                                        </div>
                                        <div className='pt-3'>
                                            <div>No charges until the day before the move.</div>
                                        </div>
                                    </>
                                )}
                                <div className='pt-3 flex flex-row justify-between'>
                                    <div className='flex items-center'>
                                        <Icon className='mr-2 hidden md:inline text-primary-700' icon={iconLibrary.faCheck} />
                                        <span>Best Price and Value Guarantee</span>
                                    </div>
                                    <div className='flex items-center md:justify-end text-primary-700'>
                                        <span>Included</span>
                                    </div>
                                </div>
                                <div className='pt-3 flex flex-row justify-between'>
                                    <div className='flex items-center'>
                                        <Icon className='mr-2 hidden md:inline text-primary-700' icon={iconLibrary.faCheck} />
                                        <span>Insured, Licensed and Vetted Professionals</span>
                                    </div>
                                    <div className='flex items-center md:justify-end text-primary-700'>
                                        <span>Included</span>
                                    </div>
                                </div>
                                <div className='py-3 flex flex-row justify-between'>
                                    <div className='flex items-center'>
                                        <Icon className='mr-2 hidden md:inline text-primary-700' icon={iconLibrary.faCheck} />
                                        <span className='md:hidden'>Customer Service 365 Days/Year</span>
                                        <span className='hidden md:inline'>Customer Service 365 Days a Year</span>
                                    </div>
                                    <div className='flex items-center md:justify-end text-primary-700'>
                                        <span>Included</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Flex>
                    <Footer continueButtonContent='Next Step' showFooterContent={false} />
                </Form>
            )}
        </Formik>
        </>
    );
};

export default MovingPlanV1;
