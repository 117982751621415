import { DiscountComponent } from '@components';
import { FatalErrorForm } from '@components';
import { GetCancellationFeeBreakdownDto, GetCancellationFeeBreakdownResponseDto } from '@generated/swaggerClient';
import { useCheckoutClientFactory, useCheckoutState } from '@hooks';
import { CancellationFeesList, formatNumber, sentryWrapper, toastError, useEffectAsync } from '@shared';
import { Flex, getCancellationDate, isJobDateWithin48Hrs, HahCollapsible, Icon, iconLibrary, Modal } from '@shared';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';

export const QuoteSummary = () => {
    const { model } = useCheckoutState();
    const [open, setOpen] = useState(false);
    const { wrapApiCall } = useCheckoutClientFactory();
    const [cancellationFeeBreakPoints, setCancellationFeeBreakpoints] = useState<GetCancellationFeeBreakdownResponseDto[] | undefined>(undefined);
    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
    const cancellationFeesRef = useRef<HTMLDivElement>(null);
    const [isFatalError, setIsFatalError] = useState(false);

    useEffectAsync(async () => {
        // we should have the selection data - because Quote.tsx loads it - and Continue.tsx loads it too if we're continuing..

        const request = {
            orderGrandTotal: model.selection.grandTotal!,
            extraHourRate: model.selection.extraHourRate!,
        } as GetCancellationFeeBreakdownDto;

        const res = await wrapApiCall(client => client.checkoutGetCancellationFeeBreakdown(request));

        if (!res.success) {
            sentryWrapper.logException(res.errorObj, 'error', 'Get cancellation fee breakdown server call failed', { component: 'QuoteSummary' }, { request: JSON.stringify(request) });

            toastError({ title: 'There was an error calculating the jobs cancellation fees.' });

            setIsFatalError(true);

            return;
        }

        setCancellationFeeBreakpoints(res.result)

    }, [model.selection.grandTotal, model.selection.extraHourRate, model.selection]);

    if (isFatalError) {
        return <FatalErrorForm />;
    }

    // if we don't have a grand total, we can't show the quote summary and something has gone wrong so let's log it and send them to the error page
    if (model.selection.grandTotal == null || model.selection.extraHourRate == null || model.jobDate == null) {
        sentryWrapper.logFatal('model.selection.grandTotal, model.selection.extraHourRate, or model.jobDate is null', { component: 'QuoteSummary' });

        toastError({ title: 'There was an error loading the quote summary. Please try again.' });

        // send them to fatal error page, i think
        return <FatalErrorForm />;
    }

    // Potentially this should all come from the server, or at least be in some hook/utility function
    const grandTotalFixed = formatNumber(model.selection.grandTotal, { truncateZero: true }); // toFixed should NOT be needed here

    // FUTURE: please stop putting business logic in RJS. all of this (including getCancellationDate) should be the responsibility of the server; RJS is just the view/UX for it.
    const dateFormatted = DateTime.fromISO(model.jobDate!).toLocaleString(DateTime.DATE_HUGE);
    const dayBefore = DateTime.fromISO(model.jobDate!).minus({ days: 1 }).toLocaleString(DateTime.DATE_MED);
    const isNextDay = DateTime.fromISO(model.jobDate!).diffNow('days').days <= 1;
    const freeCancellationBefore = getCancellationDate(model.jobDate!);

    const handleCancellationFeesClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setIsCollapsibleOpen(true);
        setTimeout(() => {
            if (cancellationFeesRef.current) {
                cancellationFeesRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 250); // delay so it has chance to open up before we scroll
    };

    // adjust subtitle based on time until job
    const isWithin48Hours = isJobDateWithin48Hrs(model.jobDate!);
    const subtitle = isWithin48Hours ? <span>Your move is scheduled in less than 48 hours and will incur a fee if cancelled. <a href="#" onClick={handleCancellationFeesClick}>View fees</a>.</span> : <span>FREE cancellation or reschedule before {freeCancellationBefore}</span>;

    return (
        <div className='relative mb-5'>
            <Modal open={open} setOpen={setOpen} title={''} onConfirm={() => setOpen(false)} confirmContent={<><Icon className='mr-2' size='lg' icon={iconLibrary.faCheck} /> Great! I Understand</>}>
                <div className='flex justify-center'>
                    <img src={'/img/moveprotection.svg'} style={{ height: 100 }} />
                </div>

                <h1 className='text-center'>Move Protection Fee</h1>

                <p className='mb-5'>
                    We understand moving can be stressful, which is why our Move Protection Fee ensures you have access to customer service 7 days a week to
                    call/text/chat with experienced agents, the lowest pricing available and fully vetted, insured, and licensed professional movers.
                </p>

                <p>This provides you with peace of mind and reliable support every step of the way.</p>
            </Modal>
            {/* Overlay (shown when isDisabled is true)
			{isDisabled && (
				<div className='absolute inset-0 rounded-3xl bg-neutralGrey-500 bg-opacity-50 flex items-center justify-center z-10' />
			)} */}
            <Flex grow={1} direction='col' className='p-5 bg-white rounded-3xl shadow'>
                <HahCollapsible justify="between" onStateChange={(isOpen) => setIsCollapsibleOpen(isOpen)} showDivider={true} title={'Quote Summary'} subtitle={subtitle}>
                    <div className='mt-3'>
                        <Flex className='mb-2' align='center'>
                            <div>
                                <Icon fixedWidth className='text-primary-700 me-2' icon={iconLibrary.faClock} />
                            </div>
                            <div>
                                <p className='text-sm'>{model.preferredStartTime}</p>
                            </div>
                        </Flex>
                        <Flex className='mb-2' align='center'>
                            <div>
                                <span className="fa-layers fa-fw me-2">
                                    <Icon fixedWidth className='text-primary-700' icon={iconLibrary.faCircle} />
                                    <Icon fixedWidth icon={iconLibrary.faA} transform="shrink-6" inverse />
                                </span>
                            </div>
                            <div>
                                <p className='text-sm'>{model.loadAddress.fullAddress}</p>
                            </div>
                        </Flex>
                        <Flex className='mb-2' align='center'>
                            <div>
                                <span className="fa-layers fa-fw me-2">
                                    <Icon fixedWidth className='text-primary-700' icon={iconLibrary.faCircle} />
                                    <Icon fixedWidth icon={iconLibrary.faB} transform="shrink-6" inverse />
                                </span>
                            </div>
                            <div>
                                <p className='text-sm'>{model.unloadAddress.fullAddress}</p>
                            </div>
                        </Flex>
                        <Flex className='mb-2' align='center'>
                            <div>
                                <Icon fixedWidth className='text-primary-700 me-2' icon={iconLibrary.faCalendar} />
                            </div>
                            <div>
                                <p className='text-sm'>{dateFormatted}</p>
                            </div>
                        </Flex>
                        <Flex className='mb-2' align='center'>
                            <div>
                                <Icon fixedWidth className='text-primary-700 me-2' icon={iconLibrary.faTruck} />
                            </div>
                            <div>
                                <p className='text-sm'>{model.selection.transportOptionName}</p>
                            </div>
                        </Flex>
                        <hr className='border-hahGrey my-4' />

                        <div className='flex justify-between mt-2' onClick={() => setOpen(true)}>
                            <div className='text-sm'>
                                Move Protection Fee <Icon className='text-primary-700 ms-1' icon={iconLibrary.faCircleInfo} />
                            </div>
                            <div>
                                <p className='text-sm text-primary-700'>Included</p>
                            </div>
                        </div>
                    </div>
                </HahCollapsible>
                <DiscountComponent />
                <div className='bg-primary-50 mt-4 p-3'>
                    <Flex justify='between' className='mb-2'>
                        <p>Estimated total</p>
                        <p>${grandTotalFixed}</p>
                    </Flex>
                    <Flex justify='between'>
                        <h2 className='text-lg font-medium mb-0'>Total Due {dayBefore}</h2>
                        <h2 className='text-lg font-medium mb-0'>${grandTotalFixed}</h2>
                    </Flex>
                    <p className='text-xs mb-2'>You will be charged 24 hours before your move date</p>

                    <Flex justify='between'>
                        <h2 className='font-medium text-primary-700 mb-0'>Due today</h2>
                        <h2 className='font-medium text-primary-700 mb-0'>{isNextDay ? `$${grandTotalFixed}` : '$0'}</h2>
                    </Flex>
                </div>
                {isCollapsibleOpen && (
                    <div ref={cancellationFeesRef} className={`mt-3transition-[opacity,transform] duration-700 ease-in-out ${isCollapsibleOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}>
                        <CancellationFeesList cancellationFees={cancellationFeeBreakPoints!} />
                    </div>
                )}
            </Flex>
        </div>
    );
};
