import { iconLibrary, Flex, Icon, HahTooltip, ActionButton } from '@shared';
import { useField } from 'formik';

interface PlusMinusButtonsProps {
    isLoading: boolean;
    name: string;
    label?: string;
    editing: boolean;
    canIncrease: boolean;
    canDecrease: boolean;
    onChange: (value: number) => void;
}

export const PlusMinusButtons = ({ name, label, editing, onChange, canIncrease, canDecrease, isLoading }: PlusMinusButtonsProps) => {
    const [inputProps, , helpers] = useField(name);

    const increment = () => {
        if (canIncrease) {
            helpers.setValue(inputProps.value + 1);
            onChange(inputProps.value + 1);
        }
    };

    const decrement = () => {
        if (canDecrease) {
            helpers.setValue(inputProps.value - 1);
            onChange(inputProps.value - 1);
        }
    };

    const withTooltip = (component: React.ReactNode, tooltipContent: string, condition: boolean) => {
        return condition ? <HahTooltip tooltipContent={tooltipContent}>{component}</HahTooltip> : component;
    };

    const decreaseDisabled = isLoading || canDecrease === false;
    const increaseDisabled = isLoading || canIncrease === false;

    return (
        <>
            {label}
            {editing && (
                <Flex direction='row' className='gap-3 mt-1' align='center' justify='center'>
                    {withTooltip(
                        <ActionButton
                            disabled={decreaseDisabled}
                            type='button'
                            shape='pill'
                            variant='primary'
                            onClickAction={decrement}
                            className='text-neutralGrey-600 bg-neutralGrey-100 hover:bg-neutralGrey-100 shadow w-8 h-8 disabled:opacity-50'
                        ><Icon icon={iconLibrary.faMinus} className='w-3.5 h-3.5 align-middle' /></ActionButton>,
                        `Minimum ${name} reached`,
                        decreaseDisabled
                    )}
                    <div className='text-lg font-medium leading-[27px]'>{inputProps.value}</div>
                    {withTooltip(
                        <ActionButton
                            type='button'
                            shape='pill'
                            variant='primary'
                            onClickAction={increment}
                            disabled={increaseDisabled}
                            className='text-primary-600 bg-primary-50 hover:bg-primary-50 shadow w-8 h-8 disabled:opacity-50'
                        ><Icon icon={iconLibrary.faPlus} className='w-3.5 h-3.5 align-middle' /></ActionButton>,
                        `Maximum ${name} reached`,
                        increaseDisabled
                    )}
                </Flex>
            )}
        </>
    );
};
