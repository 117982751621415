import braintree, { Client } from 'braintree-web';
import { sentryWrapper, toastError, ToastMessage, useEffectOnlyOnce } from '@shared';
import { debugLoggerError, debugLoggerInfo, js, js2 } from '@utils';

export const useGooglePayButton = (
    googleMerchantID: string,
    clientInstance: Client | null,
    grandTotal: string,
    buttonRef: React.MutableRefObject<HTMLDivElement | null>,
    handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void
) => {

    useEffectOnlyOnce(() => {
        debugLoggerInfo('firing off useGooglePayButton init via useEffectOnlyOnce');
        if (!clientInstance) {
            sentryWrapper.logError('PayPal client instance not available', { hook: 'useGooglePayButton' });
            toastError({ title: 'PayPal client instance not available' });
            return;
        }

        setupGooglePay(googleMerchantID, clientInstance, grandTotal, buttonRef, handlePaymentMethodApproved, toastError);
    });
};

function setupGooglePay(
    googleMerchantID: string,
	clientInstance: Client,
	grandTotal: string,
	buttonRef: React.MutableRefObject<HTMLElement | null>,
	handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void,
    toastError: (opts: Partial<Omit<ToastMessage, 'type'>>) => void
) {
	// Make sure to have https://pay.google.com/gp/p/js/pay.js loaded on your page

	const isSandbox = true; // Set to false for production

	const googlePayClient = new google.payments.api.PaymentsClient({
		environment: isSandbox ? 'TEST' : 'PRODUCTION',
	});

	braintree.googlePayment.create(
		{
			client: clientInstance, // From braintree.client.create, see below for full example
			googlePayVersion: 2,
			googleMerchantId: googleMerchantID ?? undefined, // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
		},
		(err, googlePaymentInstanceRaw) => {
			if (err || !googlePaymentInstanceRaw) {
				// INTEGRATE: log to sentry errors in creating the Google Pay instance
				// maybe we just turn off the google pay button and log it?

                sentryWrapper.logException(err, 'error', 'Error creating Google Pay instance', { hook: 'useGooglePayButton' });

                toastError({ title: 'We\'re sorry, there appears to have been a problem initializing Google Pay.' });
				debugLoggerError('🤑 Error calling braintree.googlePayment.create', js(err));
				//this.taskComplete(PageTasks.SetupGooglePay, false);
				return;
			}

			// get the allowed payment methods via braintree's google pay code
			const allowedPaymentMethods = googlePaymentInstanceRaw.createPaymentDataRequest().allowedPaymentMethods;

			debugLoggerInfo('🤑 allowedPaymentMethods', js(allowedPaymentMethods));

			googlePayClient
				.isReadyToPay({
					// see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest for all options
					apiVersion: 2,
					apiVersionMinor: 0,
					allowedPaymentMethods: allowedPaymentMethods,
					//existingPaymentMethodRequired: true, // Optional - If set to true then the IsReadyToPayResponse object includes an additional paymentMethodPresent property that describes the visitor's readiness to pay with one or more payment methods specified in allowedPaymentMethods.
				})
				.then((isReadyToPayResponse: google.payments.api.IsReadyToPayResponse) => {
					if (isReadyToPayResponse.result) {
						// Set up Google Pay button
						const buttonClick = (event: Event) => {
                            // This is a hack to check if the user has agreed to the terms and conditions - not the REACT friendly way but we're not doing the REACT friendly way for any of this code really
                            const tosCheck = document.querySelector('input[name="agreedToTos"') as HTMLInputElement;
                            if (!tosCheck.checked) {
                                toastError({ title: 'Please agree to our Terms of Service before booking.' });
                                return;
                            }
							event.preventDefault();

							const paymentDataRequest = googlePaymentInstanceRaw.createPaymentDataRequest({
								transactionInfo: {
									currencyCode: 'USD',
									totalPriceStatus: 'FINAL', // this matches what drop-in does
									totalPrice: grandTotal,
								},
							}) as google.payments.api.PaymentDataRequest;

							// We recommend collecting billing address information, at minimum
							// billing postal code, and passing that billing postal code with all
							// Google Pay card transactions as a best practice.
							// See all available options at https://developers.google.com/pay/api/web/reference/object
							const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
							cardPaymentMethod.parameters.billingAddressRequired = true;
							cardPaymentMethod.parameters.billingAddressParameters = {
								format: 'FULL', // this asks for a full billing address. this is what the drop-in does, so im leaving it alone
								phoneNumberRequired: false, // dont require a billing phone; this is default anyway but just to be explicit
							};

							googlePayClient
								.loadPaymentData(paymentDataRequest)
								.then(paymentData => {
									googlePaymentInstanceRaw.parseResponse(paymentData, (err, result) => {
										if (err) {
											// INTEGRATE: log parsing error to sentry and gracefully handle. probably disable google pay button/dont render.
                                            sentryWrapper.logException(err, 'error', 'googlePaymentInstance.parseResponse error', { hook: 'useGooglePayButton' });
                                            toastError({ title: 'We\'re sorry, there appears to have been a problem with the Google Pay payment. You may want to try again, or try another form of payment.' });
											debugLoggerError('🤑 googlePaymentInstance.parseResponse error', js(err));
											//this.taskComplete(PageTasks.SetupGooglePay, false);
											return;
										}

										// Send result.nonce to your server
										// result.type may be either "AndroidPayCard" or "PayPalAccount", and
										// paymentData will contain the billingAddress for card payments
										handlePaymentMethodApproved(
											result.nonce,
											paymentData.paymentMethodData.info?.billingAddress?.postalCode,
                                            paymentData.paymentMethodData.info?.billingAddress?.name
										);

										// example of returned data for `result`:
										/*
                                    {
                                        "nonce": "e6dacb12-96e1-189e-7dfe-537a95508e48",
                                        "type": "AndroidPayCard",
                                        "description": "Android Pay",
                                        "details": {
                                            "cardType": "MasterCard",
                                            "lastFour": "0587",
                                            "lastTwo": "87",
                                            "isNetworkTokenized": false,
                                            "bin": "555555"
                                        },
                                        "binData": {
                                            "prepaid": "Unknown",
                                            "healthcare": "Unknown",
                                            "debit": "Unknown",
                                            "durbinRegulated": "Unknown",
                                            "commercial": "Unknown",
                                            "payroll": "Unknown",
                                            "issuingBank": "Unknown",
                                            "countryOfIssuance": "Unknown",
                                            "productId": "Unknown"
                                        }
                                    }
                                    */
										// example of retunred data for `paymentData` in MIN mode:
										/*
                                   {
                                        "apiVersion": 2,
                                        "apiVersionMinor": 0,
                                        "paymentMethodData": {
                                        "description": "Mastercard••••0587",
                                        "info": {
                                            "billingAddress": {
                                            "countryCode": "US",
                                            "name": "Nicholas Head",
                                            "postalCode": "92057"
                                            },
                                            "cardDetails": "0587",
                                            "cardNetwork": "MASTERCARD"
                                        },
                                        "tokenizationData": {
                                            "token": "{\"androidPayCards\":[{\"type\":\"AndroidPayCard\",\"nonce\":\"43814d1c-4d3d-1eb2-4c62-c326dad81bb4\",\"description\":\"Android Pay\",\"consumed\":false,\"threeDSecureInfo\":null,\"details\":{\"bin\":\"555555\",\"cardType\":\"MasterCard\",\"isNetworkTokenized\":false,\"lastTwo\":\"87\",\"lastFour\":\"0587\"},\"binData\":{\"prepaid\":\"Unknown\",\"healthcare\":\"Unknown\",\"debit\":\"Unknown\",\"durbinRegulated\":\"Unknown\",\"commercial\":\"Unknown\",\"payroll\":\"Unknown\",\"issuingBank\":\"Unknown\",\"countryOfIssuance\":\"Unknown\",\"productId\":\"Unknown\"}}]}",
                                            "type": "PAYMENT_GATEWAY"
                                        },
                                        "type": "CARD"
                                        }
                                    }
                                    */

										debugLoggerInfo('🤑 googlePaymentInstance.parseResponse', js({ paymentData, result }));
									});
								})
								.catch(err => {
									if (err.statusCode && err.statusCode == 'CANCELED') {
										// INTEGRATE: they cancelled google payment window - show message maybe? clear payment method?
										// there may be other "okay" status codes here, not sure. you should check their SDK docs or just see what testing reveals
                                        sentryWrapper.logWarn('Google Pay payment was cancelled by user', { hook: 'useGooglePayButton' }, { err });
									} else {
										// INTEGRATE: log error to sentry and gracefully handle. probably disable google pay button/dont render.
                                        sentryWrapper.logFatal('Google Pay payment had an error', { hook: 'useGooglePayButton' }, { err });

                                        toastError({ title: 'We\'re sorry, there appears to have been a problem with the Google Pay payment. You may want to try again, or try another form of payment.' });
									}

									debugLoggerError('🤑 paymentsClient.loadPaymentData error', js(err));
									//this.taskComplete(PageTasks.SetupGooglePay, false);
									return;
								});
						};

						// ** render button

						// You will need a button element on your page styled according to Google's brand guidelines
						// https://developers.google.com/pay/api/web/guides/brand-guidelines

						// INTEGRATE: need andrew/product to tell us exact options for the button look/feel below
						const button = googlePayClient.createButton({
							buttonColor: 'black',
							buttonType: 'book',
							buttonRadius: 4,
							buttonLocale: 'en',
							buttonSizeMode: 'fill',
							onClick: buttonClick,
							allowedPaymentMethods: [], // use the same payment methods as for the loadPaymentData() API call
						});

						if (buttonRef.current) {
							buttonRef.current.innerHTML = ''; // clear out any existing button
							buttonRef.current.appendChild(button);
						}

						//this.taskComplete(PageTasks.SetupGooglePay, true);
					} else {
						// we had no result? log error i guess? and probably dont render google pay button...

                        sentryWrapper.logError('isReadyToPayResponse came back false', { hook: 'useGooglePayButton' }, { response: js2(isReadyToPayResponse) });

						// INTEGRATE: Probably log to sentry as a warning, and disable the google pay button/dont render if needed?
                        toastError({ title: 'We\'re sorry, there appears to have been a problem with the Google Pay payment. You may want to try again, or try another form of payment.' });

						debugLoggerError('🤑 isReadyToPayResponse.result was false', js(isReadyToPayResponse));
						//this.taskComplete(PageTasks.SetupGooglePay, false);
						return;
					}
				})
				.catch(err => {
                    sentryWrapper.logException(err, 'error', 'isReadyToPay error', { hook: 'useGooglePayButton' });

					// Handle errors from promise chain
                    toastError({ title: 'We\'re sorry, there appears to have been a problem with the Google Pay payment. You may want to try again, or try another form of payment.' });
					debugLoggerError('🤑 paymentsClient.isReadyToPay error', js(err));
					//this.taskComplete(PageTasks.SetupGooglePay, false);
					return;
				});
		}
	);
}
