import { Flex } from "@shared"
interface PorchHomeProtectionPlanProps {
    content: JSX.Element;
}
export const PorchHomeProtectionPlan = (props: PorchHomeProtectionPlanProps) => {
    return (
        <Flex direction='col' className="p-4 bg-white rounded-2xl shadow-[0px_2px_4px_0px_rgba(0,0,0,0.08)] gap-2 my-6">
            <div className="justify-center items-center gap-4 inline-flex grow">
                <img className="w-12 h-12 text-primary-500 block shrink" src="/img/app-logo.png" />
                <div className="flex-col justify-center flex grow">
                    {props.content}
                </div>
            </div>
        </Flex>
    )
}
