import { Headings, PorchHomeProtectionPlan } from '@components';
import { Flex, HahMap, Icon, iconLibrary, performDataLayerLog, sentryWrapper } from '@shared';
import confetti from 'canvas-confetti';
import { customerLoginUrl } from '../pmgConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { Urls } from '@utils';
import { CheckoutModel } from '@types';

const celebrateGoodTimesCmon = () => {
    performDataLayerLog({ event: 'click' }, true);
    confetti({
        particleCount: 200,
        spread: 100,
        origin: { y: 0.6 },
    });
};

const currentYear = new Date().getFullYear();

export const ConfirmationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const state = location.state as { model: CheckoutModel }; // Cast the state type
    const model = state?.model;

    const porchHomeProtectionContent = (
        <>
            <div className='text-neutralGrey-900 mb-2'>
                Protect your new home for <span className='font-bold'>60 Days Free!</span>
            </div>
            <div className='text-primary-600 font-medium underline flex'>
                <span className='grow'>Activate your home waranty now</span><Icon size='lg' icon={iconLibrary.faArrowRight} />
            </div>
        </>
    );

    if (!model) {
        sentryWrapper.logWarn(
            'User tried to access confirmation page without being sent there from the checkout page',
            { component: 'ConfirmationPage' },
            { model: JSON.stringify(model) }
        );
        navigate(Urls.locations.index);
        return;
    }

    return (
        <>
            <Flex grow={1} direction='col' className='w-[576px] px-4'>
                <Headings className='mt-6 md:mt-12' primary='Congratulations, Your Move is Booked!' secondary />
                <div className='mb-5'>
                    <p className='text-neutralGrey-700 mb-5'>
                        Now that your move is booked your personalized move details are being shared with your movers, {model.selection.providerName}.
                    </p>

                    <p className='text-neutralGrey-700'>
                        If you need to make any changes prior to your move date, please access your Porch Moving Dashboard. You should receive a confirmation
                        email of your move sent to {model.contact.email}.
                    </p>
                </div>
                <a id='home-warranty-link' target='_blank' href='https://porchwarranty.com/movingwarrantyoffer'><PorchHomeProtectionPlan content={porchHomeProtectionContent} /></a>
                <Flex direction='col' className='p-5 bg-white rounded-3xl shadow'>
                    <HahMap
                        className='border border-primary-200'
                        showDirections={true}
                        loadAddress={model.loadAddress.fullAddress}
                        unloadAddress={model.unloadAddress.fullAddress}
                    />
                    <div className='mb-3 p-3 bg-primary-600 text-white text-center'>
                        <h3 className='mb-0 text-lg'>Your Move is Booked!</h3>
                    </div>

                    <Flex justify='between' align='center' direction='col' className='sm:flex-row gap-4'>
                        <div className='flex-shrink-0'>
                            <a href={customerLoginUrl} className='text-sm'>
                                Log in to customer portal
                            </a>
                        </div>
                        <div className='flex-shrink-0'>
                            <button type='button' className='underline font-bold text-sm' onClick={celebrateGoodTimesCmon}>
                                🎉Celebrate with confetti
                            </button>
                        </div>
                    </Flex>
                </Flex>

                {/* Save for V2? */}
                {/*<h2 className="text-neutralGrey-950 text-center">Need more services? We have you covered!</h2>
                <div className="flex justify-between mb-5">
                    <div className="flex-shrink-0">
                        <SmallFeature icon={iconLibrary.faPeopleCarryBox}  top={'Packing Supplies'} bottom={'Get Now'}  />
                    </div>
                    <div className="flex-shrink-0">
                        <SmallFeature icon={iconLibrary.faScrewdriverWrench} top="Auto Shipments" bottom="Get Now" />
                    </div>
                </div> */}
            </Flex>

            <div className='container px-5 mx-auto'>
                <Flex justify='start' align='center' direction='col' className='py-12 gap-4 md:flex-row'>
                    <div className='grow shrink text-neutralGrey-500 text-sm'>© {currentYear} Porch Moving Group, LLC. All rights reserved. </div>
                    <div className='justify-end items-center gap-6 flex'>
                        <a target='_blank' href='https://www.porchmovinggroup.com/legal/privacy/' className='text-sm'>
                            Privacy
                        </a>
                        <a target='_blank' href='https://www.porchmovinggroup.com/legal/' className='text-sm'>
                            Terms
                        </a>
                        {/* FUTURE: This page doesn't exist..? <a href='' className='text-sm'>Sitemap</a> */}
                    </div>
                </Flex>
            </div>
        </>
    );
};
