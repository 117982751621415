import { useCheckoutPages } from "@hooks";
import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * Uses the useCheckoutPages hook to determine the next and previous pages
 * @see useCheckoutPages
 * @returns A navigation function that will navigate to the next or previous page based on the passed in action
 */
export const useCheckoutNavigation = () => {
    const navigate = useNavigate();
    const { nextUrl, previousUrl } = useCheckoutPages();
    const onNavigate = React.useCallback((action?: string) => {
        switch (action) {
            case 'next':
                navigate(nextUrl!);
                break;
            case 'back':
                navigate(previousUrl!);
                break;
        }
    }, [navigate, nextUrl, previousUrl]);
    return { onNavigate };
}