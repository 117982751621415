import { BraintreeClientPaymentMethod, PropertyType } from '@generated/swaggerClient';
import { AddressBasic, LocationDetailsFormModel, LocationsFormModel, TimeSelectionFormModel, HeavyItemsFormModel, ContactModel, CheckoutFormModel, PaymentInfoModel, DateFormModel, LdFormModel, SaveQuoteFormModel, SavePlaceFormModel } from '@types';
import * as Yup from 'yup';

const addressSchema: Yup.ObjectSchema<AddressBasic> = Yup.object().shape({
	zip: Yup.string()
		.defined()
		.min(5, 'Please enter a valid zip code')
		.matches(/^\d{5}$/, 'Please enter a valid zip code')
		.when('$manualAddressEntry', {
			is: true, // Check if manualAddressEntry is true
			then: schema => schema.required('Please enter a zip code'),
			otherwise: schema => schema.notRequired(),
		}),
	street: Yup.string()
		.defined()
		.min(2, 'Street Address must be at least 2 characters')
		.max(500, 'Street Address has a maximum of 500 characters')
		.when('$manualAddressEntry', {
			is: true,
			then: schema => schema.required('Street Address Required'),
			otherwise: schema => schema.notRequired(),
		}),
	city: Yup.string()
		.defined()
		.min(2, 'Please enter a valid city')
		.max(50, 'City has a maximum of 50 characters')
		.when('$manualAddressEntry', {
			is: true,
			then: schema => schema.required('City Required'),
			otherwise: schema => schema.notRequired(),
		}),
	street2: Yup.string().defined().default('').max(50, 'Suite/Apt # must be 50 characters or less'),
	state: Yup.string()
		.defined()
		.min(2, 'Please enter a valid state abbreviation')
		.max(2, 'Please enter a valid state abbreviation')
		.when('$manualAddressEntry', {
			is: true,
			then: schema => schema.required('Please enter a valid state abbreviation'),
			otherwise: schema => schema.notRequired(),
		}),
});

type LocationFormValidationModel = Omit<LocationsFormModel, 'loadAddress' | 'unloadAddress' | 'action'> & {
	loadAddress: AddressBasic;
	unloadAddress: AddressBasic;
};

export const locationsValidationSchema: Yup.ObjectSchema<LocationFormValidationModel> = Yup.object().shape(
	{
		loadAddress: addressSchema,
		unloadAddress: addressSchema,
		manualAddressEntry: Yup.boolean().required(),
	},
	[
		['loadAddress', 'manualAddressEntry'],
		['unloadAddress', 'manualAddressEntry'],
	]
);

export const locationDetailsValidationSchema: Yup.ObjectSchema<Omit<LocationDetailsFormModel, 'action'>> = Yup.object().shape({
	propertyType: Yup.string().oneOf(Object.values(PropertyType).filter(v => v !== PropertyType.Unknown)).required('Please select a property type'),
	bedrooms: Yup.number().required('Please select the number of bedrooms'),
	stairs: Yup.number().required('Please select the number of floors/landings'),
	street2: Yup.string().when('propertyType', {
		is: (x: PropertyType) => x === PropertyType.CondoApt,
		then: schema => schema.required('Unit # is required for an Apartment/Condo'),
	}),
	hasElevator: Yup.boolean().when('propertyType', {
		is: (x: PropertyType) => x === PropertyType.CondoApt,
		then: schema => schema.required('Please select if there is an elevator'),
	}),
});

export const heavyItemsValidationSchema: Yup.ObjectSchema<Omit<HeavyItemsFormModel, 'action'>> = Yup.object().shape({
	// hasHeavyItems: Yup.boolean().required('Please specify if there are heavy items'),
	// notes: Yup.string().max(1000, 'Notes must be 1000 characters or less'),
    heavyItemOptions: Yup.object()
		.shape({
			hasSafe: Yup.boolean().default(false),
			hasMarbleFurniture: Yup.boolean().default(false),
			hasPianoUpright: Yup.boolean().default(false),
			hasBabyGrandPiano: Yup.boolean().default(false),
			hasPoolTable: Yup.boolean().default(false),
			hasStove: Yup.boolean().default(false),
			hasTreadmill: Yup.boolean().default(false),
			other: Yup.boolean().default(false),
			heavyItemBetween300and450lbsQty: Yup.number().default(0),
			heavyItemBetween450and600lbsQty: Yup.number().default(0) ,
			heavyItemOver600lbsQty: Yup.number().default(0)
		})
});

export const timeValidationSchema: Yup.ObjectSchema<Omit<TimeSelectionFormModel, 'action'>> = Yup.object().shape({
	preferredStartTime: Yup.string().required('Please select a start time'),
});

const phoneRegex = /^(1\s*[-/.]?)?(\(([2-9]\d{2})\)|([2-9]\d{2}))\s*[-/.]?\s*(\d{3})\s*[-/.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT])\.?\s*(\d+))*$/;
//const lettersRegex = /^[a-zA-Z]+$/;
const nameRegex = /^[a-zA-Z]+[a-zA-Z, .]*$/;

export const contactValidationSchema: Yup.ObjectSchema<ContactModel> = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').matches(/@[^.]*\./, 'Please enter a valid email').required('Email is required').max(320, 'Email must be 320 characters or less'),
    //firstName: Yup.string().matches(lettersRegex, 'First Name can only contain letters').required('First Name is required').max(50, 'First Name must be 50 characters or less'),
    //lastName: Yup.string().matches(lettersRegex, 'Last Name can only contain letters').required('Last Name is required').max(50, 'Last Name must be 50 characters or less'),
    firstName: Yup.string().matches(nameRegex, 'First Name can only contain letters').required('First Name is required').max(50, 'First Name must be 50 characters or less'),
    lastName: Yup.string().matches(nameRegex, 'Last Name can only contain letters').required('Last Name is required').max(50, 'Last Name must be 50 characters or less'),
	phone: Yup.string().matches(phoneRegex, 'Please enter a valid phone number'),
});

export const saveQuoteValidationSchema: Yup.ObjectSchema<Omit<SaveQuoteFormModel, 'action'>> = Yup.object().shape({
    contact: contactValidationSchema,
});

// Phone is required for checkout - probably could make a base class here but not sure if it's worth it
export const checkoutContactValidationSchema: Yup.ObjectSchema<ContactModel> = Yup.object().shape({
	email: Yup.string().email('Please enter a valid email').matches(/@[^.]*\./, 'Please enter a valid email').required('Email is required').max(320, 'Email must be 320 characters or less'),
	firstName: Yup.string().required('First Name is required').max(50, 'First Name must be 50 characters or less'),
	lastName: Yup.string().required('Last Name is required').max(50, 'Last Name must be 50 characters or less'),
	phone: Yup.string().matches(phoneRegex, 'Please enter a valid phone number').required('Phone is required'),
});

const BraintreeFieldValueInvalid = '0';
type BraintreeFieldValueInvalid = '0';

const paymentInfoValidation: Yup.ObjectSchema<PaymentInfoModel> = Yup.object({
	billingZip: Yup.string()
		.matches(/^[0-9]+$/, 'Zip Code must be only digits')
		.min(5, 'Zip Code must be exactly 5 digits')
		.max(5, 'Zip Code must be exactly 5 digits')
		.when('$paymentMethod', {
            is: BraintreeClientPaymentMethod.CreditCard,
            then: schema => schema.required('Billing Zip Required'),
            otherwise: schema => schema.notRequired(),
        }),
	cardholderName: Yup.string().test('Cardholder Name', 'Cardholder Name Required', o => o != BraintreeFieldValueInvalid),
	number: Yup.string().test('Card Number', 'Card Number Invalid', o => o != BraintreeFieldValueInvalid),
	expirationDate: Yup.string().test('Exp Date', 'Exp Date Invalid', o => o != BraintreeFieldValueInvalid),
	cvv: Yup.string().test('CVV', 'CVV Invalid', o => o != BraintreeFieldValueInvalid),
});

export const checkoutValidationSchema: Yup.ObjectSchema<Omit<CheckoutFormModel, 'action'>> = Yup.object().shape({
	paymentInfo: paymentInfoValidation,
    contact: checkoutContactValidationSchema,
    paymentMethod: Yup.mixed<BraintreeClientPaymentMethod>().defined().oneOf(Object.values(BraintreeClientPaymentMethod).filter(e => e !== BraintreeClientPaymentMethod.Unknown).map((e) => e as BraintreeClientPaymentMethod), 'Please select a payment method'),
	agreedToTos: Yup.boolean().oneOf([true], 'Please agree to our Terms of Service before booking.').required(),
});

export const dateValidationSchema: Yup.ObjectSchema<Omit<DateFormModel, 'action'>> = Yup.object().shape({
    date: Yup.string().required('Please select a date'),
});

export const ldQuoteValidationSchema: Yup.ObjectSchema<Omit<LdFormModel, 'action'>> = Yup.object().shape({
	contact: checkoutContactValidationSchema,
	agreeToPrivacyPolicy: Yup.boolean().oneOf([true], 'Please agree to our Privacy policy by checking the box before submitting.').required(),
});

export const savePlaceValidationSchema: Yup.ObjectSchema<Omit<SavePlaceFormModel, 'action'>> = Yup.object().shape({
    contact: contactValidationSchema,
    agreeToPrivacyPolicy: Yup.boolean().oneOf([true], 'Please agree to our Privacy Policy to save your quote progress.').required(),
});
