import { useEffect, useRef } from 'react';


export function useEffectAsync(effect: (unloaded: () => boolean) => PromiseLike<void> | void, deps: React.DependencyList) {

    // store latest generator reference
    const effectRef = useRef(effect);
    effectRef.current = effect;
    useEffect(() => {

        let unloaded = false;

        effectRef.current(() => unloaded);

        return () => {
            unloaded = true;
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}


