import { HostedField } from 'react-braintree-fields';
import classNames from 'classnames';
import * as React from 'react';
import { Label } from '@lemonsqueezy/wedges';

type BraintreeFieldType = 'number' | 'expirationDate' | 'expirationMonth' | 'expirationYear' | 'cvv' | 'postalCode' | 'cardholderName';

// ** possible values:
// undefined or '' means field is empty
// '0' means field is set but is invalid
// '1' means field is valid */

const BraintreeFieldValueEmpty = '';
type BraintreeFieldValueEmpty = '';

const BraintreeFieldValueInvalid = '0';
type BraintreeFieldValueInvalid = '0';

const BraintreeFieldValueValid = '1';
type BraintreeFieldValueValid = '1';

type BraintreeFieldValue = BraintreeFieldValueEmpty | BraintreeFieldValueInvalid | BraintreeFieldValueValid;

interface HostedFieldWithLabelProps {
	label?: string;
	type: BraintreeFieldType;
	placeholder?: string;
	setRef: (field: HostedField | null) => void;
	value: BraintreeFieldValue | string | undefined;
	onChange: (value: BraintreeFieldValue | string) => void;
	onBlur?: () => void;
	name: string;
	error?: string;
	iconRight?: React.ReactNode;
	iconLeft?: React.ReactNode;
    className?: string;
    rounded?: Array<"topLeft" | "topRight" | "bottomLeft" | "bottomRight">;
    cols?: number;
    showError?: boolean;
}

const roundedClassMap: Record<string, string> = {
    topLeft: "!rounded-tl-lg",
    topRight: "!rounded-tr-lg",
    bottomLeft: "!rounded-bl-lg",
    bottomRight: "!rounded-br-lg",
};

export const HostedFieldWithLabel = ({
	type,
	placeholder,
	setRef,
	value,
	onChange,
	label,
	name,
	error,
	onBlur,
	iconLeft,
	iconRight,
    className,
    rounded = [],
    cols,
    showError = true,
}: HostedFieldWithLabelProps) => {
	const [hasFocus, setHasFocus] = React.useState<boolean>();

    const roundedClasses = rounded
        .map((key) => roundedClassMap[key] || "")
        .join(" ");

	//const invalidFeedback = transformErrorMessage(validationState?.errorMessage, label);
	const classes = classNames(className, roundedClasses,
		'flex grow rounded-lg border shadow-wg-xs transition-colors duration-100 placeholder:text-surface-500 outline-primary focus:outline focus:outline-2 bg-background text-surface-900 !rounded-none focus:-outline-offset-2 w-full px-3 py-2 text-sm h-[40px]',
		hasFocus && 'focused',
		value === BraintreeFieldValueEmpty ? 'empty' : 'non-empty',
		error
			? 'border-destructive outline-destructive hover:border-destructive dark:hover:border-destructive'
			: 'hover:border-surface-300 dark:hover:border-surface-200 border-surface-200 dark:border-surface-100'
	);
	const handleOnBlur = React.useCallback(() => {
		setHasFocus(false);
		onBlur?.();
	}, [onBlur]);


	return (
		<div className={`braintree-hosted relative col-span-${cols} ${roundedClasses}`}>
			{label && (
				<Label className='mb-2' htmlFor={name} onClick={() => () => setHasFocus(true)}>
					{label}
				</Label>
			)}
			{iconLeft && <span className='absolute top-2 left-2'>{iconLeft}</span>}

			<HostedField
				type={type}
				name={name}
				className={classes}
				onBlur={handleOnBlur}
				onFocus={() => setHasFocus(true)}
				onNotEmpty={() => onChange(BraintreeFieldValueInvalid)}
				onEmpty={() => onChange(BraintreeFieldValueEmpty)}
				placeholder={placeholder}
				ref={setRef}
				onValidityChange={(v: { isValid: unknown; isEmpty: unknown }) =>
					onChange(v.isValid ? BraintreeFieldValueValid : v.isEmpty ? BraintreeFieldValueEmpty : BraintreeFieldValueInvalid)
				}
			/>
			{iconRight && <span className='absolute top-2 right-2'>{iconRight}</span>}
			{error && showError && <div className='text-red-600 mb-2'>{error}</div>}
		</div>
	);
};
