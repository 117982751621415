import { PmgCheckoutWebApiUnprocessableEntityError } from "@generated/swaggerClient";
import { useCheckoutClientFactory, useCheckoutState } from "@hooks";
import { ActionButton, ActionLink, Flex, formatNumber, HahInput, Icon, iconLibrary, sentryWrapper, toastError } from "@shared";
import React from "react";
import { useState } from "react";

export const DiscountComponent = () => {
    const { model: { id, discountCode, discountAmount, selection }, setModel, setIsFetching, isFetching } = useCheckoutState();
    const [pendingDiscountCode, setPendingDiscountCode] = useState<string>('');
    const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = useState<string>('');
    const [showAddDiscountCode, setShowAddDiscountCode] = useState(false);
    const { wrapApiCall } = useCheckoutClientFactory();

    const subtotal = React.useMemo(() => formatNumber((selection?.grandTotal ?? 0) + (discountAmount ?? 0), { truncateZero: true }), [discountAmount, selection?.grandTotal]);
    const discountFormatted = React.useMemo(() => formatNumber((discountAmount ?? 0), { truncateZero: true }), [discountAmount]);
    const toastExceptionMessage = 'An unexpected error has occurred. We have notified our team and do apologize for any inconvenience. If this continues to happen please call us and we would be happy to assist you.';

    const logErrorToSentry = (error: Error) => {
        sentryWrapper.logException(error, 'error', 'Error applying or removing discount code', { component: 'DiscountComponent' }, { id, pendingDiscountCode });
    };

    const handleApplyDiscount = async () => {
        if (isFetching || !pendingDiscountCode) {
            // we're already submitting. let's not cause double submits...
            return;
        }

        setIsFetching(true);
        setDiscountCodeErrorMessage('');

        sentryWrapper.logBreadcrumb('Attempting to apply discount code', 'discount-code', 'info');

        try {
            const response = await wrapApiCall((client) => client.checkoutApplyDiscountCode(id, pendingDiscountCode));

            if (!response.success) {
                // unsuccesful results will be logged in the api
                sentryWrapper.logWarn('User attempted to apply an invalid discount code.', {}, {
                    pendingDiscountCode,
                    result: JSON.stringify(response),
                });

                const error = response.errorObj as PmgCheckoutWebApiUnprocessableEntityError;
                // If we have an existing/applied discount, we should show that still
                setDiscountCodeErrorMessage(error?.title ?? 'An error occurred applying the discount code.');
                return;
            }

            setPendingDiscountCode('');
            setModel({ discountCode: pendingDiscountCode, discountAmount: response.result!.discountAmount, selection: { ...selection, grandTotal: response.result!.grandTotal } });


            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            toastError({ title: toastExceptionMessage });

            logErrorToSentry(error);
        }
        finally {
            setIsFetching(false);
        }
    };

    const handleRemoveDiscount = async () => {
        if (isFetching) {
            // we're already submitting. let's not cause double submits...
            return;
        }

        setIsFetching(true);
        setDiscountCodeErrorMessage('');

        sentryWrapper.logBreadcrumb('Attempting to remove discount code', 'discount-code', 'info');

        try {
            const response = await wrapApiCall((client) => client.checkoutRemoveDiscountCode(id));

            if (!response.success) {
                sentryWrapper.logWarn('User failed to remove a discount code.', {}, {
                    pendingDiscountCode,
                    result: JSON.stringify(response),
                });
                // const error = response.errorObj as PmgCheckoutWebApiUnprocessableEntityError;
                toastError({ title: 'An error occurred while removing the discount code. Please try again.' });

                return;
            }
            setModel({ discountCode: undefined, discountAmount: 0, selection: { ...selection, grandTotal: response?.result?.grandTotal } });

        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any) {
            toastError({ title: toastExceptionMessage });

            logErrorToSentry(error);
        }
        finally {
            setIsFetching(false);
        }
    };

    return <>
        {!showAddDiscountCode && <div className="mt-3">
            <div>
                <ActionLink id='pw-addDiscountCodeBtn' onClickAction={() => setShowAddDiscountCode(true)}>Add Discount Code</ActionLink>
            </div>
        </div>}

        {showAddDiscountCode && <Flex className='mt-3'>
            <div className='flex-grow'>
                <HahInput
                    id="pw-pendingDiscountCode"
                    name="pendingDiscountCode"
                    className="form-control"
                    placeholder={`${discountCode && discountCode.length > 0 ? 'Discount Code Applied!' : 'Enter discount code'}`}
                    value={pendingDiscountCode}
                    onChange={(e) => {
                        setDiscountCodeErrorMessage('');
                        setPendingDiscountCode(e.target.value);
                    }}
                />
            </div>
            <div>
                <ActionButton className="ms-3" disabled={!pendingDiscountCode} onClickAction={handleApplyDiscount}>Apply Code</ActionButton>
            </div>
        </Flex>}
        {discountCode && discountCode.length > 0 && <Flex className="mt-2">
            <div>
                <Icon className="ms-2 me-1 text-green-700" icon={iconLibrary.faBolt} /> {discountCode}
            </div>
            <div className="text-end">
                <ActionLink onClickAction={handleRemoveDiscount}><Icon className="ms-4" icon={iconLibrary.faTimes} /></ActionLink>
            </div>
        </Flex>
        }
        {discountCodeErrorMessage.length > 0 && <div className="mt-2">
            <div className='text-red-600'>
                <Icon className="ms-2 me-1" icon={iconLibrary.faExclamationCircle} /> {discountCodeErrorMessage}
            </div>
        </div>
        }

        {discountCode && (discountAmount ?? 0) > 0 && <Flex className="mt-3">
            <div className="flex-grow">Subtotal</div>
            <div className="text-end">
                ${subtotal}
            </div>
        </Flex>}
        {discountCode && (discountAmount ?? 0) > 0 && <Flex className='mt-1'>
            <div className="flex-grow text-green-700">
                Discount
            </div>
            <div className="text-green-700 text-end">
                -${discountFormatted}
            </div>
        </Flex>}
    </>
};
