import { Footer, Headings, JobDateEdit } from '@components';
import { useCheckoutState } from '@hooks';
import { dateValidationSchema } from '@modules';
import { Card, ErrorFocus, Flex, formatDateWithOrdinal, iconLibrary } from '@shared';
import { DateFormModel } from '@types';
import { Urls } from '@utils';
import { Form, Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
type TModel = DateFormModel;

interface Props {
	onSubmitted: (action?: string) => void;
	onChange: (values: TModel, formikHelpers: FormikHelpers<TModel>) => Promise<boolean>;
	model?: TModel;
}
export const DateForm = ({ onSubmitted, onChange }: Props) => {
    const navigate = useNavigate();
	const { model } = useCheckoutState();
	const initialValues: TModel = {
        date: model.jobDate || ''
	};

	const handleSubmit = async (values: TModel, formikHelpers: FormikHelpers<TModel>) => {
		if (await onChange(values, formikHelpers)) {
            if (model.isLongDistance) {
                navigate(Urls.quote.longDistance);
            }
            else
            {
                onSubmitted(values.action);
            }
		}
	};

	return (
		<Formik
			initialValues={initialValues}
            validationSchema={dateValidationSchema}
			validateOnBlur={false}
			validateOnChange
			validateOnMount
            // FUTURE: We probably don't need enableReinitialize on all these forms, we should investigate and make sure this can be safely removed. The default is false.
			enableReinitialize
			onSubmit={handleSubmit}>
			{({ values }) => (
				<Form className='form-wrapper' noValidate>
					<Flex direction='col' className='w-[576px] px-4'>
						<ErrorFocus />
						<Headings primary='Your Move Day' />
						<Card
							className='w-1/2'
							title='My Selected Move Day'
							subtitle={formatDateWithOrdinal(values.date)}
							bgColor='bg-[#F3FCF1]'
							iconLeft={iconLibrary.faCalendar}
							titleTextColor='text-secondary-700'
						/>

                        <JobDateEdit value={values.date} isLongDistance={model.isLongDistance}  />
                        <Card className='mt-5 mb-0' centered={false} iconLeft={iconLibrary.faCircleInfo} title='On average, people in your area book their move 14 days in advance' bgColor='bg-primary-50' titleTextColor='text-primary-700' titleFontSize='text-sm text-start' />
					</Flex>
					<Footer continueButtonContent='See My Quote' />
				</Form>
			)}
		</Formik>
	);
};
