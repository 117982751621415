import { Client } from 'braintree-web';
import { create } from 'zustand';

export type BtClientState = BtClientStateModel;

export interface BtClientStateModel {
    btClientInstance?: Client;
    setBtClientInstance: (btClientInstance?: Client) => void;
}

export const useBtClientState = create<BtClientState>((set) => ({
    setBtClientInstance: (btClientInstance?: Client) => set({ btClientInstance }, false)
}));