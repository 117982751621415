import { iconLibrary, Icon, useEffectOnlyOnce } from '@shared';
import { Urls } from '@utils';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ldCheckoutPages = [
    { title: 'Locations', active: false, showInNav: true },
    { title: 'Items', additionalUrls: [], active: false, showInNav: true },
    { title: 'Date', active: false, showInNav: true },
    { title: 'Quote', mainUrl: Urls.quote.longDistance, active: false, showInNav: true },
    { title: 'Confirmation', mainUrl: Urls.quote.longDistanceConfirmation, active: false, showInNav: false },
];


export const LongDistanceProgress = () => {
    return (
        <div className='w-full h-[58px] pb-1 flex-col justify-start items-start inline-flex'>
            <ProgressBar />
        </div>
    );
};

const ProgressBar = () => {
	const { pathname } = useLocation();
    const pages = React.useMemo(() => ldCheckoutPages.map(page => ({...page, active: !!page.mainUrl && pathname == page.mainUrl})), [pathname]);

    const activeItemRef = useRef<HTMLDivElement>(null);
    const [paddingLeft, setPaddingLeft] = useState(0);

    const getProgressBarWidth = () => {
        if (!activeItemRef.current) {
            setPaddingLeft(100);
            return;
        };

        const rect = activeItemRef.current.getBoundingClientRect();
        const itemCenter = rect.left + rect.width / 2;
        const screenWidth = window.innerWidth;

        // Calculate padding-left as a percentage
        const paddingLeftPercentage = (itemCenter / screenWidth) * 100;
        setPaddingLeft(paddingLeftPercentage);
    };

    useEffectOnlyOnce(() => {
        getProgressBarWidth();
        window.addEventListener('resize', getProgressBarWidth);

        return () => {
            window.removeEventListener('resize', getProgressBarWidth);
        };
    });

    return (
        <>
            <div className='w-full h-[5px] py-px bg-[#e6e7e7] justify-start items-center inline-flex'>
                <div style={{ paddingLeft: `${paddingLeft}%` }} className='h-1 bg-primary-600 rounded-tr-[32px] rounded-br-[32px] shadow' />
            </div>
            <div className='self-stretch h-12 pt-2 flex-col justify-center items-center gap-1 flex'>
                <div className='w-[576px] max-w-full justify-start items-center gap-1 inline-flex'>
                    {pages.filter(x => x.showInNav).map((page, index) => (
                        <ProgressItem
                            ref={page.active ? activeItemRef : undefined}
                            key={index}
                            title={page.title}
                            active={page.active}
                            completed={!page.active}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

const ProgressItem = React.forwardRef<HTMLDivElement, { title: string; active: boolean; completed: boolean }>(
    ({ title, active, completed }, ref) => (
        <div
            ref={ref}
            className={`progress-item grow shrink basis-0 self-stretch rounded-md flex-col justify-center items-center gap-0.5 inline-flex whitespace-nowrap ${active || completed ? 'active text-primary-700' : 'text-neutralGrey-500'
                }`}>
            <div className='w-[18px] h-[18px] justify-center items-center gap-2.5 inline-flex'>
                {active ? <Icon icon={iconLibrary.faCircleHalfStroke} /> : completed ? <Icon icon={iconLibrary.faCircleCheck} /> : <Icon icon={iconLibrary.farCircle} />}
            </div>
            <div className='text-sm font-medium leading-[21px]'>
                <span>{title}</span>
            </div>
        </div>
    )
);