import { ProviderSortOrder } from "@generated/swaggerClient";

/**
 * This is what our "recommended" provider sort order is; and it should stay in sync with server side logic.
 */
export const RecommendedProviderSortOrder: ProviderSortOrder = ProviderSortOrder.BestMatch;

/**
 * The time taken for the save place modal to appear after inactivity.
 */
export const SavePlaceModalInactivityTime = 20000; // 20 seconds
