import React from 'react';
import { Client } from 'braintree-web';
import { usePayPalButton } from '@hooks';
import { Flex } from '@shared';

interface PayPalButtonProps {
    displayName: string;
    merchantAccount: string;
    clientInstance: Client;
    grandTotal: string;
    handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void;
}

export const PayPalButtons: React.FC<PayPalButtonProps> = ({
    displayName,
    merchantAccount,
    clientInstance,
    grandTotal,
    handlePaymentMethodApproved,
}) => {
    // Use the hook to handle button setup and logic
    usePayPalButton(displayName, merchantAccount, clientInstance, grandTotal, handlePaymentMethodApproved);

    return (
        <>
            {/* Containers for PayPal buttons */}
            <Flex className='w-[345px]' id="paypal-button" />
            {/* <div id="paypal-paylater-button" ref={payLaterButtonRef} className="mb-4" /> */}
        </>
    );
};