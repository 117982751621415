import { PreviousPageButton, NextPageButton, FeatureList } from '@components';
import { iconLibrary, Flex, Icon } from '@shared';
import { useFormikContext } from 'formik';
import { rootDomain } from '../pmgConfig';
import { useBrandInfo, useCheckoutNavigation, useCheckoutState } from '@hooks';
import { BrandType } from '@generated/swaggerClient';

interface FooterProps {
    continueButtonContent?: string;
    goBackToRoot?: boolean;
    showFooterContent?: boolean;
}
export const Footer = ({ continueButtonContent = 'Continue', showFooterContent = true, goBackToRoot }: FooterProps) => {
    return (
        <>
            {showFooterContent && <FooterContent />}
            <FooterNav continueButtonContent={continueButtonContent} goBackToRoot={goBackToRoot} />
        </>
    );
};

const FooterNav = (props: FooterProps) => {
    const formikContext = useFormikContext();
    const { onNavigate } = useCheckoutNavigation();
    const { isFetching } = useCheckoutState();

    const handleBack = () => {
        if (props.goBackToRoot) {
            window.location.href = rootDomain;
        }
        else {
            onNavigate('back');
        }
    };
    const handleSubmit = () => {
        const action = 'next';
        if (formikContext) {
            formikContext?.setFieldValue('action', action);
            formikContext?.submitForm();
        } else {
            onNavigate(action);
        }
    };

    return (
        <div className='z-10 px-4 w-full bg-neutralGrey-50 border-t border-neutralGrey-200 py-6 mt-6 md:sticky md:bottom-0'>
            <Flex direction='row' justify={'between'} align='center' className='w-[576px] h-full mx-auto'>
                <PreviousPageButton onClickAction={handleBack}>
                    <Icon size='xs' icon={iconLibrary.faArrowLeft} className='me-2' />
                    Back
                </PreviousPageButton>
                <NextPageButton disabled={isFetching || !formikContext.isValid || formikContext.isSubmitting} onClickAction={handleSubmit}>
                    {props.continueButtonContent}
                    <Icon size='xs' icon={iconLibrary.faArrowRight} className='ms-2' />
                </NextPageButton>
            </Flex>
        </div>
    );
};

export const FooterContent = () => {
    const brandInfo = useBrandInfo();
    return <>
        {brandInfo.brand !== BrandType.HireAHelper && <FeatureList /> }
        {/* <ChatBox /> */}
    </>
};
