import { useCheckoutClientFactory} from '@hooks';
import { toastError, useEffectOnlyOnceAsync } from '@shared';
import { IBrandInformationDto, BrandType } from "@generated/swaggerClient";
import { sentryWrapper } from "@shared";
import { debugLoggerInfo } from '@utils';
import { useState } from 'react';

export const useBrandInfo = () => {
    const initBrandInfo: IBrandInformationDto = {
        brand: BrandType.Unknown,
        abbrev: 'mp',
        companyName: 'Unknown',
        logoUrl: '',
        logo2xUrl: '',
        mobileLogoUrl: '',
        mobileLogo2xUrl: '',
    };

    const [brandInfo, setBrandInfo] = useState(initBrandInfo);
    const { wrapApiCall } = useCheckoutClientFactory();

    const getBrandInfo = async () => {
        debugLoggerInfo('BrandInfo: Fetching brand info');
        const sessionStorageBrandInfo = sessionStorage.getItem('brandInfoData');
        if (sessionStorageBrandInfo) {
            const data: IBrandInformationDto = JSON.parse(sessionStorageBrandInfo);
            setBrandInfo(brandInfo => ({
                ...brandInfo,
                ...data
            }))
        }
        else {
            const result = await wrapApiCall(client => client.brandInfoLoad());
            if (result.success) {
                const data = result.result!;
                sessionStorage.setItem('brandInfoData', JSON.stringify(data));
                setBrandInfo(prevBrandInfo => ({
                    ...prevBrandInfo,
                    ...data
                }));
                debugLoggerInfo('BrandInfo: success model: ', { data });
            } else {
                const errorMsg = result.errorObj ? JSON.stringify(result.errorObj) : "Error fetching brand info.";
                sentryWrapper.logFatal(errorMsg, { component: 'Layout' });
                toastError({ message: 'Something went wrong. Please try again. If problem persists please contact our support team!!' });
            }
        }
    };

    useEffectOnlyOnceAsync(async () => {
        await getBrandInfo();
    });

    return brandInfo;
};
