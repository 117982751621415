
/**
 * Converts a given phone number to E.164 format.
 *
 * E.164 format is an international phone number format that includes the country code
 * and removes any non-digit characters.
 *
 * @param phoneNumber - The phone number to be converted.
 * @returns The phone number in E.164 format.
 * @throws Will throw an error if the phone number is not in a valid format.
 */
export function toE164(phoneNumber: string): string {
    // Remove all non-digit characters
    const cleanedNumber = phoneNumber.replace(/[^0-9]/g, '');

    // Check if the cleaned number is 11 digits and starts with 1
    if (cleanedNumber.length === 11 && cleanedNumber.startsWith('1')) {
        return `+${cleanedNumber}`;
    }

    // If not 11 digits or doesn't start with 1, assume it's a 10-digit US number
    if (cleanedNumber.length === 10) {
        return `+1${cleanedNumber}`;
    }

    // If the number is not in a valid format, return an error
    throw new Error(`Invalid phone number format; could not convert to E.164: ${phoneNumber}`);
}
