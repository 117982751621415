import { useEffect, useState } from 'react';
import { APIProvider, Map, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { ProviderLocationMarkers } from '@generated/swaggerClient';
import { mapStyles, sentryWrapper } from '@shared';
import classNames from 'classnames';

const API_KEY = 'AIzaSyC2n6eSKdrDveD8xKUoPp011f_IAaIaXwE';

interface HahMapProps extends React.HTMLAttributes<HTMLDivElement> {
	showDirections: boolean;
	markers?: ProviderLocationMarkers[];
    loadAddress?: string;
    unloadAddress?: string;
}

// FUTURE: it sure seems like we're rendering this component and loading google maps, even if the element isn't visible. when we get to the quote page, we get a ton of map console warnings... either that or it's running this logic over and over...
export const HahMap = (props: HahMapProps) => {
	const { showDirections, markers, loadAddress, unloadAddress } = props;
	const [googleLoaded, setGoogleLoaded] = useState(false);

    // If we're trying to show the map with directions but we have no addresses, just don't show the map. This really should not happen.
    if (showDirections && (loadAddress === '' || unloadAddress === '')) {
        sentryWrapper.logWarn('User tried to show directions without fullAddress', { component: 'HahMap' }, {
                loadAddress,
                unloadAddress
            });
        return null;
    }

	const bounds = markers?.reduce((bounds, {latitude, longitude}) => ({
		west: Math.min(bounds.west, longitude),
		east: Math.max(bounds.east, longitude),
		north: Math.max(bounds.north, latitude),
		south: Math.min(bounds.south, latitude)
    }), {west: 180, east:-180, north:-90, south:90});

	return (
		<APIProvider region='US' libraries={['maps']} apiKey={API_KEY} onLoad={() => setGoogleLoaded(true)}>
			{googleLoaded && (
				<div className={classNames('h-48', props.className)}>
					<Map
                        // FUTURE: SG: we get a console warning if we dont have zoom/center set, or defaultZoom/defaultCenter SEE: <Map> component is missing configuration. You have to provide zoom and center (via the `zoom`/`defaultZoom` and `center`/`defaultCenter` props) or specify the region to show using `defaultBounds`. See https://visgl.github.io/react-google-maps/docs/api-reference/components/map#required
                        defaultBounds={bounds}
						// this seems to be working when we have map markers we need to set the boundaries, otherwise lets set the zoom level to 12
						zoom={(markers?.length ?? 0) > 1 || showDirections ? undefined : 12}
						//defaultCenter={{ lat: lat || defaultLat!, lng: lng || defaultLng! }}
						styles={mapStyles}
						gestureHandling='none'
						clickableIcons={false}
						disableDoubleClickZoom={true}
						zoomControl={false}
						fullscreenControl={false}
						disableDefaultUI={true}>
						{markers?.map((m, i) => (
							<Marker key={i} position={{ lat: m.latitude, lng: m.longitude }}>
								{/* <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} /> */}
							</Marker>
						))}
						{showDirections && loadAddress && unloadAddress && <Directions loadAddress={loadAddress} unloadAddress={unloadAddress} />}
					</Map>
				</div>
			)}
		</APIProvider>
	);
};
interface DirectionsProps {
	loadAddress: string;
	unloadAddress: string;
}

const Directions = ({ loadAddress, unloadAddress }: DirectionsProps) => {
	const map = useMap();
	const routesLibrary = useMapsLibrary('routes');
	const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
	const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
	// const [routes, setRoutes] = useState<google.maps.DirectionsRoute[]>([]);
	//const [routeIndex, setRouteIndex] = useState(0);
	//const selected = routes[routeIndex];
	//const leg = selected?.legs[0];

	// Initialize directions service and renderer
	useEffect(() => {
		if (!routesLibrary || !map) return;
		setDirectionsService(new routesLibrary.DirectionsService());
		setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, suppressInfoWindows: true }));
	}, [routesLibrary, map]);

	// Use directions service
	useEffect(() => {
		if (!directionsService || !directionsRenderer) return;

		directionsService
			.route({
				origin: loadAddress,
				destination: unloadAddress,
				travelMode: google.maps.TravelMode.DRIVING,
				//provideRouteAlternatives: true,
			})
			.then(response => {
				directionsRenderer.setDirections(response);
				//setRoutes(response.routes);
			});

		return () => directionsRenderer.setMap(null);
	}, [directionsService, directionsRenderer, loadAddress, unloadAddress]);

	// Update direction route
	// useEffect(() => {
	// 	if (!directionsRenderer) return;
	// 	directionsRenderer.setRouteIndex(routeIndex);
	// }, [routeIndex, directionsRenderer]);

	//if (!leg) return null;

	return (
		<div className='directions'>
			{/* <h2>{selected.summary}</h2>
			<p>
				{leg.start_address.split(',')[0]} to {leg.end_address.split(',')[0]}
			</p>
			<p>Distance: {leg.distance?.text}</p>
			<p>Duration: {leg.duration?.text}</p>

			<h2>Other Routes</h2>
			<ul>
				{routes.map((route, index) => (
					<li key={route.summary}>
						<button onClick={() => setRouteIndex(index)}>{route.summary}</button>
					</li>
				))}
			</ul> */}
		</div>
	);
};
