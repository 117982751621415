import { logExperimentVariant } from "@shared";
import { environmentName } from "../../pmgConfig";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface OptimizelyState {
    [key: string]: OptimizelyExperiment;
}
interface OptimizelyExperiment {
    audiences: any[];
    variation?: Variation;
    visitorRedirected: boolean;
    isActive: boolean;
    id: string;
    experimentName: string;
    isInExperimentHoldback: boolean;
}
interface Variation {
    id: string;
    name: string;
}

/**
 * Asks Optimizely for the variant the user is bucketed into for a given experiment, then logs it serverside to us.
 * @param optimizely The Optimizely global instance.
 * @param pageName The name of the page, taken from Optimizely's settings for the experinment.
 * @param experimentKey The experiment key from Optimizely.
 * @returns a Promise that resolves to a variant or a null string. If null, it means we had an error talking to our server and logging
 */
export async function bucketAndCheckForOptimizelyVariant(
    optimizely: any,
    pageName: string,
    experimentKey: string,
    mutuallyExclusiveExperiments?: string[]
): Promise<string> {
    const overrideKey = new URLSearchParams(window.location.search).get('vid');
    if (overrideKey) {
        return new Promise<string>(resolve => {
            console.info('🧪 bucketAndCheckForOptimizelyVariant returning overrideKey', { overrideKey })
            resolve(overrideKey);
        });
    }
    if (!optimizely || !optimizely.initialized) {
        // optimizely doesnt exist. ad blocked? not loaded on page? whatever, we cannot proceed.
        const variantToLog = `${experimentKey}-1`;
        console.error('🧪 bucketAndCheckForOptimizelyVariant optimizely is null, cannot proceed with bucketing. stamping -1 for the variant', {
            pageName,
            experimentKey,
        });
        return new Promise(resolve => {
            logExperimentVariant(experimentKey, variantToLog, variantID => {
                console.info('🧪 bucketAndCheckForOptimizelyVariant calling resolve1', { variantID });
                resolve(variantID);
            });
        });
    }

    // Get all the experiments and their current states.
    let state = optimizely.get('state').getExperimentStates() as OptimizelyState;

    if (mutuallyExclusiveExperiments && mutuallyExclusiveExperiments.length > 0 && Object.keys(state).length > 0) {
        const bucketedForMutuallyExclusiveTest = Object.keys(state).some(key => {
            const experiment = state[key];
            return mutuallyExclusiveExperiments.includes(experiment.id);
        });

        // if we're in a mutually exclusive test, we need to check if the user is in any other active experiments
        if (bucketedForMutuallyExclusiveTest) {
            // Don't push or bucket the user if they're in another active experiment
            return new Promise(resolve => {
                console.warn('🧪 bucketAndCheckForOptimizelyVariant user is in another active experiment; not bucketing', { state, experimentKey });
                logExperimentVariant(experimentKey, `${experimentKey}-2`, variantID => {
                    console.info('🧪 bucketAndCheckForOptimizelyVariant calling resolve2', { state, variantID });
                    resolve(variantID);
                });
            });
        }
    }

    // Proceed with page activation and bucketing
    optimizely.push({
        type: 'page',
        pageName: pageName,
    });

    // Get all the experiments and their current states. Do not set isActive to true as we want to get variants they are in if the page is not active.
    state = optimizely.get('state').getExperimentStates();
    const experimentState = state[experimentKey];
    const variation = experimentState?.variation;

    if (variation == null) {
        console.warn('🧪 bucketAndCheckForOptimizelyVariant variation came back null from optimizely. experiment probably not running', {
            experimentState,
            variation,
        });
    }

    return new Promise(resolve => {
        const variantToLog = variation?.id ?? `${experimentKey}-0`;
        console.info('🧪 bucketAndCheckForOptimizelyVariant result; logging to our server', {
            experimentKey,
            experimentState,
            rawVariation: variation,
            variantToLog,
        });
        logExperimentVariant(experimentKey, variantToLog, variantID => {
            console.info('🧪 bucketAndCheckForOptimizelyVariant calling resolve2', { experimentState, variation });
            resolve(variantID);
        });
    });
}
if (environmentName === 'Development' || environmentName === 'Staging') {
    // Expose the function for debugging purposes
    window.bucketAndCheckForOptimizelyVariant = bucketAndCheckForOptimizelyVariant;
}

/**
 * Asks Optimizely for the variant the user is bucketed into for a given experiment.
 * @param optimizely The Optimizely global instance.
 * @param pageName The name of the page, taken from Optimizely's settings for the experinment.
 * @param experimentKey The experiment key from Optimizely.
 * @returns a Promise that resolves to a variant or a null string. If null, it means we had an error talking to our server and logging
 */
export async function getCurrentOptimizelyVariantId(
    optimizely: any,
    pageName: string,
    experimentKey: string
): Promise<string> {
    const overrideKey = new URLSearchParams(window.location.search).get('vid');
    if (overrideKey) {
        return new Promise<string>(resolve => {
            console.info('🧪 getCurrentOptimizelyVariantId returning overrideKey', { overrideKey });
            resolve(overrideKey);
        });
    }
    if (!optimizely || !optimizely.initialized) {
        // optimizely doesnt exist. ad blocked? not loaded on page? whatever, we cannot proceed.
        console.error('🧪 getCurrentOptimizelyVariantId optimizely is null, returning no variant.', {
            pageName,
            experimentKey,
        });
        return new Promise<string>(resolve => {
            resolve('');
        });
    }

    // Get all the experiments and their current states. Do not set isActive to true as we want to get variants they are in if the page is not active.
    const state = optimizely.get('state').getExperimentStates();
    const experimentState = state[experimentKey];
    const variation = experimentState?.variation;

    if (variation == null) {
        console.warn('🧪 getCurrentVariantId variation came back null from optimizely. experiment probably not running', {
            experimentState,
            variation,
        });
    }

    return new Promise<string>(resolve => {
        const variant = variation?.id ?? `${experimentKey}-0`;
        console.info('🧪 getCurrentVariantId calling resolve2', { experimentState, variation });
        resolve(variant);
    });
}
