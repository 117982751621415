import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// scroll to top every time the pathname changes
export const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [location.pathname]);

    return null;
};