import { CheckboxGroup } from "@lemonsqueezy/wedges";
import { HahFormikCheckbox, HahFormikSelectField } from "@shared";
import { ErrorMessage, useFormikContext } from "formik";
import { useEffect } from "react";

const heavyItemsConfig = {

    hasSafe: {
        label: 'Gun Safe (Medium/Large)',
        tooltip:undefined
    },
    hasMarbleFurniture: {
        label: 'Marble Furniture (Oversized/Heavy)',
        tooltip:undefined
    },
    hasBabyGrandPiano: {
        label: 'Piano (Grand/Baby Grand)',
        tooltip: 'We transport both grand and baby grand pianos. Make sure to include the piano in your estimate, as special equipment and additional personnel may be needed.'
    },
    hasPianoUpright: {
        label: 'Piano (Upright)',
        tooltip: 'Their compact design makes them slightly easier to transport than grand pianos, but they\'re still heavy and delicate. Our movers will ensure proper handling.'
    },
    hasPoolTable: {
        label: 'Pool Table (Disassembled, NOT Slate)',
        tooltip: 'Slate pool tables weigh between 800 and 1,200 pounds, are very delicate and are not easily moved by most professional movers due to the equipment needed. We highly recommend hiring a Professional Pool Table Mover as the most secure way to move a pool table.'
    },
    hasStove: {
        label: 'Stove (Viking)',
        tooltip:undefined
    },
    other: {
        label: 'Other (Please describe below)',
        tooltip: 'Other heavy items like safes, pool tables, or large furniture require specialized handling.'
    },
};


export const HeavyItemsList = () => {
    const { values, setFieldValue } = useFormikContext<{ heavyItemOptions: Record<string, any> }>();

    const isOtherSelected = values?.heavyItemOptions?.other;
    const heavyItemsKeys = Object.keys(heavyItemsConfig);
    const oversizedHeavyItemsOptions = [{label:'Between 300 to 450lbs', value: 'heavyItemOptions.heavyItemBetween300and450lbsQty'}, {label: 'Between 450 to 600lbs', value: 'heavyItemOptions.heavyItemBetween450and600lbsQty'} , {label: 'Over 600lbs', value: 'heavyItemOptions.heavyItemOver600lbsQty'}]
    const heavyItemsQty = [...Array(6)].map((_, i) => ({
        label: (i).toString(),
        value: i,
    }));
    useEffect(() => {
        if (isOtherSelected === false) {
            setFieldValue('heavyItemOptions.heavyItemBetween300and450lbsQty', 0);
            setFieldValue('heavyItemOptions.heavyItemBetween450and600lbsQty', 0);
            setFieldValue('heavyItemOptions.heavyItemOver600lbsQty', 0);
        }
    }, [isOtherSelected, setFieldValue]);

    return (
        <div className='px-3 py-2 bg-white border-neutralGray-500 rounded-lg shadow dark:bg-gray-800 dark:border-gray-500 mb-4'>
            <CheckboxGroup>
                {heavyItemsKeys.map((key, index) => {
                    const itemConfig = heavyItemsConfig[key as keyof typeof heavyItemsConfig] || { label: key, tooltip: undefined };
                    return (
                        <HahFormikCheckbox
                            key={index}
                            label={itemConfig.label}
                            name={`heavyItemOptions.${key}`}
                            tooltip={itemConfig?.tooltip || ''}
                        />
                    );
                })}
                <ErrorMessage name='heavyItemOptions' component='div' className='text-red-600' />
            </CheckboxGroup>

            {isOtherSelected && (
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Please specify the type of heavy items you have:
                    </label>
                    <div className="flex flex-col md:flex-row md:justify-between gap-3 mt-2">
                        {oversizedHeavyItemsOptions.map((label, idx) => (
                        <div key={idx} className="flex items-center gap-2 w-full md:w-auto">
                            <div className="col-auto w-full">
                            <HahFormikSelectField
                                label={label.label}
                                name={label.value}
                                placeholder={"0"}
                                className='row'
                                options={heavyItemsQty}
                            />
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            )}

        </div>
    )

};
