export const Urls = {
	index: '/',
	locations: {
		index: '/locations',
		load: '/locations/load',
		unload: '/locations/unload',
	},
	items: '/items',
	date: '/date',
	quote: {
		index: '/quote',
		providers: '/quote/providers',
		longDistance: '/quote/long-distance',
		longDistanceConfirmation: '/quote/long-distance/confirmation',
	},
	time: '/time',
	checkout: '/checkout',
	confirmation: '/confirmation',
	continue: '/continue/:id',
	noProvidersFound: '/no-providers-found',

    debug: '/debug',
};
