import { HahFormikRadio, RadioBtn } from "@shared";
import classNames from "classnames";

interface RadioBtnGroupProps {
    className?: string;
    children: React.ReactElement<typeof RadioBtn>[] | React.ReactElement<typeof HahFormikRadio>[];
}

export const RadioBtnGroup = ({ children, className }: RadioBtnGroupProps) => {
    return (<div className={classNames(className, 'radioBtnGroup')}>{children}</div>);
};