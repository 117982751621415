import { useBrandInfo } from '@hooks';
import { Button } from '@lemonsqueezy/wedges';
import * as Dialog from '@radix-ui/react-dialog';
import { iconLibrary, Icon, ActionButton } from '@shared';
import React from 'react';
import { useRef } from 'react';

type PointerDownOutsideEvent = CustomEvent<{
    originalEvent: PointerEvent;
}>;
type FocusOutsideEvent = CustomEvent<{
    originalEvent: FocusEvent;
}>;

interface ModalProps {
    children: React.ReactNode;
    triggerText?: string;
    title: string;
    open?: boolean;
    setOpen?: (open: boolean) => void;
    confirmContent?: string | React.ReactNode;
    onConfirm?: () => void;
    confirmDisabled?: boolean;
    triggerButton?: React.ReactNode;
    confirmButton?: React.ReactNode;
    headerElement?: React.ReactNode;
    centeredTitle?: boolean;
    preventCloseOnOutsideClick?: boolean;
}

/**
 * Modal component that displays a modal dialog with customizable content and actions.
 *
 * @param {React.ReactNode} props.children - The content to be displayed inside the modal.
 * @param {string} props.triggerText - The text for the button that triggers the modal.
 * @param {string} props.title - The title of the modal.
 * @param {boolean} props.open - A boolean indicating whether the modal is open or not.
 * @param {function} props.setOpen - Function to set the open state of the modal.
 * @param {string} props.confirmContent - The text for the confirm button inside the modal.
 * @param {function} props.onConfirm - Function to be called when the confirm button is clicked. If this is not provided, the confirm button will not be rendered.
 * @param {boolean} props.confirmDisabled - A boolean indicating whether the confirm button is disabled.
 * @param {React.ReactNode} props.triggerButton - Custom button element to trigger the modal.
 * @param {React.ReactNode} props.confirmButton - Custom button element to confirm the action.
 * @param {React.ReactNode} props.headerElement - Custom element to be displayed in the header of the modal.
 * @param {boolean} props.centeredTitle - A boolean indicating whether the title should be centered.
 * @param {boolean} props.preventCloseOnOutsideClick - A boolean indicating whether clicking outside the modal should close it.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
export const Modal = ({ children, triggerText, title, open, setOpen, confirmContent, onConfirm, confirmDisabled, triggerButton, confirmButton, headerElement, centeredTitle, preventCloseOnOutsideClick }: ModalProps) => {
    const wrapper = useRef<HTMLDivElement>(null);

    return (
        <div ref={wrapper}>
            <Dialog.Root open={open} onOpenChange={setOpen}>
                <Dialog.Trigger asChild>
                    {triggerButton && React.isValidElement(triggerButton) ? (
                        <div>{triggerButton}</div>
                    ) : (
                        <Button variant='link' shape='pill' className='text-primary-600 text-base focus-visible:outline-2'>
                            {triggerText}
                        </Button>
                    )}
                </Dialog.Trigger>
                <ModalContent title={title} container={wrapper.current} confirmContent={confirmContent} onConfirm={onConfirm}
                    confirmDisabled={confirmDisabled} confirmButton={confirmButton} headerElement={headerElement} centeredTitle={centeredTitle}
                    onPointerDownOutside={preventCloseOnOutsideClick ? (event: PointerDownOutsideEvent) => event.preventDefault() : undefined}
                    onInteractOutside={preventCloseOnOutsideClick ? (event: PointerDownOutsideEvent | FocusOutsideEvent) => event.preventDefault() : undefined}
                >
                    {children}
                </ModalContent>
            </Dialog.Root>
        </div>
    );
};

interface ModalContentProps {
    children: React.ReactNode;
    title: string;
    container: HTMLDivElement | null;
    confirmContent?: string | React.ReactNode;
    onConfirm?: () => void;
    confirmButton?: React.ReactNode;
    confirmDisabled?: boolean;
    headerElement?: React.ReactNode;
    centeredTitle?: boolean;
    onPointerDownOutside?: (event: PointerDownOutsideEvent) => void;
    onInteractOutside?: (event: PointerDownOutsideEvent | FocusOutsideEvent) => void;
}

export const ModalContent = ({ children, title, container, onConfirm, confirmContent = 'Save Changes', confirmDisabled, confirmButton, headerElement, centeredTitle, onPointerDownOutside, onInteractOutside }: ModalContentProps) => {
    const { abbrev } = useBrandInfo();
    const ConfirmButtonWrapper = ({ element, onClick }: { element: React.ReactElement; onClick: () => void }) => {
        return React.cloneElement(element, { onClick });
    };
    return (
        <Dialog.Portal container={container}>
            <Dialog.Overlay className='DialogOverlay bg-neutralGrey-600 bg-opacity-50 data-[state=open]:animate-overlayShow fixed top-0 left-0 right-0 bottom-0 inset-0 z-40 overflow-y-auto justify-center content-between grid pb-10'>
                <Dialog.Content
                    className={`DialogContent ${abbrev} max-w-[90vw] w-[526px] rounded-3xl bg-white lg:px-6 p-4 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none z-50 relative mt-16`}
                    onPointerDownOutside={onPointerDownOutside} onInteractOutside={onInteractOutside}>
                    {React.isValidElement(headerElement) && headerElement}
                    <Dialog.Title className={`${centeredTitle ? 'text-center' : ''} text-neutralGrey-800 text-2xl font-semibold leading-9 mb-2`}>{title}</Dialog.Title>
                    <Dialog.Description className='hidden'>{title}</Dialog.Description>
                    {children}
                    {onConfirm && (
                        <div className='mt-4'>
                            {confirmButton && React.isValidElement(confirmButton) ? (
                                <ConfirmButtonWrapper element={confirmButton as React.ReactElement} onClick={onConfirm} />
                            ) : (
                                <ActionButton variant='primary' shape='pill' className='w-full' disabled={confirmDisabled} onClickAction={onConfirm}>
                                    {confirmContent}
                                </ActionButton>
                            )}
                        </div>
                    )}
                    <Dialog.Close asChild>
                        <Button
                            variant='transparent'
                            className='absolute top-[15px] right-[15px] inline-flex appearance-none items-center justify-center rounded-full'
                            aria-label='Close'>
                            <Icon className='text-black opacity-50' size='lg' icon={iconLibrary.falTimes} />
                        </Button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Overlay>
        </Dialog.Portal>
    );
};
export default Modal;
