// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import Api from './api';
import BraintreeContext from './context'
import { sentryWrapper } from '@shared';

export default class Braintree extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        onAuthorizationSuccess: PropTypes.func,
        authorization: PropTypes.string,
        getTokenRef: PropTypes.func,
        onValidityChange: PropTypes.func,
        onCardTypeChange: PropTypes.func,
        onError: PropTypes.func,
        styles: PropTypes.object,
        className: PropTypes.string,
        tagName: PropTypes.string,
        onClientInstanceReady: PropTypes.func,
    };

    static defaultProps = {
        tagName: 'div',
    };

    constructor(props) {
        super(props);
        this.api = new Api(props);
        this.state = { clientInstance: null };

        // Updated context value
        this.contextValue = {
            braintreeApi: this.api,
            clientInstance: this.state.clientInstance,
        };
    }

    async componentDidMount() {
        try {
            const clientInstance = await this.api.setAuthorization(
                this.props.authorization,
                this.props.onAuthorizationSuccess
            );

            this.setState({ clientInstance });

            if (this.props.onClientInstanceReady) {
                this.props.onClientInstanceReady(clientInstance);
            }

            if (this.props.getTokenRef) {
                this.props.getTokenRef(this.api.tokenize.bind(this.api));
            }
        } catch (error) {
            sentryWrapper.logException(error, 'fatal', 'ReactBraintreeFields.componentDidMount threw an Error', { component: 'ReactBraintreeFields' });
        }
    }

    componentWillUnmount() {
        this.api.teardown();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authorization !== this.props.authorization) {
            this.api.setAuthorization(this.props.authorization, this.props.onAuthorizationSuccess);
        }
    }

    tokenize(options) {
        return this.api.tokenize(options);
    }

    render() {
        const { className: providedClass, tagName: Tag } = this.props;
        let className = 'braintree-hosted-fields-wrapper';
        if (providedClass) {
            className += ` ${providedClass}`;
        }

        const contextValue = {
            ...this.contextValue,
            clientInstance: this.state.clientInstance,
        };

        return (
            <BraintreeContext.Provider value={contextValue}>
                <Tag className={className}>{this.props.children}</Tag>
            </BraintreeContext.Provider>
        );
    }
}
