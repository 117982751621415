import { Headings, NextPageButton, ProviderList } from '@components';
import { useCheckoutState, useProviderListState } from '@hooks';
import { ActionButton, Flex, Icon, iconLibrary, SpinnerNew } from '@shared';
import { Urls } from '@utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ProviderListPage = () => {
    const navigate = useNavigate();
    const { model, selectProvider } = useCheckoutState();
    const { selectedProviderLocationID, setSelectedProviderLocationID, selectedProviderSortOrder } = useProviderListState();
    const handleBack = () => {
        setSelectedProviderLocationID(undefined);
        navigate(Urls.quote.index);
    };
    const [loading, setShowLoading] = useState(false);
    const handleSubmit = () => {
        setShowLoading(true);
        // Add a timeout to show the spinner for a specified duration
        setTimeout(() => {
            setShowLoading(false);
            selectProvider({
                providerLocationID: selectedProviderLocationID ?? model.selection.providerLocationID!,
                providerSortOrder: selectedProviderSortOrder,
            });
            setSelectedProviderLocationID(undefined);
            navigate(Urls.quote.index);
        }, 2000);
    };
    return (
        <>
            {loading && <SpinnerNew fixed text='Updating Your Provider' />}
            <Flex grow={1} direction='col' className='w-[576px] px-4'>
                <Headings primary={'View other moving providers'} secondary={'View and select a new mover or keep your recommendation.'} />
                <ProviderList />
            </Flex>
            <div className='z-10 px-4 w-full bg-neutralGrey-50 border-t border-neutralGrey-200 py-6 mt-6 md:sticky md:bottom-0'>
                <Flex direction='row' justify={'between'} align='center' className='w-[576px] h-full mx-auto'>
                    <ActionButton variant='transparent' className='text-neutralGrey-600 text-xl font-medium' onClickAction={handleBack} args='back'>
                        <Icon size='xs' icon={iconLibrary.faArrowLeft} className='me-2' />
                        Back
                    </ActionButton>
                    <NextPageButton disabled={false} onClickAction={handleSubmit}>
                        Update
                        <Icon size='xs' icon={iconLibrary.faArrowRight} className='ms-2' />
                    </NextPageButton>
                </Flex>
            </div>
        </>
    );
};
