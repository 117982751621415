import numeral from 'numeral';

export function formatCurrency(amount: number, currency: string = 'USD'): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
}

export function formatNumber(value: number | undefined): string;
// eslint-disable-next-line:unified-signatures
export function formatNumber(value: number | undefined, options: { truncateZero: boolean }): string;
export function formatNumber(value: number | undefined, options?: { truncateZero: boolean }) {

    if (typeof value === 'undefined') {
        return undefined;
    }
    //const v = evenRound(value, 2);
    return numeral(value).format(options?.truncateZero && value == Math.round(value) ? '0,0' : '0,0.00');
}