import { DataLayerEvent } from '@types';
import { performDataLayerLog, useEffectOnlyOnce } from '@shared';

/**
 * Hook to interact with the Google Tag Manager/GA4 dataLayer.
 * Uses useEffectOnlyOnce to prevent the event from being logged multiple times when we're in dev mode.
 * @param event The event to log to the dataLayer.
 * @param immediately If true, the event will be logged immediately. Otherwise, it will be delayed by 1 second. We do this in case we need to acknowledge a page load event first.
 */
export const useDataLayerLogger = (event: DataLayerEvent, immediately: boolean, performEcommerceClear: boolean = false) => {
    useEffectOnlyOnce(() => {
        performDataLayerLog(event, immediately, performEcommerceClear);
    });
};