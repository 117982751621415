import { BrandPhoneNumber, FeatureList, Headings } from '@components';
import { ReasonNoRecommendedProviderValue } from '@generated/swaggerClient';
import { useCheckoutState, useDataLayerLogger } from '@hooks';
import { ActionButton, Flex, Icon, iconLibrary, performDataLayerLog } from '@shared';
import { Urls } from '@utils';
import { useNavigate } from 'react-router-dom';

export const NoProvidersFound = () => {
    const { model, lastGetProvidersResult } = useCheckoutState();


    let reasonNoRecommendedProvider: string | undefined;

    if (lastGetProvidersResult?.reasonNoRecommendedProvider != null) {
        reasonNoRecommendedProvider = ReasonNoRecommendedProviderValue[lastGetProvidersResult!.reasonNoRecommendedProvider!];
    }

    useDataLayerLogger({
        event: 'no_msp_available',
        is_long_distance: model.isLongDistance,
        reasonNoRecommendedProvider,
        reasonNoRecommendedProviderRaw: lastGetProvidersResult?.reasonNoRecommendedProviderRaw,
    }, false);

    if (lastGetProvidersResult?.reasonNoRecommendedProvider == null) {
        // Render default component if reason is null or undefined
        return <DefaultNoProviders />;
    }

    // Get the component from the mapping or use the default fallback
    const ComponentToRender = componentMap[lastGetProvidersResult!.reasonNoRecommendedProvider!] ?? DefaultNoProviders;

    return <ComponentToRender />;
};


interface GoBackLinkProps {
    urlTo: string;
    text?: string;
}
const GoBackLink = (props: GoBackLinkProps) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        performDataLayerLog({ event: 'overcame_no_msp_available' }, true);
        navigate(props.urlTo);
    };

    return <ActionButton variant='outline' shape='pill' onClickAction={handleGoBack} args='next' className={'w-[345px] text-base self-center mb-12 border-primary-600 text-primary-600 hover:bg-primary-600 hover:text-white'}>
        <Icon size='xs' icon={iconLibrary.faArrowLeft} className='me-2' />
        {props.text ?? 'Go Back'}
    </ActionButton>;
}
const DoesNotOfferThatNumberOfCrew = () => (
    <Flex grow={1} direction="col" className="w-[576px] px-4">
        <Headings
            primary="No Providers Available"
            secondary={
                <>Based on the size of your move, we were unable to find a provider online, but not to worry, our moving specialists may still be able to help. Please give us a call at <BrandPhoneNumber showIcon={false} /> to discuss your options.</>
            }
            imgSrc={"/img/Search.svg"}
            imgAlt="Magnifying glass icon"
            hideImgOnSm={false}
        />
        <FeatureList />
    </Flex>
);

const DoesNotMovePiano = () => (
    <Flex grow={1} direction='col' className='w-[576px] px-4'>
        <Headings
            primary='We were unable to find a moving provider with experience needed to move your piano.'
            secondary={
                <>
                    To proceed with your quote, please go back and remove the piano from your Oversized or Items Needing Special Attention list and re-submit to
                    see your quote. We recommend finding a professional piano mover with the training necessary to move your piano safely.
                    <br /><br />If you would like to speak with a moving specialist, please call <BrandPhoneNumber showIcon={false} />.
                </>
            }
            imgSrc={'/img/Tools.svg'}
            imgAlt='Wrench and Screwdriver icon'
            hideImgOnSm={false}
        />
        <GoBackLink text='Update Oversize Items' urlTo={Urls.items} />
        <FeatureList />
    </Flex>
);

const DoesNotMoveHeavyItem = (weightRange: string) => (
    <Flex grow={1} direction='col' className='w-[576px] px-4'>
        <Headings
            primary={`We were unable to find a moving provider with the crew necessary to move your oversized items over ${weightRange}.`}
            secondary={
                <>
                    To proceed with your quote, please go back and remove any oversized items from your Oversized or Items Needing Special Attention list and re-submit to see your quote.
                    <br /><br />If you would like to speak with a moving specialist, please call <BrandPhoneNumber showIcon={false} />.
                </>
            }
            imgSrc={'/img/Tools.svg'}
            imgAlt='Wrench and Screwdriver icon'
            hideImgOnSm={false}
        />
        <GoBackLink text='Update Oversize Items' urlTo={Urls.items} />
        <FeatureList />
    </Flex>
);

// Default fallback component
const DefaultNoProviders = () => (
    <Flex grow={1} direction="col" className="w-[576px] px-4">
        <Headings
            primary="No Providers Found"
            secondary="No moving providers were found in your area. Please give us a call so that we can review your options with a moving specialist."
            imgSrc={"/img/Search.svg"}
            imgAlt="Magnifying glass icon"
            hideImgOnSm={false}
        />
        <FeatureList />
    </Flex>
);

// Component mapping
const componentMap: Record<string, React.FC> = {
    [ReasonNoRecommendedProviderValue.UnspecifiedReason]: DefaultNoProviders,
    [ReasonNoRecommendedProviderValue.NoOneCanService]: DefaultNoProviders,
    [ReasonNoRecommendedProviderValue.DoesNotMoveUprightPiano]: DoesNotMovePiano,
    [ReasonNoRecommendedProviderValue.DoesNotMoveUprightPianoWithStairs]: DoesNotMovePiano,
    [ReasonNoRecommendedProviderValue.DoesNotMoveBabyGrandPiano]: DoesNotMovePiano,
    [ReasonNoRecommendedProviderValue.DoesNotMoveBabyGrandPianoWithStairs]: DoesNotMovePiano,
    [ReasonNoRecommendedProviderValue.DoesNotMoveItems300to450]: () => DoesNotMoveHeavyItem('300 pounds'),
    [ReasonNoRecommendedProviderValue.DoesNotMoveItems300to450WithStairs]: () => DoesNotMoveHeavyItem('300 pounds'),
    [ReasonNoRecommendedProviderValue.DoesNotMoveItems450to600]: () => DoesNotMoveHeavyItem('450 pounds'),
    [ReasonNoRecommendedProviderValue.DoesNotMoveItems450to600WithStairs]: () => DoesNotMoveHeavyItem('450 pounds'),
    [ReasonNoRecommendedProviderValue.DoesNotMoveItems600Plus]: () => DoesNotMoveHeavyItem('600 pounds'),
    [ReasonNoRecommendedProviderValue.DoesNotMoveItems600PlusWithStairs]: () => DoesNotMoveHeavyItem('600 pounds'),
    [ReasonNoRecommendedProviderValue.DoesNotOfferThatNumberOfCrew]: DoesNotOfferThatNumberOfCrew,
    [ReasonNoRecommendedProviderValue.DoesNotOfferTruckWithThatNumberOfCrew]: DoesNotOfferThatNumberOfCrew,
};
