// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Braintree from 'braintree-web/client';
import HostedFields from 'braintree-web/hosted-fields';
import BraintreeDataCollector from 'braintree-web/data-collector';
import BraintreeThreeDSecure from 'braintree-web/three-d-secure';
function cap(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class BraintreeClientApi {
	fields = Object.create(null);

	_nextFieldId = 0;

	fieldHandlers = Object.create(null);

	client = null; // Store the client instance

	constructor({ authorization, styles, onAuthorizationSuccess, ...callbacks }) {
		this.styles = styles || {};
		this.wrapperHandlers = callbacks || {};
		this.setAuthorization(authorization, onAuthorizationSuccess);
	}

	setAuthorization(authorization, onAuthorizationSuccess) {
		return new Promise((resolve, reject) => {
			if (!authorization && this.authorization) {
				this.teardown();
				return resolve(null); // No authorization, resolve with null
			} else if (authorization && authorization !== this.authorization) {
				if (this.authorization) this.teardown();

				// Delay setting authorization if no fields have checked in
				if (Object.keys(this.fields).length === 0 && !this.pendingAuthTimer) {
					this.pendingAuthTimer = setTimeout(() => {
						this.pendingAuthTimer = null;
						this.setAuthorization(authorization, onAuthorizationSuccess).then(resolve).catch(reject);
					}, 5);
					return;
				}

				this.authorization = authorization;

				// Use Braintree.create with a Promise wrapper
				Braintree.create({ authorization }, (err, clientInstance) => {
					if (err) {
						this.onError(err);
						return reject(err); // Reject on error
					}

					this.create(clientInstance, onAuthorizationSuccess);
					this.client = clientInstance; // Store the client instance

					if (this.wrapperHandlers.onThreeDSecureReady) {
						BraintreeThreeDSecure.create({ client: clientInstance, version: 2 }, this.wrapperHandlers.onThreeDSecureReady);
					}

					if (this.wrapperHandlers.onDataCollectorInstanceReady) {
						BraintreeDataCollector.create({ client: clientInstance, kount: false, paypal: true }, this.wrapperHandlers.onDataCollectorInstanceReady);
					}

					resolve(clientInstance); // Resolve with the client instance
				});
			}
		});
	}

	nextFieldId() {
		this._nextFieldId += 1;
		return this._nextFieldId;
	}

	onError(err) {
		if (!err) {
			return;
		}
		if (this.wrapperHandlers.onError) {
			this.wrapperHandlers.onError(err);
		}
	}

	create(client, onAuthorizationSuccess) {
		this.client = client;
		HostedFields.create(
			{
				client,
				styles: this.styles,
				fields: this.fields,
			},
			(err, hostedFields) => {
				if (err) {
					this.onError(err);
					return;
				}
				this.hostedFields = hostedFields;
				['blur', 'focus', 'empty', 'notEmpty', 'cardTypeChange', 'validityChange'].forEach(eventName => {
					hostedFields.on(eventName, ev => this.onFieldEvent(`on${cap(eventName)}`, ev));
				});
				this.onError(err);

				if (onAuthorizationSuccess) {
					onAuthorizationSuccess();
				}
			}
		);
	}

	teardown() {
		if (this.hostedFields) {
			this.hostedFields.teardown();
		}
		if (this.pendingAuthTimer) {
			clearTimeout(this.pendingAuthTimer);
			this.pendingAuthTimer = null;
		}
	}

	checkInField({
		formatInput,
		maxlength,
		minlength,
		placeholder,
		select,
		type,
		prefill,
		rejectUnsupportedCards,
		id = `braintree-field-wrapper-${this.nextFieldId()}`,
		options = {},
		...handlers
	}) {
		const onRenderComplete = () => {
			this.fieldHandlers[type] = handlers;
			this.fields[type] = {
				formatInput,
				maxlength,
				minlength,
				placeholder,
				select,
				prefill,
				selector: `#${id}`,
				...options,
			};
			if ('number' === type && rejectUnsupportedCards) {
				this.fields.number.rejectUnsupportedCards = true;
			}
		};
		return [id, onRenderComplete];
	}

	focusField(fieldType, cb) {
		this.hostedFields.focus(fieldType, cb);
	}

	clearField(fieldType, cb) {
		this.hostedFields.clear(fieldType, cb);
	}

	setAttribute(fieldType, name, value) {
		this.hostedFields.setAttribute({
			field: fieldType,
			attribute: name,
			value,
		});
	}

	onFieldEvent(eventName, event) {
		const fieldHandlers = this.fieldHandlers[event.emittedBy];
		if (fieldHandlers && fieldHandlers[eventName]) {
			fieldHandlers[eventName](event.fields[event.emittedBy], event);
		}
		if (this.wrapperHandlers[eventName]) {
			this.wrapperHandlers[eventName](event);
		}
	}

	tokenize(options = {}) {
		return new Promise((resolve, reject) => {
			this.hostedFields.tokenize(options, (err, payload) => {
				if (err) {
					this.onError(err);
					reject(err);
				} else {
					resolve(payload);
				}
			});
		});
	}
}
