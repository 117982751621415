import { BraintreeClientPaymentMethod } from "@generated/swaggerClient";

export const getPaymentMethodLabel = (paymentMethod: BraintreeClientPaymentMethod) => {
    switch (paymentMethod) {
        case BraintreeClientPaymentMethod.AndroidPayCard:
            return 'Google Pay';
        case BraintreeClientPaymentMethod.PayPal:
            return 'PayPal';
        default:
            return paymentMethod.replace(/([A-Z])/g, ' $1').trim();
    }
};

const getPaymentMethodButtonText = (paymentMethod: BraintreeClientPaymentMethod) => {
    switch (paymentMethod) {
        case BraintreeClientPaymentMethod.AndroidPayCard:
            return 'Book with Google Pay';
        case BraintreeClientPaymentMethod.ApplePay:
            return 'Book with Apple Pay';
        case BraintreeClientPaymentMethod.PayPal:
            return 'Pay with PayPal';
        // This isn't used in the UI, but it's here for completeness
        case BraintreeClientPaymentMethod.CreditCard:
        default:
            return 'Book Now with Credit Card';
    }
};

export const allValidPaymentMethods = Object.values(BraintreeClientPaymentMethod).filter(e => e !== BraintreeClientPaymentMethod.Unknown).map((value) => {
    return {
        value,
        // AndroidPayCard should be labeled as "Google Pay" in the UI
        // PayPal should remain as "PayPal" in the UI
        label: getPaymentMethodLabel(value), // Add spaces before each capital letter
        buttonText: getPaymentMethodButtonText(value),
    };
});
export const getValidPaymentMethodLabel = (paymentMethod: BraintreeClientPaymentMethod) => {
    return allValidPaymentMethods.find(e => e.value === paymentMethod)?.label;
};