import { useCheckoutState } from "@hooks";
import { CheckoutPage } from "@types";
import { isLongDistanceUrl, Urls } from "@utils";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const checkoutPages: CheckoutPage[] = [
    { title: 'Locations', mainUrls: [Urls.locations.index, Urls.locations.load, Urls.locations.unload], additionalUrls: [], active: false, step: 0 },
    { title: 'Items', mainUrls: [Urls.items], additionalUrls: [], active: false, step: 1 },
    { title: 'Date', mainUrls: [Urls.date], additionalUrls: [], active: false, step: 2 },
    // { title: 'Move Plan', urls: [Urls.quote], active: false },
    { title: 'Quote', mainUrls: [Urls.quote.index], additionalUrls: [Urls.quote.providers, Urls.noProvidersFound], active: false, step: 3 },
    { title: 'Time', mainUrls: [Urls.time], additionalUrls: [], active: false, step: 4 },
    { title: 'Checkout', mainUrls: [Urls.checkout], additionalUrls: [], active: false, step: 5 }
];

// For now we can skip some steps here but product says we may need to add them back in the future
const ldCheckoutPages: CheckoutPage[] = [
    { title: 'Locations', mainUrls: [Urls.locations.index, Urls.locations.load, Urls.locations.unload], additionalUrls: [], active: false, step: 0 },
    { title: 'Items', mainUrls: [Urls.items], additionalUrls: [], active: false, step: 1 },
    { title: 'Date', mainUrls: [Urls.date], additionalUrls: [], active: false, step: 2 },
    { title: 'Quote', mainUrls: [Urls.quote.longDistance], additionalUrls: [Urls.quote.longDistanceConfirmation], active: false, step: 3 },
    { title: 'Confirmation', mainUrls: [Urls.quote.longDistanceConfirmation], additionalUrls: [], active: false, step: 4 },
];

/**
 *
 * @param url The current url. This is used to find and set the active page
 * @returns The list of all pages, the active page, the previous url, and the next url
 */
export const useCheckoutPages = () => {
    const { pathname: currentUrl } = useLocation();
    const { model: { fastMode } } = useCheckoutState();

    const pages: CheckoutPage[] = (isLongDistanceUrl(currentUrl) ? ldCheckoutPages : checkoutPages).map(page => ({
        ...page,
        active: (page.mainUrls.some(u => currentUrl.startsWith(u)) || page.additionalUrls.some(u => currentUrl.startsWith(u))),
    }));
    const activePage = pages.find(page => page.active);
    const urls = pages.flatMap(page => page.mainUrls);
    const activeIndex = urls.findIndex(u => currentUrl === u);
    const previousUrl = activeIndex > 0 ? urls[activeIndex - 1] : undefined;

    const nextUrl = useMemo(() => {
        const standardNextUrl = activeIndex < (urls.length - 1) ? urls[activeIndex + 1] : undefined;

        const getNextUrlForFastMode = (): string | undefined => {
            // If current URL corresponds to the "load" step for locations, skip to the "items" page.
            if (currentUrl.startsWith(Urls.locations.load)) {
                return Urls.items;
            }

            // If current URL corresponds to the "items" page, skip to the "quote" page.
            if (currentUrl.startsWith(Urls.items)) {
                return Urls.quote.index;
            }

            return standardNextUrl;
        };

        return fastMode ? getNextUrlForFastMode() : standardNextUrl;
    }, [activeIndex, urls, currentUrl, fastMode]);

    return { pages, activePage, previousUrl, nextUrl };
}
