import React from 'react';
import { iconLibrary, Flex, Icon } from '@shared';

interface RadioBtnProps {
    name: string;
    value: string;
    label: string;
    checked?: boolean;
    onChange: (value: string) => void;
    icon?: React.ReactNode;
    disabled?: boolean;
    id?: string;
}

export const RadioBtn = ({ name, value, label, checked, onChange, icon, id, disabled = false }: RadioBtnProps) => {
    const radioRef = React.useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (!checked && !disabled) {
            onChange(value);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (disabled) return;

        if (e.key === 'Enter') {
            handleClick();
        }
    };

    React.useEffect(() => {
        if (checked && radioRef.current) {
            radioRef.current.focus();
        }
    }, [checked]);

    return (
        <div
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            className={`radioBtnWrapper${checked ? ' checked' : ''}${disabled ? ' disabled' : ''}`}
            role="radio"
            aria-checked={checked}
            tabIndex={0} // Make it focusable via Tab key
        >
            <Flex align='center' className='relative'>
                <input
                    ref={radioRef}
                    disabled={disabled}
                    className="radioBtn hidden"
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    checked={checked}
                    aria-checked={checked} // make it accessible to screen readers
                    readOnly
                    tabIndex={-1} // hide native radio button from tab navigation
                />
                {checked ? (
                    <Icon className='text-primary-600 me-3' icon={iconLibrary.faCircleCheck} />
                ) : (
                    <Icon className={`me-3 ${disabled ? 'text-neutralGrey-100' : 'text-hahGrey'}`} icon={iconLibrary.farCircle} />
                )}
                <p className={`text-base font-medium leading-normal flex-grow ${disabled ? 'text-neutralGrey-100' : ''}`}>
                    {label}
                </p>
                {icon && <span>{icon}</span>}
            </Flex>
        </div>
    );
};
