import { ApiResponseWrapper, ProviderBadges, ReviewsSummaryBreakdown } from '@components';
import {
    ProviderCard,
    HoursOfOperation,
    ProviderPhotos,
    ProviderCredentials,
    ReviewsSummary,
    ReviewsList,
    ProviderLocationMarkers,
} from '@generated/swaggerClient';
import { ApiCallResult, useBrandInfo, useCheckoutClientFactory, useCheckoutState } from '@hooks';
import { Tabs } from '@lemonsqueezy/wedges';
import { Flex, StarDisplay, Icon, iconLibrary, HahMap, ActionButton, useEffectAsync } from '@shared';
import { useCallback, useState } from 'react';

interface ProviderDetailsProps {
    provider: ProviderCard;
    selectedTab: 'info' | 'credentials' | 'reviews' | 'photos';
}

export const ProviderDetails: React.FC<ProviderDetailsProps> = ({ provider, selectedTab }) => {
    const { model } = useCheckoutState();
    const [hoursOfOperation, setHoursOfOperation] = useState<ApiCallResult<HoursOfOperation[]>>();
    const [photos, setPhotos] = useState<ApiCallResult<ProviderPhotos[]>>();
    const [credentials, setCredentials] = useState<ApiCallResult<ProviderCredentials[]>>();
    const [reviewsSummary, setReviewsSummary] = useState<ApiCallResult<ReviewsSummary>>();
    const [reviewsList, setReviewsList] = useState<ApiCallResult<ReviewsList>>();
    const [description, setDescription] = useState<ApiCallResult<string>>();
    const [providerLocations, setProviderLocations] = useState<ApiCallResult<ProviderLocationMarkers[]>>();
    const [currentTab, setCurrentTab] = useState<string>(selectedTab);
    const [reviewPageNumber, setReviewPageNumber] = useState<number>(1);
    const { wrapApiCall } = useCheckoutClientFactory();
    const brand = useBrandInfo();

    const recommended = provider.providerLocationID === model.recommendation.providerLocationID;

    const handleChangeTabs = useCallback((value: string) => {
        setCurrentTab(value);
    }, []);

    useEffectAsync(async () => {
        const fetchData = async () => {
            switch (currentTab) {
                case 'info': {
                    if (hoursOfOperation !== null) {
                        const hoursOfOperationRequest = await wrapApiCall(client => client.providersGetHours(provider.providerID));
                        setHoursOfOperation(hoursOfOperationRequest);
                    }
                    if (providerLocations !== null) {
                        const providerLocationsRequest = await wrapApiCall(client => client.providersGetLocations(provider.providerID));
                        setProviderLocations(providerLocationsRequest);
                    }
                    if (description !== null) {
                        const descriptionRequest = await wrapApiCall(client => client.providersGetDescription(provider.providerID));
                        setDescription(descriptionRequest);
                    }
                    break;
                }
                case 'photos': {
                    if (photos !== null) {
                        const photosRequest = await wrapApiCall(client => client.providersGetPhotos(provider.providerID));
                        setPhotos(photosRequest);
                    }
                    break;
                }
                case 'credentials': {
                    if (credentials !== null) {
                        const credentialsRequest = await wrapApiCall(client => client.providersGetCredentials(provider.providerLocationID));
                        setCredentials(credentialsRequest);
                    }
                    break;
                }
                case 'reviews': {
                    if (reviewsSummary !== null) {
                        const reviewSummaryRequest = await wrapApiCall(client => client.providersGetReviewsSummary(provider.providerID));
                        setReviewsSummary(reviewSummaryRequest);
                    }
                    const reviewsListRequest = await wrapApiCall(client => client.providersGetReviewsList(provider.providerID, reviewPageNumber, 5));
                    setReviewsList(reviewsListRequest);
                    break;
                }
            }
        };
        await fetchData();
    }, [currentTab, provider.providerID, provider.providerLocationID, reviewPageNumber]);

    return (
        <div>
            <Flex className='mb-2 flex-wrap' align='center'>
                <StarDisplay rating={provider.reviewAverage} id={provider.providerLocationID} />{' '}
                <span className='ms-2 me-4'>{provider.reviewAverage.toFixed(1)} avg.</span>
                <ProviderBadges badges={provider.badges} recommended={recommended} />
            </Flex>
            {provider.completedJobCount > 0 && (
                <div className='mb-1'>
                    <Icon icon={iconLibrary.faBox} fixedWidth className='mr-2' />
                    <strong>{provider.completedJobCount.toLocaleString()}</strong> moves in our marketplace
                </div>
            )}
            {provider.yearsInBusiness > 0 && (
                <div className='mb-1'>
                    <Icon icon={iconLibrary.faBriefcase} fixedWidth className='mr-2' />
                    <strong>{provider.yearsInBusiness.toLocaleString()}</strong> years in business
                </div>
            )}
            <Tabs className='my-4' variant='underlined' value={currentTab} onValueChange={handleChangeTabs}>
                <Tabs.List className='gap-0 md:gap-2'>
                    <Tabs.Trigger value='info'>Info</Tabs.Trigger>
                    <Tabs.Trigger value='credentials'>Credentials</Tabs.Trigger>
                    <Tabs.Trigger value='reviews'>Reviews</Tabs.Trigger>
                    <Tabs.Trigger value='photos'>Photos</Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value='info'>
                    <div className='text-neutralGrey-700 text-base font-semibold leading-normal mb-1'>About {provider.name}:</div>
                    <ApiResponseWrapper apiResult={description} errorMessage='Description could not be loaded.'>
                        {result => <p className='mb-4'>{result || 'No description provided.'}</p>}
                    </ApiResponseWrapper>
                    <ApiResponseWrapper apiResult={hoursOfOperation} errorMessage='Hours of operation could not be loaded.'>
                        {result => (
                            <div className='mb-4 p-4 bg-coolGrey-50 rounded-2xl text-neutralGrey-700'>
                                <div className='text-base font-semibold leading-normal mb-2'>Working Hours:</div>
                                {result.map((wh, i) => (
                                    <div key={i} className='gap-4 flex mb-2'>
                                        <div>{wh.day}:</div>
                                        <div className='grow text-end'>{wh.hours}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </ApiResponseWrapper>
                    <ApiResponseWrapper apiResult={providerLocations} errorMessage='Provider locations could not be loaded.'>
                        {result => <HahMap showDirections={false} markers={result} />}
                    </ApiResponseWrapper>
                </Tabs.Content>
                <Tabs.Content value='credentials'>
                    <div className='text-neutralGrey-700 text-base font-semibold leading-normal mb-1'>Licenses & Other Business Information:</div>
                    <p>
                        {brand.companyName} requires that moving labor providers operate within the law. Sometimes service providers make an extra effort to
                        provide features like background-checks, uniformed crew members, or commercial liability insurance.
                    </p>
                    <ApiResponseWrapper
                        apiResult={credentials}
                        errorMessage='Credentials could not be loaded.'
                        noResultMessage={`Notice: ${provider.name} has not provided any business credentials.`}>
                        {result => (
                            <>
                                {result.map((cred, i) => (
                                    <div key={i} className='my-4 p-4 bg-coolGrey-50 rounded-2xl text-neutralGrey-700'>
                                        <div className='text-base font-semibold leading-normal mb-2'>{cred.name}</div>
                                        {cred.attributes?.map((att, j) => (
                                            <div key={j} className='gap-4 flex mb-2'>
                                                <div>{att.name}:</div>
                                                <div className='grow text-end'>{att.value}</div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </>
                        )}
                    </ApiResponseWrapper>
                </Tabs.Content>
                <Tabs.Content value='reviews'>
                    <ApiResponseWrapper
                        apiResult={reviewsSummary}
                        errorMessage='Reviews summary could not be loaded.'
                        noResultMessage='No reviews summary available.'>
                        {result => <ReviewsSummaryBreakdown reviewsSummary={result} />}
                    </ApiResponseWrapper>
                    <ApiResponseWrapper
                        apiResult={reviewsList}
                        errorMessage='Reviews could not be loaded.'
                        noResultMessage='No reviews yet. How sad. But everyone has to start somewhere, right?'>
                        {result => (
                            <>
                                {result.items!.map((review, i) => (
                                    <div key={i} className='my-4 p-4 bg-coolGrey-50 rounded-2xl text-neutralGrey-700'>
                                        <div className='text-base font-semibold leading-normal mb-2'>
                                            {review.displayName} from{' '}
                                            <span className='text-primary-600'>
                                                {review.city}, {review.state}
                                            </span>
                                        </div>
                                        <StarDisplay rating={review.rating ?? 0} id={i} />
                                        <div className='mt-2'>{review.summary}</div>
                                    </div>
                                ))}
                                <Flex align='center' justify='center' className='gap-4'>
                                    <ActionButton
                                        className='focus-visible:outline-2'
                                        variant='link'
                                        onClickAction={() => setReviewPageNumber(result.pageNumber - 1)}
                                        disabled={result.pageNumber === 1}>
                                        <Icon icon={iconLibrary.faArrowLeft} />
                                    </ActionButton>
                                    <div>
                                        Page: {result.pageNumber} of {result.pageCount}
                                    </div>
                                    <ActionButton
                                        className='focus-visible:outline-2'
                                        variant='link'
                                        onClickAction={() => setReviewPageNumber(result.pageNumber + 1)}
                                        disabled={result.pageNumber === result.pageCount}>
                                        <Icon icon={iconLibrary.faArrowRight} />
                                    </ActionButton>
                                </Flex>
                            </>
                        )}
                    </ApiResponseWrapper>
                </Tabs.Content>
                <Tabs.Content value='photos'>
                    <ApiResponseWrapper
                        apiResult={photos}
                        errorMessage='Photos could not be loaded.'
                        noResultMessage={`${provider.name} has not provided any additional photos yet.`}>
                        {result => (
                            <div className='grid grid-cols-2 gap-4'>
                                {result!.map((photo, i) => (
                                    <img
                                        className='rounded-lg'
                                        key={i}
                                        src={photo.thumbnailUrl}
                                        title={photo.description}
                                        alt={photo.description || `Photo ${i + 1}`}
                                    />
                                ))}
                            </div>
                        )}
                    </ApiResponseWrapper>
                </Tabs.Content>
            </Tabs>
        </div>
    );
};
