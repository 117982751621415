import { DateTime } from "luxon";

export const formatDate = (value: string | undefined | DateTime | Date): string | undefined => {
    if (value) {
        const d = value instanceof Date ? DateTime.fromJSDate(value) : DateTime.isDateTime(value) ? value : DateTime.fromISO(value);
        return d.toLocaleString(DateTime.DATE_SHORT);
    }
    return undefined;
}
export const formatDateIso = (value: string | undefined | DateTime | Date): string | undefined => {
    if (value) {
        const d = value instanceof Date ? DateTime.fromJSDate(value) : DateTime.isDateTime(value) ? value : DateTime.fromISO(value);
        return d.toISODate()!;
    }
    return undefined;
}

export const toDateTime = (value: string | undefined | DateTime | Date): DateTime | undefined => {
    if (value) {
        const d = value instanceof Date ? DateTime.fromJSDate(value) : DateTime.isDateTime(value) ? value : DateTime.fromISO(value);
        return d;
    }
    return undefined;
}
export const formatDateWithOrdinal = (value: string | undefined | DateTime | Date): string | undefined => {
    if (value) {
        const d = value instanceof Date ? DateTime.fromJSDate(value) : DateTime.isDateTime(value) ? value : DateTime.fromISO(value);

        return d.toLocaleString(DateTime.DATE_MED);
    }
    return undefined;
}