import { emitDebuggingInfo } from '../pmgConfig';

export function debugLoggerInfo(message: string, ...optionalParams: unknown[]): void {
    if (emitDebuggingInfo) {
        console.info(`debugLogger: ${message}`, optionalParams);
    }
}

export function debugLoggerWarn(message: string, ...optionalParams: unknown[]): void {
    if (emitDebuggingInfo) {
        console.warn(`debugLogger: ${message}`, optionalParams);
    }
}

export function debugLoggerError(message: string, ...optionalParams: unknown[]): void {
    if (emitDebuggingInfo) {
        console.error(`debugLogger: ${message}`, optionalParams);
    }
}