import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Hook to detect user inactivity
 * @param timeout - time in milliseconds to consider user inactive default is 20000ms
 * @returns boolean - true if user is inactive
 */
export const useInactivity = (timeout: number = 20000): boolean => {

    const [isInactive, setIsInactive] = useState<boolean>(false);
    const hasTriggered = useRef<boolean>(false);
    const inactivityTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const resetTimer = useCallback(() => {
        //if already inactive, no need to reset - avoid multiple triggers
        if (hasTriggered.current) {
            return;
        }

        if (inactivityTimerRef.current) {
            clearTimeout(inactivityTimerRef.current);
        }
        setIsInactive(false);
        inactivityTimerRef.current = setTimeout(() => {
            setIsInactive(true);
            hasTriggered.current = true;
        }, timeout);
    }, [timeout]);

    useEffect(() => {
        const events: string[] = ['mousemove', 'keydown', 'scroll', 'click', 'touchstart', 'touchmove'];

        const handleUserActivity = () => {
            resetTimer();
        };

        //initializing listeners
        events.forEach((e) => {
            window.addEventListener(e, handleUserActivity);
        });
        resetTimer();

        //cleanup on unmount
        return () => {
            events.forEach((e) => {
                window.removeEventListener(e, handleUserActivity);
            });
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
        }
    }, [resetTimer]);

    return isInactive;
}
