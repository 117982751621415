import { Urls } from "@utils";
import { CheckoutPage, DatePage, ItemsPage, LoadLocationPage, LocationsPage, ProviderListPage, QuotePage, TimePage, UnloadLocationPage, ConfirmationPage, ContinuePage, NoProvidersFound, LdQuotePage, LdQuoteConfirmationPage, DebugPage } from "@pages";
import { IndexRouteObject, Navigate, NonIndexRouteObject } from 'react-router-dom';
import { NoMatchRoute } from "./NoMatchRoute";

// FUTURE: move page titles to this? we'll need new interfaces for the routes... then we can implement in the usePageTitles hook

const AppRoutes: (IndexRouteObject | NonIndexRouteObject)[] = [
  { index: true, element: <Navigate to={Urls.locations.index} replace /> },
  { path: Urls.locations.index, element: <LocationsPage /> },
  { path: Urls.locations.load, element: <LoadLocationPage /> },
  { path: Urls.locations.unload, element: <UnloadLocationPage /> },
  { path: Urls.items, element: <ItemsPage /> },
  { path: Urls.date, element: <DatePage /> },
  { path: Urls.quote.index, element: <QuotePage /> },
  { path: Urls.quote.providers, element: <ProviderListPage /> },
  { path: Urls.quote.longDistance, element: <LdQuotePage /> },
  { path: Urls.quote.longDistanceConfirmation, element: <LdQuoteConfirmationPage /> },
  { path: Urls.time, element: <TimePage /> },
  { path: Urls.checkout, element: <CheckoutPage /> },
  { path: Urls.confirmation, element: <ConfirmationPage /> },
  { path: Urls.continue, element: <ContinuePage /> },
  { path: Urls.noProvidersFound, element: <NoProvidersFound /> },

  { path: Urls.debug, element: <DebugPage /> },

  { path: '*', element: <NoMatchRoute /> }
];

export default AppRoutes;
