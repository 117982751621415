
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons/faCircleHalfStroke';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleCheck as farCircleCheck } from '@fortawesome/free-regular-svg-icons/faCircleCheck';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons/faCircleQuestion';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faWeightHanging } from '@fortawesome/free-solid-svg-icons/faWeightHanging';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimes as falTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faWarning } from '@fortawesome/free-solid-svg-icons/faWarning';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faComments } from '@fortawesome/free-regular-svg-icons/faComments';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons/faShieldHalved';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons/faHandHoldingDollar';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons/faKeyboard';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons/faStarHalfAlt';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faStairs } from '@fortawesome/free-solid-svg-icons/faStairs';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons/faHouseChimney';
import { faPeopleCarryBox } from '@fortawesome/free-solid-svg-icons/faPeopleCarryBox';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons/faCreditCard';
import { faBox } from '@fortawesome/free-solid-svg-icons/faBox';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faGem } from '@fortawesome/free-regular-svg-icons/faGem';
import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck';
import { faDolly } from '@fortawesome/free-solid-svg-icons/faDolly';
import { faSackDollar } from '@fortawesome/free-solid-svg-icons/faSackDollar';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faA } from '@fortawesome/free-solid-svg-icons/faA';
import { faB } from '@fortawesome/free-solid-svg-icons/faB';
import { faShield } from '@fortawesome/free-solid-svg-icons/faShield';
import { faDollar } from '@fortawesome/free-solid-svg-icons/faDollar';
import { faCertificate } from '@fortawesome/free-solid-svg-icons/faCertificate';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faDollyFlatbed } from '@fortawesome/free-solid-svg-icons/faDollyFlatbed';
import { faToiletPaper } from '@fortawesome/free-solid-svg-icons/faToiletPaper';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons/faBookmark';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';




export const iconLibrary = {
    faCertificate,
    faShield,
    faDollar,
    faA,
    faB,
    faPhone,
    //faBadgeCheck,
    faCircle,
    farCircle,
    faCircleHalfStroke,
    faCircleCheck,
    farCircleCheck,
    //faCircleDollar,
    faCircleQuestion,
    faCircleInfo,
    //faCircleA,
    //faCircleB,
    faWeightHanging,
    faMapMarkerAlt,
    faTimes,
    falTimes,
    faWarning,
    faChevronDown,
    faChevronUp,
    faComments,
    //faShieldCheck,
    faShieldHalved,
    faHeart,
    faHandHoldingDollar,
    faCalendarAlt,
    faCalendar,
    faKeyboard,
    faStar,
    farStar,
    faStarHalfAlt,
    faEnvelope,
    faPlus,
    faMinus,
    faStairs,
    faHouseChimney,
    faPeopleCarryBox,
    faClock,
    faCheck,
    faGear,
    faArrowRight,
    faArrowLeft,
    faMagnifyingGlass,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faCreditCard,
    faTruck,
    faDolly,
    faBox,
    faBriefcase,
    faGem,
    faSackDollar,
    faSearch,
    faBolt,
    faExclamationCircle,
    faDollyFlatbed,
    faWrench,
    faToiletPaper,
    farBookmark,
    faLock
}
library.add(iconLibrary as IconPack);
