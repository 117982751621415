import { DateTime, BusinessDayNumbers } from 'luxon-business-days';

export const longDistanceDisabledDaysRules = () => {

    const dt = DateTime.local();
    const businessDays: BusinessDayNumbers[] = [1, 2, 3, 4, 5];
    const holidays = [
        dt.availableHolidayMatchers.isNewYearsDay,
        dt.availableHolidayMatchers.isMemorialDay,
        dt.availableHolidayMatchers.isIndependanceDay,
        dt.availableHolidayMatchers.isLaborDay,
        dt.availableHolidayMatchers.isThanksgivingDay,
        dt.availableHolidayMatchers.isChristmasDay,
    ];

    dt.setupBusiness({ businessDays: businessDays, holidayMatchers: holidays });

    const isNextDay = (date: Date) => {
        const jsDate = DateTime.fromJSDate(date);
        return jsDate.plus({ days: 1 }) == DateTime.now().plus({ days: 1 });
    }

    const isNextThreeBusinessDays = (date: Date) => {
        const jsDate = DateTime.fromJSDate(date);
        const todayPlusTwo = DateTime.now().plus({ days: 2 });
        return jsDate <= todayPlusTwo;
    }

    const isHoliday = (date: Date) => {
        const jsDate = DateTime.fromJSDate(date);
        return jsDate.isHoliday() || jsDate.plus({ days: -1 }).isHoliday() || jsDate.plus({ days: 1 }).isHoliday();
    }

    const isWeekend = (date: Date) => {
        const jsDate = DateTime.fromJSDate(date);
        return !jsDate.isBusinessDay();
    }

    const disableRules = [
        (date: Date) => isNextDay(date), // Disable next day.
        (date: Date) => isNextThreeBusinessDays(date), // Disable three business days from today.
        (date: Date) => isWeekend(date), // Disable weekends.
        (date: Date) => isHoliday(date), // Disable holidays and one day before and after.
    ];

    return disableRules;
}