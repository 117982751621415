import { ProviderBadges, ProviderDetails } from '@components';
import { ProviderCard } from '@generated/swaggerClient';
import { useCheckoutState, useProviderListState } from '@hooks';
import { Flex, Icon, iconLibrary, StarDisplay, ActionButton, Modal } from '@shared';
import { formatCurrency } from '@utils';
import { useState } from 'react';

export const ProviderSummary = ({ provider, showSelect }: { provider: ProviderCard; showSelect: boolean }) => {
    const { model } = useCheckoutState();
    const [open, setOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState<'info' | 'credentials' | 'reviews' | 'photos'>('info');
    const { selectedProviderLocationID, setSelectedProviderLocationID } = useProviderListState();
    const selected = selectedProviderLocationID ? selectedProviderLocationID === provider.providerLocationID : model.selection.providerLocationID === provider.providerLocationID;
    const recommended = model.recommendation.providerLocationID == provider.providerLocationID;
    const confirmButton = (
        <ActionButton variant='transparent' className='text-neutralGrey-600 text-xl font-medium w-full' onClickAction={() => setOpen(false)}>
            <Icon size='xs' icon={iconLibrary.faArrowLeft} className='me-2' />
            Back
        </ActionButton>
    );
    return (
        <div
            className={`w-full p-4 bg-white rounded-lg border  mb-4 ${
                selected && showSelect
                    ? 'border-primary-600 border-2 shadow-[0px_8px_16px_0px_rgba(0,0,0,0.15)]'
                    : 'border-neutralGrey-200 shadow-[0px_2px_4px_-1px_rgba(18,18,23,0.06)]'
            }`}>
            <div className='inline-flex'>
                <a
                    className='pr-4 cursor-pointer'
                    onClick={() => {
                        setSelectedTab('photos');
                        setOpen(true);
                    }}>
                    <img className='rounded border border-primary w-10 h-10' src={provider.logoUrl} width={40} height={40} />
                </a>
                <div className='flex-auto'>
                    <Flex align='center' className='mb-2 gap-2 flex-wrap'>
                        <Modal
                            title={provider.name}
                            triggerText={provider.name}
                            open={open}
                            setOpen={setOpen}
                            onConfirm={() => setOpen(false)}
                            confirmButton={confirmButton}
                            confirmContent={
                                <>
                                    <Icon icon={iconLibrary.faArrowLeft} className='mr-2' />
                                    Close
                                </>
                            }>
                            <ProviderDetails provider={provider} selectedTab={selectedTab} />
                        </Modal>
                        <ProviderBadges badges={provider.badges} recommended={recommended} />
                    </Flex>
                    <div
                        className='mb-2 cursor-pointer'
                        onClick={() => {
                            setSelectedTab('reviews');
                            setOpen(true);
                        }}>
                        <StarDisplay rating={provider.reviewAverage} id={provider.providerLocationID} />{' '}
                        <strong>{provider.reviewCount.toLocaleString()} verified reviews</strong>
                    </div>
                    {provider.completedJobCount > 0 && (
                        <div className='mb-1'>
                            <Icon icon={iconLibrary.faBox} fixedWidth className='mr-2' />
                            <strong>{provider.completedJobCount.toLocaleString()}</strong> moves in our marketplace
                        </div>
                    )}
                    {provider.yearsInBusiness > 0 && (
                        <div className='mb-1'>
                            <Icon icon={iconLibrary.faBriefcase} fixedWidth className='mr-2' />
                            <strong>{provider.yearsInBusiness.toLocaleString()}</strong> years in business
                        </div>
                    )}
                </div>
            </div>
            {showSelect && (
                <ActionButton
                    className={`w-full text-lg disabled:opacity-100 mt-4 ${
                        selected
                            ? 'bg-primary-50 text-primary-600 hover:bg-primary-50 border border-transparent'
                            : 'bg-white text-primary-600 border hover:bg-white'
                    }`}
                    shape='pill'
                    variant='primary'
                    onClickAction={setSelectedProviderLocationID}
                    args={provider.providerLocationID}
                    disabled={selected}>
                    {selected ? (
                        <>
                            <Icon icon={iconLibrary.farCircleCheck} className='mr-2' />
                            Selected
                        </>
                    ) : (
                        'Select'
                    )}{' '}
                    - {formatCurrency(provider.grandTotal)}
                </ActionButton>
            )}
        </div>
    );
};
