import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Checkbox, Textarea } from '@lemonsqueezy/wedges';
import { iconLibrary, Flex, HahTooltip, Icon, RadioBtn, RadioBtnGroup, useEffectAsync } from '@shared';

import { HahInput, HahSelect, InputGroup } from '@shared';
import { debugLoggerInfo, debugLoggerWarn } from '@utils';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import React, { ChangeEventHandler, FormEventHandler, ReactNode } from 'react';

interface HahFormikFieldPropsGeneric {
    id?: string;
	name: string | number | symbol;
	icon?: IconDefinition;
	label?: string;
	placeholder?: string;
	autoComplete?: string;
	className?: string;
	parentName?: string;
	as?: React.ElementType<unknown>;
	rows?: number;
	type?: string;
	required?: boolean;
	maxLength?: number;
	tooltip?: string;
}
interface HahFormikFieldProps<T extends object> extends HahFormikFieldPropsGeneric {
	name: keyof T;
}
interface HahFormikSelectFieldProps<T extends object, TValue> extends HahFormikFieldProps<T> {
	options: { label: string; value: TValue }[];
    cols?: number;
	rounded?: ('topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight')[] | undefined;
}
interface HahFormikRadioProps<T extends object> extends Omit<HahFormikFieldProps<T>, 'icon'> {
    icon?: ReactNode;
	checkedValue: boolean | string;
    disabled?: boolean;
}
interface HahFormikRadioGroupProps extends HahFormikFieldPropsGeneric {
	children: React.ReactElement<typeof HahFormikRadio>[];
}
interface HahFormikCheckboxProps<T extends object> extends Omit<HahFormikFieldProps<T>, 'placeholder' | 'autoComplete' | 'label'>{
	label?: string | React.ReactNode;
    helperText?: string | React.ReactNode;
}

interface HahFormikFieldGroupProps<T extends object> extends HahFormikFieldProps<T> {
	cols?: number;
	rounded?: ('topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight')[] | undefined;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
}
export const HahFormikFieldGroup = <T extends object>({ name, parentName, cols, rounded, maxLength, ...rest }: HahFormikFieldGroupProps<T>) => {
	const fullName = parentName ? `${parentName}.${name as string}` : (name as string);
	const [inputProps, metaProps, helpers] = useField(fullName);
	const formikBag = useFormikContext();
	React.useEffect(() => {
		if (formikBag.isSubmitting && metaProps.error) {
			helpers.setTouched(true);
		}
	}, [formikBag.isSubmitting, helpers, metaProps.error]);

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await helpers.setValue(e.target.value, false);
		await helpers.setTouched(true, true);
	};

	return (
		<>
			<InputGroup.Input
				cols={cols ?? 1}
				rounded={rounded}
				maxLength={maxLength}
				destructive={metaProps.touched && !!metaProps.error}
				{...inputProps}
				{...rest}
				onChange={handleChange}
			/>
		</>
	);
};

// FUTURE: these need to use keyof to ensure type safety
export const HahFormikInputField = <T extends object>({ name, label, parentName, maxLength, className, ...rest }: HahFormikFieldProps<T>) => {
	const fullName = parentName ? `${parentName}.${name as string}` : (name as string);
	const [inputProps, metaProps, helpers] = useField(fullName);
	const formikBag = useFormikContext();
	React.useEffect(() => {
		if (formikBag.isSubmitting && metaProps.error) {
			helpers.setTouched(true);
		}
	}, [formikBag.isSubmitting, helpers, metaProps.error]);

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await helpers.setValue(e.target.value, false);
		await helpers.setTouched(true, true);
	};

	return (
		<div className={className}>
			<HahInput
				label={label ?? ''}
				maxLength={maxLength}
				destructive={metaProps.touched && !!metaProps.error}
				{...inputProps}
				{...rest}
				onChange={handleChange}
			/>
			<ErrorMessage name={fullName} component='div' className='text-red-600' />
		</div>
	);
};

export const HahFormikTextArea = <T extends object>({ name, label, parentName, maxLength, className, ...rest }: HahFormikFieldProps<T>) => {
	const fullName = parentName ? `${parentName}.${name as string}` : (name as string);
	const [inputProps, metaProps, helpers] = useField(fullName);
	const formikBag = useFormikContext();
	React.useEffect(() => {
		if (formikBag.isSubmitting && metaProps.error) {
			helpers.setTouched(true);
		}
	}, [formikBag.isSubmitting, helpers, metaProps.error]);

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> & FormEventHandler<HTMLLabelElement> = async e => {
		const event = e as React.ChangeEvent<HTMLTextAreaElement>;
		await helpers.setValue(event.target.value, false);
		await helpers.setTouched(true, true);
	};

	return (
		<div className={className}>
			<Textarea
				label={label ?? ''}
				maxLength={maxLength}
				destructive={metaProps.touched && !!metaProps.error}
				{...inputProps}
				{...rest}
				onChange={handleChange}
			/>
			<ErrorMessage name={fullName} component='div' className='text-red-600' />
		</div>
	);
};

export const HahFormikSelectField = <T extends object, TValue>({
	name,
	parentName,
	label,
	options,
	required,
	className,
    cols,
    rounded,
    placeholder
}: HahFormikSelectFieldProps<T, TValue>) => {
	const fullName = parentName ? `${parentName}.${name as string}` : (name as string);

	const [inputProps, metaProps, helpers] = useField(fullName);

	const formikBag = useFormikContext();

	const handleChange = (value: TValue) => {
		formikBag.setFieldValue(fullName, value);
	};

	useEffectAsync(async () => {
		if (formikBag.isSubmitting && metaProps.error) {
			await helpers.setTouched(true, true);
		}
	}, [formikBag.isSubmitting, helpers, metaProps.error]);

	return (
		<div className={className}>
			<HahSelect<TValue> placeholder={placeholder} cols={cols} rounded={rounded} id={fullName} label={label ?? ''} options={options} required={required} {...inputProps} aria-label={label} onChange={handleChange} />
			<ErrorMessage name={fullName} component='div' className='text-red-600' />
		</div>
	);
};

export const HahFormikCheckbox = <T extends object>({ name, parentName, label, className, tooltip, required, helperText }: HahFormikCheckboxProps<T>) => {
	const fullName = parentName ? `${parentName}.${name as string}` : (name as string);

	const [inputProps,, helpers] = useField(fullName);

    const handleChange = async () => {
		await helpers.setValue(!inputProps.value, false);
		await helpers.setTouched(true, true);
	};

	return (
		<Flex className={`gap-2 ${className}`} align='center'>
			<Checkbox label={label} id={fullName} {...inputProps} checked={inputProps.value} onCheckedChange={handleChange} required={required} helperText={helperText} />
			{/* Not using tooltip on wedges component because of styling */}
			{tooltip && (
				<HahTooltip tooltipContent={tooltip}>
					<Icon icon={iconLibrary.faCircleQuestion} className={'text-neutralGrey-700'} />
				</HahTooltip>
			)}
		</Flex>
	);
};

export const HahFormikRadio = <T extends object>({ name, parentName, label, checkedValue, icon, id, disabled = false }: HahFormikRadioProps<T>) => {
	const fullName = parentName ? `${parentName}.${name as string}` : (name as string);
	const [field,, helpers] = useField(fullName);

	const handleChange = async () => {
		await helpers.setValue(checkedValue, false);
		await helpers.setTouched(true, true);
	};

	return <RadioBtn id={id} disabled={disabled} icon={icon as ReactNode} onChange={handleChange} name={name as string} label={label ?? ''} checked={field.value === checkedValue} value={checkedValue.toString()} />;
};

export const HahFormikRadioGroup = ({ name, parentName, children, className }: HahFormikRadioGroupProps) => {
	const fullName = parentName ? `${parentName}.${name as string}` : (name as string);

	return (
		<>
			<RadioBtnGroup className={className}>{children}</RadioBtnGroup>
			<ErrorMessage name={fullName} component='div' className='mt-2 text-red-600' />
		</>
	);
};

export const ErrorFocus = () => {
	// Get the context for the Formik form this component is rendered into.
	const { isSubmitting, isValidating, errors } = useFormikContext();

	React.useEffect(() => {
		// Get all keys of the error messages.
		const keys = Object.keys(errors);
		// Whenever there are errors and the form is submitting but finished validating.
		if (keys.length > 0 && isSubmitting) {
			// We grab the first input element that error by its name.
			let errorElement = document.querySelector(`[name="${keys[0]}"]`);
			// Ok we don't have a name, lets just grab the first element marked as
			if (!errorElement) {
				errorElement = document.querySelector('[aria-invalid="true"]');
			}
			if (errorElement) {
				debugLoggerInfo(`ErrorFocus errorElement display=${window.getComputedStyle(errorElement).display}`);
				// Ok, if the element is hidden, get the first invalid message and scroll to that instead
				if (window.getComputedStyle(errorElement).display === 'none') {
					const invalidFeedback = document.querySelector('[aria-invalid="true"] ~ .text-red-600');
					if (invalidFeedback) {
						invalidFeedback.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
				} else {
					// When there is an input, scroll this input into view.
					errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
					(errorElement as HTMLElement).focus();
				}
			} else {
				debugLoggerWarn(`ErrorFocus no errorElement - errors: ${JSON.stringify(errors)}`);
			}
		}
	}, [isSubmitting, isValidating, errors]);

	// This component does not render anything by itself.
	return null;
};
