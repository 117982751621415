/**
 * Wrapper around JSON'ing of debug data.
 * @param obj The object to be JSON'd.
 * @returns JSON string.
 */
export function js(obj: object | undefined): string | object {
    if (obj === undefined) {
        return '(undefined)';
    }
    try {
        return JSON.stringify(obj, null, 2);
    } catch {
        // just return object i guess
        return obj;
    }
}

/**
 * Wrapper around JSON'ing of debug data. If we cannot convert it to JSON, we will just return '(JSON.stringify error)'
 * @param obj The object to be JSON'd.
 * @returns JSON string.
 */
export function js2(obj: object | undefined): string {
    if (obj === undefined) {
        return '(undefined)';
    }
    try {
        return JSON.stringify(obj, null, 2);
    } catch {
        // just return object i guess
        return '(JSON.stringify error)';
    }
}