import { useEffectOnlyOnceAsync } from "@shared";
import { useState } from "react";

interface OptimizelyParams {
    optimizely: Window["optimizely"];
    apiName: string;
    experimentId: string;
    variantId: string;
    fetchVariant: (optimizely: Window["optimizely"], apiName: string, experimentId: string) => Promise<string>;
}

/**
 * Custom hook to determine if a user is in a specific Optimizely experiment variant.
 *
 * @param {Object} params - The parameters object.
 * @param {Window["optimizely"]} params.optimizely - The Optimizely object from the window.
 * @param {string} params.apiName - The API name of the experiment.
 * @param {string} params.experimentId - The ID of the experiment.
 * @param {string} params.variantId - The ID of the variant group.
 * @param {function} params.fetchVariant - Function to fetch the variant for the user.
 *
 * @returns {boolean} - Returns true if the user is in the specified variant, otherwise false.
 */
export function useOptimizelyExperiment({ optimizely, apiName, experimentId, variantId, fetchVariant }: OptimizelyParams) {
    const optimizelyInitialized = !!(optimizely && optimizely.initialized);
    const [optimizelyBucketed, setOptimizelyBucketed] = useState(false);
    const [userIsInVariant, setUserIsInVariant] = useState(false);

    useEffectOnlyOnceAsync(async () => {
        if (optimizelyInitialized && !optimizelyBucketed) {
            setOptimizelyBucketed(true);
            try {
                const variant = await fetchVariant(optimizely, apiName, experimentId);
                if (variant === variantId) {
                    console.info("🧪 User is in the experiment variant");
                    setUserIsInVariant(true);
                } else {
                    console.info("🧪 User is NOT in the variant", { variant });
                }
            } catch (err) {
                console.error("🧪 Error checking Optimizely experiment", { err });
            }
        }
    });

    return userIsInVariant;
}
