import { Label } from "@lemonsqueezy/wedges";
import { HahInput, roundedClassMap } from "@shared";

interface InputGroupProps {
    label?: React.ReactNode;
    rows: number;
    cols: number;
    children: React.ReactElement<typeof InputGroup.Input>[];
    className?: string;
    required?: boolean;
}
export const InputGroup: React.FC<InputGroupProps> & {
    Input: React.FC<InputProps>;
} = ({ label, rows, cols, children, className, required }) => {
    return (
        <div className={`relative flex flex-col ${className}`}>
            {label && <Label className='mb-2'>{label} {required && <span className='text-red-600'>*</span>}</Label>}
            <div
                className={`input-group grid grid-cols-${cols} grid-rows-${rows}`}
            >
                {children}
            </div>
        </div>
    );
};

export interface InputProps {
    placeholder?: string;
    value?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    cols: number;
    rounded?: Array<"topLeft" | "topRight" | "bottomLeft" | "bottomRight"> | undefined;
    maxLength?: number;
    destructive?: boolean;
    name: string;
    required?: boolean;
    id?: string;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    autoComplete?: string;
}
export interface TextAreaProps {
    placeholder?: string;
    name: string;
    value?: string | undefined;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
    cols: number;
    rows: number;
    rounded?: Array<"topLeft" | "topRight" | "bottomLeft" | "bottomRight">;
}




InputGroup.Input = ({
    placeholder,
    value,
    onChange,
    cols,
    rounded = [],
    maxLength,
    destructive,
    name,
    required,
    id,
    iconLeft,
    iconRight,
    autoComplete
}: InputProps) => {

    const roundedClasses = rounded
        .map((key) => roundedClassMap[key] || "")
        .join(" ");

    return (
        <div className={`col-span-${cols} ${roundedClasses}`}>
            <HahInput
                id={id}
                name={name}
                destructive={destructive}
                maxLength={maxLength}
                required={required}
                className={`h-10 !rounded-none focus:-outline-offset-2 w-full px-3 py-2 text-sm ${roundedClasses}`}
                placeholder={placeholder}
                value={value}
                iconLeft={iconLeft}
                iconRight={iconRight}
                autoComplete={autoComplete}
                onChange={onChange}
            />
        </div>
    );
};