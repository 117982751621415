import { Option } from '../types';

export const enumValuesToOptions = (enumObject: readonly string[], descriptionObject: { [key: string]: string }, emptyLabel?: string): Option<string | undefined>[] => {
    const result: Option<string | undefined>[] = enumObject.map(key => {
        return {
            value: key,
            label: descriptionObject[key]
        }
    });
    if (emptyLabel) {
        result.unshift({
            value: undefined,
            label: emptyLabel
        });
    }
    return result;
}

export const enumToOptions = (enumObject: { [key: string]: string }, descriptionObject: { [key: string]: string }, emptyLabel?: string): Option<string | undefined>[] => {
    return enumValuesToOptions(Object.keys(enumObject), descriptionObject, emptyLabel);
}