import { DateForm } from "@components";
import { UpdateDateDto } from "@generated/swaggerClient";
import { defaultState, useCheckoutClientFactory, useCheckoutNavigation, useCheckoutState } from "@hooks";
import { sentryWrapper } from "@shared";
import { DateFormModel } from "@types";
import { js2 } from "@utils";
import { FormikHelpers } from "formik";

export const DatePage = () => {
    const { model, setModel } = useCheckoutState();
    const { wrapApiCall } = useCheckoutClientFactory();
    const { onNavigate } = useCheckoutNavigation();

    const handleChange = async (values: DateFormModel, formikHelpers: FormikHelpers<DateFormModel>) => {
        // We clear the recommendation and selection so that we can get a new recommendation for the user
        setModel({ jobDate: values.date, recommendation: defaultState.model.recommendation, selection: defaultState.model.selection });

        const dto = UpdateDateDto.fromJS(values);
        const result = await wrapApiCall((client) => client.dateTimeUpdateDate(model.id, dto), formikHelpers);

        if (!result.success) {
            sentryWrapper.logException(result.errorObj, 'error', 'Error updating date', { component: 'DatePage' }, { id: model.id, dto: js2(dto) });
        }

        return result.success;
    }

    return <DateForm onChange={handleChange} onSubmitted={onNavigate} />

}