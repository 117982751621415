import { ApiCallResult } from "@hooks";
import { SpinnerBasic } from "@shared";

interface ApiResponseWrapperProps<T> {
    apiResult?: Pick<ApiCallResult<T>, 'success' | 'result'>;
    errorMessage?: string;
    noResultMessage?: string;
    children: (result: T) => JSX.Element;
}

/**
 * A wrapper component that handles the rendering of API response states.
 *
 * @template T - The type of the result expected from the API response.
 *
 * @param {ApiResponseWrapperProps<T>} props - The properties for the ApiResponseWrapper component.
 * @param {ApiResult<T> | null} props.apiResult - The result of the API call. It can be null, an error, or a successful result.
 * @param {string} [props.errorMessage] - The message to display when the API call fails.
 * @param {string} [props.noResultMessage] - The message to display when the API call returns an empty result.
 * @param {(result: T) => JSX.Element} props.children - A function that renders the successful result.
 * @returns {JSX.Element} The rendered component based on the API response state.
 */
export const ApiResponseWrapper = <T,>({ apiResult, errorMessage, noResultMessage, children }: ApiResponseWrapperProps<T>): JSX.Element => {
    if (!apiResult) {
        {/* FUTURE: If we're concerned with layout shift we should add skeleton or some placeholder https://www.npmjs.com/package/react-loading-skeleton */}
        return (
            <div className='my-4'>
                <SpinnerBasic />
            </div>
        );
    }

    if (!apiResult.success) {
        return (
            <div className='my-4'>
                <em>{errorMessage || 'There was an issue loading data.'}</em>
            </div>
        );
    }

    if (apiResult.result && Array.isArray(apiResult.result) && apiResult.result.length === 0) {
        return <div className='my-4'>{noResultMessage || 'There were no results found.'}</div>;
    }

    return <>{children(apiResult.result!)}</>;
};