import { useCheckoutClientFactory, useCheckoutNavigation, useCheckoutState, useProviderListState } from '@hooks';
import { NumHelpersAndHours, QuoteFormModel, QuoteProviderInfo } from '@types';
import { FormikHelpers } from 'formik';
import { FatalErrorForm, MovingPlanV1 } from '@components';
import { ProviderCard, ProviderSearch, QuoteSelectionsDto, UpdateQuoteDto } from '@generated/swaggerClient';
import { RecommendedProviderSortOrder, sentryWrapper, SpinnerNew, useEffectAsync, useEffectOnlyOnceAsync } from '@shared';
import { useRef, useState } from 'react';
import { debugLoggerInfo, js2, Urls } from '@utils';
import { useNavigate } from 'react-router-dom';

export const QuotePage = () => {
    const { onNavigate } = useCheckoutNavigation();
    const { model, setModel, setLastGetProvidersResult } = useCheckoutState();
    const { selectedProviderSortOrder } = useProviderListState();
    const { wrapApiCall } = useCheckoutClientFactory();
    const navigate = useNavigate();
    const [selectedProvider, setSelectedProvider] = useState<ProviderCard>();
    const [recommendedProvider, setRecommendedProvider] = useState<ProviderCard>();
    const [bookableProviderCount, setBookableProviderCount] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isFatalError, setIsFatalError] = useState<boolean>(false);
    const [estimateNumHelpersAndHours, setEstimateNumHelpersAndHours] = useState<NumHelpersAndHours>({ laborHours: 0, numHelpers: 0 });

    const getAndSetRecommendedProvider = async (overrideCrewSize?: number, overrideLaborHours?: number) => {
        const request = new ProviderSearch({
            overrideCrewSize: overrideCrewSize,
            overrideLaborHours: overrideLaborHours,
        });

        setIsFetching(true);
        const result = await wrapApiCall(client => client.providersGetProviders(model.id, request));
        setIsFetching(false);

        if (!result.success) {
            // we experienced some server error
            sentryWrapper.logFatal('Get providers server call failed', { component: 'QuotePage' }, { model: JSON.stringify(model) });

            setIsFatalError(true);
            return;
        }

        const solidResult = result.result!;

   	    // set into state so we have it on no-providers-found page if needed
    	setLastGetProvidersResult(solidResult);

        if (solidResult.providers.length === 0) {
            sentryWrapper.logWarn('No providers were returned from server', { component: 'QuotePage' }, { model: JSON.stringify(model) });
            return navigate(Urls.noProvidersFound);
        } else if (!solidResult.recommendedProviderLocationID) {
            sentryWrapper.logWarn('No recommended provider was returned from server', { component: 'QuotePage' }, { model: JSON.stringify(model) });
            return navigate(Urls.noProvidersFound);
        }

        setBookableProviderCount(solidResult.providers.length);

        // FUTURE: this really should be centralized. it was entirely too hard to find this buried.
        const mapProviderCardToQuoteProviderInfo = (provider: ProviderCard, settingRecommended: boolean): QuoteProviderInfo => {
            // this feels kinda yucky. because this is depending on someone having visited the provider list - which in theory, they should..
            const sortOrderChosen = settingRecommended ? RecommendedProviderSortOrder : selectedProviderSortOrder;

            return {
                grandTotal: provider.grandTotal,
                providerID: provider.providerID,
                providerLocationID: provider.providerLocationID,
                hours: settingRecommended ? provider.actualNumHours : overrideLaborHours ?? provider.actualNumHours,
                movers: settingRecommended ? provider.actualCrewSize : overrideCrewSize ?? provider.actualCrewSize,
                transportOptionId: provider.transportOptionId,

                providerName: provider.name,
                extraHourRate: provider.serviceDetails?.extraHourRate,
                transportOptionName: provider.serviceDetails?.transportOptionName,
                providerListQueryID: solidResult.providerListQueryIDs[sortOrderChosen]!,
                providerIndex: provider.sortOrder[sortOrderChosen],
            };
        };

        const recommendedProviderEntry = solidResult.providers.find(p => p.providerLocationID === solidResult!.recommendedProviderLocationID)!;

        setRecommendedProvider(recommendedProviderEntry);

        setEstimateNumHelpersAndHours({ laborHours: solidResult.estimateLaborHours, numHelpers: solidResult.estimateCrewSize });

        // we always set the recommendation
        setModel({ recommendation: mapProviderCardToQuoteProviderInfo(recommendedProviderEntry, true) });

        // if we have no selection yet, set the selection to the recommended provider
        if (model.selection.providerLocationID == null) {
            setModel({ selection: mapProviderCardToQuoteProviderInfo(recommendedProviderEntry, false) });
            setSelectedProvider(recommendedProviderEntry);
            return;
        }

        // we have a selected one, so make sure they're on the list still

        const selectedProvider = solidResult.providers.find(p => p.providerLocationID === model.selection.providerLocationID);

        // If the previously selectedProvider is no longer in the list of available providers, select the recommended provider
        if (!selectedProvider) {
            setModel({ selection: mapProviderCardToQuoteProviderInfo(recommendedProviderEntry, false) });
            setSelectedProvider(recommendedProviderEntry);
            return;
        }

        // otherwise, update the selected provider with the latest data
        setModel({ selection: mapProviderCardToQuoteProviderInfo(selectedProvider, false) });
        setSelectedProvider(selectedProvider);
    };

    // useRef to track whether the initial fetch has been done
    const hasFetched = useRef(false);

    // Effect to handle initial fetch
    useEffectOnlyOnceAsync(async () => {
        debugLoggerInfo('Quote.tsx - firing off useEffectAsync (initial)', { hours: model.selection.hours, movers: model.selection.movers });

        // This potentially causes issues for users going back and forth on pages. On the date and items page we clear the recommendation and selection so that we can get a new recommendation for the user, but on this page we keep the selection which will cause us to get the recommended provider with these overrides. This probably needs some refactoring.
        await getAndSetRecommendedProvider(model.selection.movers, model.selection.hours);

        // Mark that the fetch has been done
        hasFetched.current = true;
    });

    // Effect to handle subsequent updates to movers and hours
    useEffectAsync(async () => {
        if (!hasFetched.current) {
            return; // Don't fetch again until initial fetch is complete
        }

        debugLoggerInfo('Quote.tsx - firing off useEffectAsync (update)', { hours: model.selection.hours, movers: model.selection.movers });

        await getAndSetRecommendedProvider(model.selection.movers, model.selection.hours);
    }, [model.selection.movers, model.selection.hours]);

    const handleChange = async (values: QuoteFormModel, formikHelpers: FormikHelpers<QuoteFormModel>) => {
        setModel({
            selection: {
                ...model.selection,
                hours: values.hours,
                movers: values.movers,
            },
        });

        // These props (model.recommendation and model.section) should not have any null/undefined values at this point
        const dto = new UpdateQuoteDto({
            recommendation: new QuoteSelectionsDto({
                hours: model.recommendation.hours!,
                movers: model.recommendation.movers!,
                providerLocationID: model.recommendation.providerLocationID!,
                providerID: model.recommendation.providerID!,
                transportOptionId: model.recommendation.transportOptionId!,
                grandTotal: model.recommendation.grandTotal!,
                providerIndex: model.recommendation.providerIndex,
                providerListQueryID: model.recommendation.providerListQueryID,
            }),
            selection: new QuoteSelectionsDto({
                hours: values.hours,
                movers: values.movers,
                providerLocationID: model.selection.providerLocationID!,
                providerID: model.selection.providerID!,
                transportOptionId: model.selection.transportOptionId!,
                grandTotal: model.selection.grandTotal,
                providerIndex: model.selection.providerIndex,
                providerListQueryID: model.selection.providerListQueryID,
            }),
        });

        const result = await wrapApiCall(client => client.quoteUpdate(model.id, dto), formikHelpers);

        if (!result.success) {
            sentryWrapper.logException(result.errorObj, 'error', 'Error updating quote', { component: 'QuotePage' }, { id: model.id, dto: js2(dto) });
        }

        return result.success;
    };

    if (isFatalError) {
        return <FatalErrorForm />;
    }

    if (!selectedProvider || !recommendedProvider) {
        return <SpinnerNew fixed verticallyCentered text='Checking availability...' />;
    }

    return (
        <MovingPlanV1
            onSubmitted={onNavigate}
            onChange={handleChange}
            selectedProvider={selectedProvider}
            bookableProviderCount={bookableProviderCount}
            isFetching={isFetching}
            estimateNumHelpersAndHours={estimateNumHelpersAndHours}
        />
    );
};
