import { create } from 'zustand';
import { CheckoutModel, CheckoutState, CheckoutStateModel, emptyAddress, defaultHeavyItems } from '@types';
import { devtools, persist } from 'zustand/middleware';
import { DateTime } from 'luxon';
import { toDateTime } from '@shared';
import { ProviderSortOrder } from '@generated/swaggerClient';
import { IProviderListResult } from '@generated/swaggerClient';

export const defaultState: CheckoutStateModel = {
    loggedManualEntry: false,
    model: {
        id: '',
        selection: {
            providerID: undefined,
            providerLocationID: undefined,
            movers: undefined,
            hours: undefined,
            transportOptionId: undefined,
            grandTotal: undefined,
        },
        selectionSortOrder: undefined,
        recommendation: {
            providerID: undefined,
            providerLocationID: undefined,
            movers: undefined,
            hours: undefined,
            transportOptionId: undefined,
            grandTotal: undefined,
        },
        loadAddress: emptyAddress,
        unloadAddress: emptyAddress,
        loadAddressDetails: {},
        unloadAddressDetails: {},
        manualAddressEntry: false,
        preferredStartTime: '',
        jobDate: '',
        heavyItems: defaultHeavyItems,
        contact: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
        },
        isLongDistance: false,
        discountAmount: undefined,
        discountCode: undefined,
        fastMode: false,
    },
    lastGetProvidersResult: undefined,
    lastUpdated: DateTime.now(),
    isFetching: false,
};

export const useCheckoutState = create<CheckoutState>()(
    persist(
        devtools(
            set => ({
                ...defaultState,
                setIsFetching: (fetching: boolean) => set(() => ({ isFetching: fetching }), undefined, { type: 'setIsFetching' }),
                setModel: (model: Partial<CheckoutModel>) =>
                    set(state => ({ model: { ...state.model, ...model }, lastUpdated: DateTime.now() }), undefined, { type: 'setModel' }),
                setLastGetProvidersResult: (model: IProviderListResult) =>
                    set(state => ({ lastGetProvidersResult: { ...state.lastGetProvidersResult, ...model }, lastUpdated: DateTime.now() }), undefined, { type: 'setLastGetProvidersResult' }),
                setLoggedManualEntry: () => set(() => ({ loggedManualEntry: true, lastUpdated: DateTime.now() }), undefined, { type: 'setLoggedManualEntry' }),
                checkForExpiration: () =>
                    set(
                        state => {
                            // FUTURE: Change this to whatever we want for the expiration
                            if (Math.abs(toDateTime(state.lastUpdated)!.diffNow('day').days) > 5) {
                                return defaultState;
                            }
                            return state;
                        },
                        undefined,
                        { type: 'checkForExpiration' }
                    ),
                selectProvider: ({ providerLocationID, providerSortOrder }: { providerLocationID: number, providerSortOrder: ProviderSortOrder }) =>
                    set(state => ({ model: { ...state.model, selection: { providerLocationID }, selectionSortOrder: providerSortOrder }, lastUpdated: DateTime.now() }), undefined, {
                        type: 'selectProvider',
                    })
            }),
            { name: 'checkout', store: 'checkout' }
        ),
        { name: 'checkout' }
    )
);
