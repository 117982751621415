import { CheckoutForm } from '@components';
import { BraintreeClientPaymentMethod, PmgCheckoutWebApiUnprocessableEntityError, QuoteCheckoutDto } from '@generated/swaggerClient';
import { useCheckoutState, useCheckoutClientFactory, defaultState } from '@hooks';
import { exportLeadsUserData, performDataLayerLog, sentryWrapper, Spinner, toastError, toE164, useEffectOnlyOnce } from '@shared';
import { CheckoutFormModel } from '@types';
import { debugLoggerInfo, js2, Urls } from '@utils';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CheckoutPage = () => {
    const navigate = useNavigate();
    const { model, setModel } = useCheckoutState();
    const { wrapApiCall } = useCheckoutClientFactory();
    const optimizelyInitialized = !!(window.optimizely && window.optimizely.initialized);
    const [scriptsLoaded, setScriptsLoaded] = useState(false);

    useEffectOnlyOnce(() => {
        if (!scriptsLoaded) {
            const loadScripts = () => {
                const scripts = [
                    { src: 'https://pay.google.com/gp/p/js/pay.js' },
                    { src: 'https://js.braintreegateway.com/web/3.115.2/js/client.min.js' },
                    { src: 'https://js.braintreegateway.com/web/3.115.2/js/data-collector.min.js' },
                    { src: 'https://js.braintreegateway.com/web/3.115.2/js/hosted-fields.min.js' },
                    { src: 'https://js.braintreegateway.com/web/3.115.2/js/paypal-checkout.min.js' },
                    { src: 'https://js.braintreegateway.com/web/3.115.2/js/google-payment.min.js' },
                    { src: 'https://js.braintreegateway.com/web/3.115.2/js/apple-pay.min.js' },
                ];

                let loadedCount = 0;

                scripts.forEach(({ src }) => {
                    // Check if the script is already present in the document
                    if (!document.querySelector(`script[src="${src}"]`)) {
                        const script = document.createElement('script');
                        script.src = src;
                        script.async = true;
                        script.onload = () => {
                            loadedCount++;
                            if (loadedCount === scripts.length) {
                                setScriptsLoaded(true);
                                debugLoggerInfo('All Braintree scripts loaded');
                            }
                        };
                        document.head.appendChild(script);
                        debugLoggerInfo(`Loading Braintree script: ${src}`);
                        return;
                    }

                    // Script already loaded, increment the counter
                    debugLoggerInfo(`Braintree Script already loaded so not loading again: ${src}`);
                    loadedCount++;
                    if (loadedCount === scripts.length) {
                        setScriptsLoaded(true);
                        debugLoggerInfo('All Braintree scripts loaded');
                    }
                });

                // nicholashead-pmg as part of PMG-6542 - log a fatal if the scripts don't load within 30 seconds
                setTimeout(() => {
                    if (!scriptsLoaded) {
                        sentryWrapper.logFatal('Braintree scripts failed to load within timeout period', { component: 'CheckoutPage' });
                    }
                }, 30000);
            };

            loadScripts();
        }
    });

    const onNavigate = () => {
        // Clear the model so we don't have a quote they can return to, there are a few items we need on this page that we'll need on the confirmation page
        setModel(defaultState.model);
        navigate(Urls.confirmation, { state: { model } });
    }

    const handleBook = async (values: CheckoutFormModel, formikHelpers: FormikHelpers<CheckoutFormModel>, paymentNonce: string, cardholderName: string, digitalWalletBillingPostalCode: string, deviceData: string) => {
        const billingPostalCode = values.paymentMethod === BraintreeClientPaymentMethod.CreditCard ? values.paymentInfo.billingZip : digitalWalletBillingPostalCode;

        const dto = QuoteCheckoutDto.fromJS({ braintreePaymentToken: paymentNonce, cardholderName, billingPostalCode, paymentMethod: values.paymentMethod, deviceData, ...values.contact });

        const result = await wrapApiCall((client) => client.quoteCheckout(model.id, dto), formikHelpers);

        const msgFallback = 'There was an error processing your payment. Please try again.';

        if (!result.success) {
            if (result.errorObj != null && result.errorObj instanceof PmgCheckoutWebApiUnprocessableEntityError) {
                sentryWrapper.logWarn(`PmgCheckoutWebApiUnprocessableEntityError during checkout: ${result.errorObj.title ?? ''}`, { component: 'CheckoutPage' }, { id: model.id, dto: js2(dto) });

                toastError({ title: result.errorObj.title ?? msgFallback }); // show public-facing message to user
            }
            else
            {
                // treat as fatal because it's on final payment page and a big deal
                sentryWrapper.logException(result.errorObj, 'fatal', 'Error during quote checkout', { component: 'CheckoutPage' }, { id: model.id, dto: js2(dto) });

                // in theory we should never get here, but lets just show a generic message in case.
                toastError({ title: msgFallback });
            }
        }
        else
        {
            const actualResult = result.result!;

            // send to ga4
            performDataLayerLog({
                event: 'purchase',
                ecommerce: {
                    transaction_id: actualResult.primaryOrderId,
                    value: actualResult.totalNetIncome,
                    currency: "USD",
                    coupon: null, // FUTURE: v2+ may have codes
                    items: [
                    {
                      item_id: actualResult.jobs[0].jobID,
                      item_name: actualResult.jobs[0].analyticsItemName,
                      coupon: null, // FUTURE: v2+ may have codes
                      discount: actualResult.jobs[0].totalDiscountAmount,
                      item_brand: actualResult.jobs[0].providerCompanyName,
                      item_category: actualResult.jobs[0].analyticsItemCategory,
                      price: actualResult.jobs[0].netIncome,
                      quantity: 1
                    }]
                  }
            }, false, true);

            if (optimizelyInitialized) {
                const cents = Math.round(actualResult.totalNetIncome * 100);
                window.optimizely!.push({
                    type: "event",
                    eventName: "purchase",
                    tags: {
                        revenue: cents, // Optional in cents as integer (500 == $5.00)
                        //value: actualResult.totalNetIncome // Optional as float
                    }
                });
            }

            let convertedPhone = values.contact.phone ?? '';
            try {
                // try and clean up to E.164 format per John B - this is kind of a HACK: and i'd prefer to do it server-side in the FUTURE:
                convertedPhone = toE164(convertedPhone);
            } catch (e) {
                // log and then we'll just fall back to the original phone number
                sentryWrapper.logException(
                    e,
                    'warning',
                    'toE164 couldnt convert number',
                    { component: 'CheckoutPage' },
                    { additionalMessage: 'toE164 couldnt convert', phone: values.contact.phone }
                );
            }

            // send "lead" data to ga4 too
            // https://porchmovinggroup.atlassian.net/browse/PMG-4516
            exportLeadsUserData({
                email: values.contact.email,
                phone_number: convertedPhone,
                address: {
                    first_name: values.contact.firstName,
                    last_name: values.contact.lastName,
                    street: model.loadAddress.street,
                    city: model.loadAddress.city,
                    region: model.loadAddress.state,
                    country: 'US',
                    postal_code: model.loadAddress.zip,
                },
            });
        }

        /*
    ecommerce: {
    transaction_id: "((PRIMARY_JOB_ID))",
    // Sum of (revenue * quantity) for all items.
    value: ((TOTALREVENUE)),
    currency: "USD",
    coupon: "((DISCOUNT_CODE))",
    items: [
    {
      item_id: "JOB_ID",
      item_name: "((type of Service))",
      coupon: "((DISCOUNT_CODE))",
      discount: ((AMOUNTOFDISCOUNT)),
      item_brand: "((MSP_NAME))",
      item_category: "((Service Classification (Labor/M+T, etc.))",
      price:((Individual Job Revenue)),
      quantity: 1
    },
    {
      item_id: "JOB2_ID",
      item_name: "((type of Service)",
      coupon: "((DISCOUNT_CODE))",
      discount: ((AMOUNTOFDISCOUNT)),
      item_brand: "((MSP_NAME))",
      item_category: ((Service Classification (Labor/M+T, etc.))",
      price: (JOB2_Revenue),
      quantity: 1
    }]
  }*/

        return result.success;
    };

    if (!scriptsLoaded || !model.id) {
        return <Spinner fixed />;
    }

    return <CheckoutForm onSubmitted={onNavigate} onHandleBook={handleBook} />;
};
