import { Feature } from '@components';
import { iconLibrary, Flex } from '@shared';

export const FeatureList = () => (
	<Flex grow={1} direction='row' justify='between' className='w-[576px] my-6'>
		<Feature icon={[iconLibrary.faCircle, iconLibrary.faDollar]} text='Best Price and Value for Moving' />
		<Feature icon={[iconLibrary.faShield, iconLibrary.faCheck]} text='Insured, Licensed, & Certified Movers' />
        <Feature
            icon={iconLibrary.faHeart}
            text={
                <>
                    Customer Service <span style={{ whiteSpace: 'nowrap' }}>7 Days a Week</span>
                </>
            }
        />
	</Flex>
);