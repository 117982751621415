import { Card, ErrorFocus, Flex, HahFormikCheckbox, HahFormikFieldGroup, Icon, iconLibrary, InputGroup } from '@shared'
import { LdFormModel } from '@types';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { ldQuoteValidationSchema } from '@modules';
import { Footer, Headings } from '@components';
import { defaultState, useCheckoutState } from '@hooks';
import { Urls } from '@utils';

type TModel = LdFormModel;

interface Props {
    onSubmitted: (action?: string) => void;
    onChange: (values: TModel, formikHelpers: FormikHelpers<TModel>) => Promise<boolean>;
}

export const LdQuoteForm = ({ onSubmitted, onChange }: Props) => {

    const { model, setModel } = useCheckoutState();

    const handleSubmit = async (values: TModel, formikHelpers: FormikHelpers<TModel>) => {
        if (await onChange(values, formikHelpers)) {
            setModel(defaultState.model);
            onSubmitted(Urls.quote.longDistanceConfirmation);
        }
    }

    const initialValues: LdFormModel = {
        contact: {
            email: model.contact?.email ?? '',
            phone: model.contact?.phone ?? '',
            firstName: model.contact?.firstName ?? '',
            lastName: model.contact?.lastName ?? ''
        },
        agreeToPrivacyPolicy: false,
    };

    const parentName = 'contact';
    const agreeToPrivacyPolicyLabel = <>I agree to the <a href="https://www.porchmovinggroup.com/legal/privacy/" target="_blank" className='text-blue-600 underline'>Privacy Policy</a></>;

    return <>
        <Formik
            initialValues={initialValues}
            validationSchema={ldQuoteValidationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
            enableReinitialize
            onSubmit={handleSubmit}>
            <Form className='form-wrapper' noValidate>
                <Flex grow={1} direction='col' className='w-[576px] px-4'>
                    <ErrorFocus />
                    <Headings
                        primary="We noticed you are moving long distance..."
                        secondary="Please provide your contact information and we will send you an accurate, long-distance moving quote. You will receive an email confirmation, updates via SMS, and a moving specialist will contact you soon!"
                        imgSrc={""}
                        imgAlt={""}
                    />
                    <InputGroup rows={3} cols={2} className='mb-4'>
                        <HahFormikFieldGroup
                            parentName={parentName}
                            name='email'
                            rounded={['topLeft', 'topRight']}
                            placeholder='me@email.com'
                            cols={2}
                            iconLeft={<Icon icon={iconLibrary.faEnvelope} />}
                            required
                        />
                        <HahFormikFieldGroup parentName={parentName} name='phone' placeholder='(555) 000-0000' cols={2} required />
                        <HahFormikFieldGroup rounded={['bottomLeft']} parentName={parentName} name='firstName' placeholder='First name' required />
                        <HahFormikFieldGroup rounded={['bottomRight']} parentName={parentName} name='lastName' placeholder='Last name' required />
                        <HahFormikCheckbox className='mt-4 col-span-2' name="agreeToPrivacyPolicy" label={agreeToPrivacyPolicyLabel} required />
                    </InputGroup>
                    <Card className='mt-5 mb-0' centered={false} iconLeft={iconLibrary.faCircleInfo} title='MovingPlace is a licensed transportation broker, License #MC807665' bgColor='bg-primary-50' titleTextColor='text-primary-700' titleFontSize='text-sm text-start' />
                    <span className='text-start text-sm text-destructive mt-2'>
                        <ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.email`} />
                        <ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.phone`} />
                        <ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.firstName`} />
                        <ErrorMessage className='text-red-600 mb-2' component='div' name={`${parentName}.lastName`} />
                        <ErrorMessage className='text-red-600 mb-2' component='div' name="agreeToPrivacyPolicy" />
                    </span>
                </Flex>
                <Footer continueButtonContent="Submit" />
            </Form>
        </Formik>
    </>;
}