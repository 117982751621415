import { LdQuoteForm } from '@components';
import { UpdateContactDto } from '@generated/swaggerClient';
import { defaultState, useCheckoutClientFactory, useCheckoutState } from '@hooks';
import { sentryWrapper, toastError } from '@shared';
import { LdFormModel } from '@types';
import { js2, Urls } from '@utils';
import { FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';

export const LdQuotePage = () => {
    const { model: { id }, setModel } = useCheckoutState();
    const { wrapApiCall } = useCheckoutClientFactory();
    const navigate = useNavigate();

    const handleChange = async (values: LdFormModel, formikHelpers: FormikHelpers<LdFormModel>) => {
        setModel({
            ...values,
            contact: values.contact
        });

        const dto = UpdateContactDto.fromJS({ ...values.contact, id });
        const result = await wrapApiCall((client) => client.ldUpdateLd(id, dto), formikHelpers);

        if (!result.success) {
            sentryWrapper.logException(result.errorObj, 'error', 'Error updating long distance', { component: 'LdQuotePage' }, { id: id, dto: js2(dto) });

            toastError({ title: result.result ?? 'There was an error creating the quote. Please try again or give us a call.' });
        }

        return result.success;
    }

    const handleSubmit = () => {
        setModel(defaultState.model);
        navigate(Urls.quote.longDistanceConfirmation);
    }

    return <LdQuoteForm onChange={handleChange} onSubmitted={handleSubmit}  />;
}
