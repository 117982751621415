import { FatalErrorForm, Footer, Headings } from "@components";
import { AvailableTimeOptions } from "@generated/swaggerClient";
import { useCheckoutClientFactory } from "@hooks";
import { timeValidationSchema } from "@modules";
import { ActionButton, ErrorFocus, Flex, RadioBtn, RadioBtnGroup, sentryWrapper, Spinner, toastError, useEffectAsync } from "@shared";
import { TimeSelectionFormModel } from "@types";
import { debugLoggerInfo } from "@utils";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import React, { useState } from "react";

type TModel = TimeSelectionFormModel;
interface Props {
    onSubmitted: (action?: string) => void;
    onChange: (values: TModel, formikHelpers: FormikHelpers<TModel>) => Promise<boolean>;
    model: TModel;
    draftQuoteID: string;
    providerName?: string | undefined;
}

export const TimeSelectionForm = ({ onSubmitted, onChange, model, draftQuoteID, providerName }: Props) => {
    const { wrapApiCall } = useCheckoutClientFactory();

    const [availableTimes, setAvailableTimes] = React.useState<AvailableTimeOptions[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [isFatalError, setIsFatalError] = useState(false);

    const handleSubmit = async (values: TModel, formikHelpers: FormikHelpers<TModel>) => {
        if (await onChange(values, formikHelpers)) {
            onSubmitted(values.action);
        }
    }

    useEffectAsync(async (unloaded) => {
        if (unloaded()) return;
        setLoading(true);
        // Fetch available times
        const result = await wrapApiCall((client) => client.dateTimeGetAvailableTimes(draftQuoteID));
        setLoading(false);
        if (result.success) {
            setAvailableTimes(result.result!.availableTimes);
        }
        else {
            sentryWrapper.logException(result.errorObj, 'error', 'Failed to fetch available times', { component: 'TimeSelectionForm' }, { draftQuoteID });
            setIsFatalError(true);
            return;
        }
    }, [draftQuoteID]);

    if (isFatalError) {
        return <FatalErrorForm/>;
    }

    return <Formik
        initialValues={model}
        validationSchema={timeValidationSchema}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}>
        <Form className='form-wrapper' noValidate>
            <Flex direction='col' className='w-[576px] px-4'>
                <ErrorFocus />
                {loading && <Spinner delay={250} />}
                <Headings primary={'Your Move Start Time'} secondary={`When should ${providerName} arrive?`} />
                {!loading && (availableTimes.length > 0
                    ? <AvailableTimesList times={availableTimes} />
                    : <Headings secondary='No Available Times Found' />)}
            </Flex>
            <Footer />
        </Form>
    </Formik>;
}

const AvailableTimesList = ({ times }: { times: AvailableTimeOptions[]; }) => {
    const formikContext = useFormikContext<TModel>();
    const [expanded, setExpanded] = React.useState(!!formikContext.values.preferredStartTime);
    const onSetPreferredStartTime = (time: string) => {
        formikContext.setFieldValue('preferredStartTime', time);
        formikContext.setFieldTouched('preferredStartTime', true);
    }

    const currentTime = formikContext.values.preferredStartTime;

    useEffectAsync(async () => {
        debugLoggerInfo('AvailableTimesList.tsx - firing off useEffect', { currentTime, times });
        // Check if any of the available times values match the current time
        if (times.length > 0 && !times.some(item => item.value === (currentTime ?? ''))) {
            if (currentTime) {
                toastError({ title: 'Your originally selected time is unavailable, so we selected the next available time slot for you.'});
            }
            await formikContext.setFieldValue('preferredStartTime', times[0].value, false);
            await formikContext.setFieldTouched('preferredStartTime', true, true);
        }

    }, [currentTime, formikContext, times]);

    const timesToShow = React.useMemo(() => expanded || times.length <= 4 ? times : times.slice(0, 4), [expanded, times]);

    return <Flex direction="col">
        <RadioBtnGroup>
            {timesToShow.map(time => <RadioBtn
                key={time.value}
                name="preferredStartTime"
                value={time.value}
                label={time.label}
                checked={formikContext.values.preferredStartTime == time.value}
                onChange={onSetPreferredStartTime}
            />)}
        </RadioBtnGroup>
        {times.length > 4 && !expanded && <ActionButton className="mt-4" onClickAction={setExpanded} args={true}>Show more availability windows</ActionButton>}
    </Flex>;
}
