import { ProviderSortOrder } from '@generated/swaggerClient';
import { performDataLayerLog, RecommendedProviderSortOrder } from '@shared';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type ProviderListState = ProviderListStateModel & ProviderListStateActions;
export interface ProviderListStateActions {
    setSelectedProviderLocationID: (selectedProviderLocationID?: number) => void;
    setSelectedProviderSortOrder: (providerSortOrder: ProviderSortOrder) => void;
}

export interface ProviderListStateModel {
    /**
     * The provider location ID they chose/locked in manually.
     */
    selectedProviderLocationID?: number;
    /**
     * The last selected sort order for the provider list.
     */
    selectedProviderSortOrder: ProviderSortOrder;
}

export const ProviderListDefaultState: ProviderListStateModel = {
    selectedProviderSortOrder: RecommendedProviderSortOrder,
};

export const useProviderListState = create<ProviderListState>()(
    devtools(
        set => {
            return {
                ...ProviderListDefaultState,
                setSelectedProviderLocationID: (selectedProviderLocationID?: number) => {
                    if (selectedProviderLocationID !== undefined) {
                        performDataLayerLog({ event: 'select_new_msp' }, true);
                    }
                    return set({ selectedProviderLocationID }, false, {
                        type: 'setSelectedProviderLocationID',
                    });
                },
                setSelectedProviderSortOrder: (selectedProviderSortOrder: ProviderSortOrder) => {
                    return set({ selectedProviderSortOrder }, false, {
                        type: 'setSelectedProviderSortOrder',
                    });
                },
            };
        },
        { name: 'checkout', store: 'providerList' }
    )
);
