import { LocationDetailsForm } from "@components";
import { DraftQuoteLocationType, UpdateLocationDetailsDto } from "@generated/swaggerClient";
import { useCheckoutClientFactory, useCheckoutNavigation, useCheckoutState } from "@hooks";
import { sentryWrapper } from "@shared";
import { LocationDetailsFormModel } from "@types";
import { js2 } from "@utils";
import { FormikHelpers } from "formik";


export const LoadLocationPage = () => {
    const { onNavigate } = useCheckoutNavigation();
    const { model, setModel } = useCheckoutState();
    const { wrapApiCall } = useCheckoutClientFactory();

    const handleChange = async (values: LocationDetailsFormModel, formikHelpers: FormikHelpers<LocationDetailsFormModel>) => {
        setModel({
            loadAddressDetails: values
        });

        const dto = UpdateLocationDetailsDto.fromJS({
            type: values.propertyType,
            locationType: DraftQuoteLocationType.Origin,
            ...values
        });

        const result = await wrapApiCall((client) => client.locationsUpdateDetails(model.id, dto), formikHelpers);

        if (!result.success) {
            sentryWrapper.logException(result.errorObj, 'error', 'Error updating load location details', { component: 'LoadLocationPage' }, { id: model.id, dto: js2(dto) });
        }

        return result.success;
    }

    return <LocationDetailsForm onSubmitted={onNavigate} onChange={handleChange} model={{...model.loadAddressDetails, street2: model.loadAddressDetails.street2 ?? model.loadAddress.street2}} address={model.loadAddress.fullAddress!} label='Starting Address' imgSrc='/img/load-location.svg' imgAlt='Load location icon' />;
}