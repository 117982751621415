import { useCheckoutState } from '@hooks';
import { useEffectOnlyOnce } from '@shared';

export const ProspectVerify = () => {
    const { checkForExpiration} = useCheckoutState();

    useEffectOnlyOnce(() => {
        checkForExpiration();
    });
    return <></>;
}