import { QuoteSelectionsDto, UpdateContactAndQuoteDto } from '@generated/swaggerClient';
import { useCheckoutClientFactory, useCheckoutState } from '@hooks';
import { saveQuoteValidationSchema } from '@modules';
import { InputGroup, Modal, Card, Icon, iconLibrary, ActionButton, performDataLayerLog, sentryWrapper, toastError } from '@shared';
import { SaveQuoteFormModel } from '@types';
import { js2 } from '@utils';
import { FormikHandlers, FormikHelpers, FormikState, useFormik } from 'formik';
import React from 'react';

enum ModalState {
	InProgress,
	Success,
	Error
}
/**
 * Modal to save and email a quote
 */
export const SaveQuoteModal = ({ useLinkStyle }: { useLinkStyle?: boolean | undefined}) => {
	const { wrapApiCall } = useCheckoutClientFactory();
	const { model: { contact, id, selection, recommendation }, setModel } = useCheckoutState();
	const [currentState, setCurrentState] = React.useState(ModalState.InProgress);
	const [show, setShow] = React.useState(false);

    // Ensure contact exists in state
	React.useEffect(() => {
		if (!contact) {
			setModel({ contact: { email: '', firstName: '', lastName: '', phone: '' } });
		}
	}, [contact, setModel]);

    React.useEffect(() => {
        //  if we're showing the modal, then log the saved form start event
        if (show) {
            performDataLayerLog({ event: 'saved_form_start' }, true);
        }
    }, [ show ]);

    const handleSaveQuote = async (values: SaveQuoteFormModel, formikHelpers: FormikHelpers<SaveQuoteFormModel>) => {
		const selectionDto = QuoteSelectionsDto.fromJS(selection);
        const recommendationDto = QuoteSelectionsDto.fromJS(recommendation);
		const dto = UpdateContactAndQuoteDto.fromJS({ ...values.contact, selection: selectionDto, recommendation: recommendationDto });
		const result = await wrapApiCall(client => client.quoteEmail(id, dto), formikHelpers);
		if (result.success) {
            performDataLayerLog({ event: 'saved_form_submit' }, true);
			setModel({ contact: values.contact });
			setCurrentState(ModalState.Success);
		}
        else {
            // FUTURE: improve this. we should gracefully handle if the quote couldn't be saved for some reason, and tell the user what to do? or take them to an error page?

            sentryWrapper.logException(result.errorObj, 'error', 'quoteEmail API endpoint had an error', { component: 'SaveQuoteModal' }, { id, dto: js2(dto) });

            toastError({ title: 'There was an unexpected error saving your quote information. Please refresh and try again. If you continue to experience difficulties, please contact us.' });
        }
    };

	const formik = useFormik({
        initialValues: {
            contact: contact || {
                email: '',
                firstName: '',
                lastName: '',
                phone: ''
            }
        },
		onSubmit: handleSaveQuote,
		validateOnChange: true,
		validateOnMount: true,
		validateOnBlur: true,
        validationSchema: saveQuoteValidationSchema
	});

	const handleSubmit = () => {
        if (formik.isSubmitting) return;
		if (currentState == ModalState.InProgress) {
			if (formik.isValid) {
				formik.handleSubmit();
			}
		} else {
			setShow(false);
		}
	}

	const showModal = () => {
		setCurrentState(ModalState.InProgress);
		setShow(true);
	}

    const triggerLink = (
		<ActionButton className='text-primary-700 no-underline text-base font-medium' variant='link' onClickAction={showModal}>
			<Icon icon={iconLibrary.faEnvelope} className='me-2' />
			Email Quote
		</ActionButton>
	);

	const triggerButton = (
		<ActionButton className='w-full mb-2 text-xl font-normal leading-[30px]' variant='outline' shape='pill' onClickAction={showModal}>
			<Icon icon={iconLibrary.faEnvelope} className='me-2' />
			Save Your Quote
		</ActionButton>
	);

	const confirmContent = React.useMemo(() => {
		if (currentState === ModalState.Success) {
			return <><Icon icon={iconLibrary.faTimes} className='me-2' /> <span>Close this Window</span></>;
		}
		return <><Icon icon={iconLibrary.faEnvelope} className='me-2' /> <span>Save Your Quote</span></>;
	}, [currentState]);

	return (
		<Modal
			open={show}
			setOpen={setShow}
			title={currentState == ModalState.InProgress ? "Save Moving Quote" : ""}
			confirmContent={confirmContent}
			onConfirm={handleSubmit}
			confirmDisabled={!formik.isValid || formik.isSubmitting}
			triggerButton={useLinkStyle ? triggerLink : triggerButton}>
			{currentState === ModalState.InProgress && <InProgressContent formik={formik} />}
			{currentState === ModalState.Success && <SuccessContent />}
		</Modal>
	);
};

const SuccessContent = () => {
	return <div>
		<div className='text-center'>
            <span className="fa-layers fa-fw fa-3x mt-4 mb-3">
                <Icon className='text-primary-500' icon={iconLibrary.faCertificate} />
                <Icon icon={iconLibrary.faCheck} transform="shrink-6" inverse />
            </span>
		</div>
		<h2>Your Moving Quote is Saved!</h2>
		<p className='mb-4'>
			Your quote was successfully saved for later and we sent you an email with a link back to your quote.
		</p>
		<p className='mb-4'>
			If you gave us your phone number, expect a call back here within the 30 minutes.
		</p>
		<Card className='mb-0' iconLeft={iconLibrary.faCircleInfo} title='Your quote will be saved for 7 days' bgColor='bg-primary-50' titleTextColor='text-primary-700' />
	</div>;
}

const InProgressContent = ({ formik }: { formik: FormikState<SaveQuoteFormModel> & FormikHandlers }) => {
    const parentName = 'contact';

    const handleChange = (field: React.ChangeEvent<HTMLInputElement>) => {
        formik.getFieldHelpers(field.target.name).setTouched(true);
        formik.handleChange(field);
    };

	return <>
		<p className='mb-4'>
			Please provide your contact info to save your moving quote and receive a link via email. If you would like us to give you a call regarding your quote,
            please provide your phone number as well.
            Status: {formik.touched.contact?.email ? 'Touched' : 'Not Touched'}
        </p>
        {/* FUTURE: SG: I think this should be a ContactInfoForm and we should be able to get that working */}
		<InputGroup label={<span>Contact Info <span className='text-red-600'>*</span></span>} rows={3} cols={2} className='mb-4'>
			<InputGroup.Input
                name={`${parentName}.email`}
				rounded={['topLeft', 'topRight']}
				placeholder='me@email.com'
				value={formik.values.contact.email}
				onChange={handleChange}
				cols={2}
                maxLength={320}
				destructive={!!(formik.errors.contact?.email && formik.touched.contact?.email)}
				required
			/>
            <InputGroup.Input name={`${parentName}.firstName`} placeholder='First name' required value={formik.values.contact.firstName} destructive={!!(formik.errors.contact?.firstName && formik.touched.contact?.firstName)} onChange={handleChange} cols={1} maxLength={50} />
            <InputGroup.Input name={`${parentName}.lastName`} placeholder='Last name' required value={formik.values.contact.lastName} destructive={!!(formik.errors.contact?.lastName && formik.touched.contact?.lastName)} onChange={handleChange} cols={1} maxLength={50} />
			<InputGroup.Input
				rounded={['bottomLeft', 'bottomRight']}
                name={`${parentName}.phone`}
				placeholder='(555) 000-0000'
				value={formik.values.contact.phone}
				onChange={handleChange}
				cols={2}
			/>
		</InputGroup>
        {formik.errors.contact?.email && formik.touched.contact?.email && <div className='text-red-600 text-sm mb-1'>{formik.errors.contact?.email}</div>}
        {formik.errors.contact?.firstName && formik.touched.contact?.firstName && <div className='text-red-600 text-sm mb-1'>{formik.errors.contact?.firstName}</div>}
        {formik.errors.contact?.lastName && formik.touched.contact?.lastName && <div className='text-red-600 text-sm mb-1'>{formik.errors.contact?.lastName}</div>}
        {formik.errors.contact?.phone && formik.touched.contact?.phone && <div className='text-red-600 text-sm mb-1'>{formik.errors.contact?.phone}</div>}
		<Card className='mb-0' iconLeft={iconLibrary.faCircleInfo} title='Your quote will be saved for 7 days' bgColor='bg-primary-50' titleTextColor='text-primary-700' />
	</>;
}
