import { sentryWrapper } from '@shared';
import React from 'react';

export interface ActionLinkProps<T extends (...args: unknown[]) => void | Promise<void>> extends Omit<React.HTMLProps<HTMLAnchorElement>, 'onClick'> {
    onClickAction: T;
    args?: Parameters<T>;
    children: React.ReactNode;
    disabled?: boolean;
    href?: string; // Add 'href' for the link destination
    afterAction?: () => void;
}

export function ActionLink<T extends (...args: unknown[]) => void | Promise<void>>({
    onClickAction,
    args,
    children,
    disabled,
    href, // Include 'href' in the destructuring
    afterAction,
    ...rest
}: ActionLinkProps<T>) {
    const [isLoading, setIsLoading] = React.useState(false);

    const handleClick = React.useCallback(async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault(); // Prevent default link navigation

        if (isLoading || disabled) return;

        setIsLoading(true);
        try {
            const result = args ? onClickAction(...args) : onClickAction();
            if (result instanceof Promise) {
                await result;
            }
            if (afterAction) {
                afterAction();
            }
        } catch (e) {
            sentryWrapper.logException(e, 'error', 'ActionLink handleClick had an exception', { component: 'ActionLink' });
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, disabled, args, onClickAction, afterAction]);

    return (
        <a href={href || '#'} // Use 'href' if provided, otherwise '#' to prevent navigation
            onClick={handleClick}
            style={{ cursor: isLoading || disabled ? 'not-allowed' : 'pointer' }} // Visual cue for disabled state
            {...rest}>
            {children}
        </a>
    );
}