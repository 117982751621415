import { sentryWrapper } from "@shared";
import { DataLayerEvent } from "@types";
import { debugLoggerInfo } from "@utils";

/**
 * Performs a dataLayer log event for GA4.
 * @param event The event to log to the dataLayer.
 * @param immediately If true, the event will be logged immediately. Otherwise, it will be delayed by 1 second. We do this in case we need to acknowledge a page load event first.
 */
export const performDataLayerLog = (event: DataLayerEvent, immediately: boolean, performEcommerceClear: boolean = false) => {
    const actualLog = () => {
        window.dataLayer = window.dataLayer || []; // init if it doesnt exist already
        if (performEcommerceClear) {
            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        }
        window.dataLayer.push(event);

        // per John B, clear it after too, so if we have additional events (like they start over checkout??) we don't carry forward the ecommerce data
        if (performEcommerceClear) {
            window.dataLayer.push({ ecommerce: null });  // Clear the newly set ecommerce object.
        }
    };

    if (immediately) {
        actualLog();
    }
    else
    {
        setTimeout(() => {
            actualLog();
        }, 1000);
    }

    debugLoggerInfo('ga4; sent dataLayer event', { event });

    sentryWrapper.logBreadcrumb('dataLayer push', 'analytics');
};