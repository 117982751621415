import braintree, { Client } from 'braintree-web';
import { sentryWrapper, toastError, ToastMessage, useEffectOnlyOnce } from '@shared';
import { debugLoggerInfo, js2 } from '@utils';

export const useApplePayButton = (
    displayName: string,
    clientInstance: Client | null,
    grandTotal: string,
    buttonRef: React.MutableRefObject<HTMLElement | null>,
    handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void,
) => {
    useEffectOnlyOnce(() => {
        debugLoggerInfo('firing off useApplePayButton init via useEffectOnlyOnce');

        if (!clientInstance) {
            toastError({ title: 'PayPal client instance not available' });
            return;
        }

        setupApplePay(displayName, clientInstance, grandTotal, buttonRef, handlePaymentMethodApproved, toastError);
    });
};

function setupApplePay(
	displayName: string,
	clientInstance: Client,
	grandTotal: string,
	buttonRef: React.MutableRefObject<HTMLElement | null>,
	handlePaymentMethodApproved: (paymentNonce: string, postalCode: string | undefined, cardholderName: string | undefined) => void,
	toastError: (opts: Partial<Omit<ToastMessage, 'type'>>) => void,
) {
	braintree.applePay.create({ client: clientInstance }, (err, applePayInstance) => {
		if (err || !applePayInstance) {
            // FUTURE: SG: we may not want this to alert the user? we should log it though
            sentryWrapper.logException(err, 'error', 'Error creating ApplePay instance', { hook: 'useApplePayButton' });

            toastError({ title: 'We\'re sorry, there appears to have been a problem initializing ApplePay.' });
			return;
		}

        const applePayButton = document.createElement('button');
        applePayButton.type = 'button';
        applePayButton.className = 'w-full h-full';
        applePayButton.addEventListener('click', () => {
            const tosCheck = document.querySelector('input[name="agreedToTos"') as HTMLInputElement;
            if (!tosCheck.checked) {
                toastError({ title: 'Please agree to our Terms of Service before booking.' });
                return;
            }

            sentryWrapper.logInfo('ApplePay button clicked', { hook: 'useApplePayButton' });

            const paymentRequest = applePayInstance.createPaymentRequest({
				total: {
					label: displayName,
					amount: grandTotal,
				},
				countryCode: 'US',
				currencyCode: 'USD',
				requiredBillingContactFields: ['postalAddress'],
			});

			const session = new ApplePaySession(3, paymentRequest as ApplePayJS.ApplePayPaymentRequest);

			session.onvalidatemerchant = event => {
				applePayInstance.performValidation(
					{
						validationURL: event.validationURL,
						displayName: displayName,
					},
					(validationErr, merchantSession) => {
						if (validationErr) {
                            sentryWrapper.logException(validationErr, 'error', 'ApplePay returned an error during onvalidatemerchant-performValidation', { hook: 'useApplePayButton' }, { event: js2(event) });
                            toastError({ title: 'We\'re sorry, there appears to have been a problem validating ApplePay.' });
							session.abort();
							return;
						}
						session.completeMerchantValidation(merchantSession);
					}
				);
			};

			session.onpaymentauthorized = event => {
				applePayInstance.tokenize(
					{
						token: event.payment.token,
					},
					(tokenizeErr, payload) => {
						if (tokenizeErr) {
                            sentryWrapper.logException(tokenizeErr, 'error', 'ApplePay returned an error during onpaymentauthorized-tokenize', { hook: 'useApplePayButton' }, { event: js2(event) });
							toastError({ title: 'We\'re sorry, there appears to have been a problem authorizing ApplePay.' });
							session.completePayment(ApplePaySession.STATUS_FAILURE);
							return;
						}

						handlePaymentMethodApproved(payload!.nonce, event.payment.billingContact?.postalCode, `${event.payment.billingContact?.givenName} ${event.payment.billingContact?.familyName}`);

						session.completePayment(ApplePaySession.STATUS_SUCCESS);
					}
				);
			};

			session.begin();
        });

        if (buttonRef.current) {
            buttonRef.current.innerHTML = ''; // clear out any existing button
            buttonRef.current.appendChild(applePayButton);
        }
	});
}
