import { Footer, Headings, PorchHomeProtectionPlan } from '@components';
import { useCheckoutState, useOptimizelyExperiment } from '@hooks';
import { Button } from '@lemonsqueezy/wedges';
import { locationsValidationSchema } from '@modules';
import { AddressAutoComplete, AddressManualEntry, ErrorFocus, iconLibrary, Flex, Icon, sentryWrapper, getCurrentOptimizelyVariantId, PMG_6747_MP_LD_Funnel } from '@shared';
import { emptyAddress, LocationsFormModel } from '@types';
import { Urls } from '@utils';
import { APIProvider } from '@vis.gl/react-google-maps';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const API_KEY = 'AIzaSyC2n6eSKdrDveD8xKUoPp011f_IAaIaXwE';

interface LocationsFormProps {
    onSubmitted: (action?: string) => void;
    onChange: (values: LocationsFormModel, formikHelpers: FormikHelpers<LocationsFormModel>) => Promise<{saveSuccess: boolean; isLongDistance: boolean}>;
}

export const LocationsForm = (props: LocationsFormProps) => {
    const { onSubmitted, onChange } = props;
    const navigate = useNavigate();
    const { model, loggedManualEntry, setModel, setLoggedManualEntry } = useCheckoutState();
    const [googleLoaded, setGoogleLoaded] = useState(false);

    const canSkipToLD = useOptimizelyExperiment({
        optimizely: window.optimizely,
        apiName: PMG_6747_MP_LD_Funnel.apiName,
        experimentId: PMG_6747_MP_LD_Funnel.experimentId,
        variantId: PMG_6747_MP_LD_Funnel.controlId, // control group is the one that can skip to LD
        fetchVariant: getCurrentOptimizelyVariantId,
    });

    const handleSubmit = async (values: LocationsFormModel, formikHelpers: FormikHelpers<LocationsFormModel>) => {
        // if they're part of the MP LD LP test and they are moving long distance we should send them directly to the LD page
        const changeResult = await onChange(values, formikHelpers);
        if (changeResult) {
            if (canSkipToLD && changeResult.isLongDistance) {
                navigate(Urls.quote.longDistance);
            }
            else
            {
                onSubmitted(values.action);
            }
        }
    };

    // Close manual entry and reset the address fields
    const handleClickManualAddressEntry = () => {
        // log to sentry, but just once per visitor maybe?
        if (!loggedManualEntry) {
            setLoggedManualEntry();
            sentryWrapper.logWarn('User switched to manual address entry', { component: 'LocationsForm' }, { model: JSON.stringify(model) });
        }

        setModel({
            ...model,
            // nicholashead-pmg removed as part of PMG-6542 - seems weird to clear out the fields just because we're switching to manual
            //loadAddress: { ...emptyAddress },
            //unloadAddress: { ...emptyAddress },
            manualAddressEntry: !model.manualAddressEntry,
        });
    };

    const initialValues: LocationsFormModel = {
        loadAddress: model.loadAddress || { ...emptyAddress },
        unloadAddress: model.unloadAddress || { ...emptyAddress },
        manualAddressEntry: model.manualAddressEntry,
    };

    const handleValidate = async (values: LocationsFormModel) => {
        const errors: FormikErrors<LocationsFormModel> = {};
        if (!values.manualAddressEntry) {
            if (!values.loadAddress.placeId) {
                errors.loadAddress = { ...errors.loadAddress, placeId: 'Starting Address is required' };
            }
            if (!values.loadAddress.fullAddress) {
                errors.loadAddress = { ...errors.loadAddress, fullAddress: 'Starting Address is required' };
            }
            if (!values.unloadAddress.placeId) {
                errors.unloadAddress = { ...errors.unloadAddress, placeId: 'Destination Address is required' };
            }
            if (!values.unloadAddress.fullAddress) {
                errors.unloadAddress = { ...errors.unloadAddress, fullAddress: 'Destination Address is required' };
            }
        } else {
            if (!values.loadAddress.street) {
                errors.loadAddress = { ...errors.loadAddress, street: 'Starting Street is required' };
            }
            if (!values.loadAddress.city) {
                errors.loadAddress = { ...errors.loadAddress, city: 'Starting City is required' };
            }
            if (!values.loadAddress.state) {
                errors.loadAddress = { ...errors.loadAddress, state: 'Starting State is required' };
            }
            if (!values.loadAddress.zip) {
                errors.loadAddress = { ...errors.loadAddress, zip: 'Starting ZIP is required' };
            }
            if (!values.unloadAddress.street) {
                errors.unloadAddress = { ...errors.unloadAddress, street: 'Destination Street is required' };
            }
            if (!values.unloadAddress.city) {
                errors.unloadAddress = { ...errors.unloadAddress, city: 'Destination City is required' };
            }
            if (!values.unloadAddress.state) {
                errors.unloadAddress = { ...errors.unloadAddress, state: 'Destination State is required' };
            }
            if (!values.unloadAddress.zip) {
                errors.unloadAddress = { ...errors.unloadAddress, zip: 'Destination ZIP is required' };
            }
        }
        return errors;
    };

    const porchHomeProtectionContent = (
        <>
            <div className='text-neutralGrey-900 mb-2'>
                <span className='font-bold'>Limited time offer:</span> Protect your new home for <span className='font-bold'>FREE</span>!
            </div>
            <div className='text-neutralGrey-900'>Book today to redeem a free 60-day home warranty with your move.</div>
        </>
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={locationsValidationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
            validate={handleValidate}
            enableReinitialize
            onSubmit={handleSubmit}>
            <Form className='form-wrapper' noValidate>
                <Flex direction='col' className='w-[576px] px-4'>
                    <ErrorFocus />
                    <Headings
                        primary="You're In the Right Place for Your Move"
                        secondary="Tell us about your move and we'll match you with trusted partners tailored to your needs"
                        imgSrc={'/img/locations.svg'}
                        imgAlt={'Check Badge'}
                    />
                    {!model.manualAddressEntry && (
                        <APIProvider region='US' libraries={['places']} apiKey={API_KEY} onLoad={() => setGoogleLoaded(true)}>
                            {!googleLoaded && <div className='text-neutralGrey-700 text-sm mb-2'>Loading...</div>}
                            {googleLoaded && (
                                <>
                                    <AddressAutoComplete
                                        placeholder='Start typing...'
                                        label='Starting Address'
                                        fullAddressModel={model.loadAddress.fullAddress}
                                        isUnloadAddress={false}
                                    />
                                    <AddressAutoComplete
                                        placeholder='Start typing...'
                                        label='Destination Address'
                                        fullAddressModel={model.unloadAddress.fullAddress}
                                        isUnloadAddress={true}
                                    />
                                </>
                            )}
                        </APIProvider>
                    )}
                    {model.manualAddressEntry && (
                        <>
                            <AddressManualEntry label='Starting Address' parentName='loadAddress' />
                            <AddressManualEntry label='Destination Address' parentName='unloadAddress' />
                        </>
                    )}
                    <div>
                        <Button
                            className='text-neutralGrey-700 font-normal shadow-none!'
                            type='button'
                            after={
                                model.manualAddressEntry ? (
                                    <Icon size='sm' icon={iconLibrary.faSearch} className='w-3 h-3 ms-1 opacity-100' />
                                ) : (
                                    <Icon size='sm' icon={iconLibrary.faKeyboard} className='w-4 h-4 ms-1 opacity-100' />
                                )
                            }
                            variant='outline'
                            size='xs-icon'
                            onClick={handleClickManualAddressEntry}>
                            {model.manualAddressEntry ? 'Back to Address Search' : 'Enter Addresses Manually'}
                        </Button>
                    </div>
                    <PorchHomeProtectionPlan content={porchHomeProtectionContent} />
                </Flex>
                <Footer continueButtonContent='Start My Move Quote' />
            </Form>
        </Formik>
    );
};
