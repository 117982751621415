import { ServiceType2, DriveTimePolicyRule2, ServiceDetailsDto } from '@generated/swaggerClient';
import { iconLibrary, Icon } from '@shared';
import { toStringNoTrailingZeros, totalMinutesFromHours } from '@utils';

export interface PriceIncludesListProps {
    serviceDetails: ServiceDetailsDto;
}

const ListItem = ({ text }: { text: string }) => (
    <li className='mb-1 flex items-start'>
        <Icon className='text-primary-700 mr-2 mt-[2px]' icon={iconLibrary.faCheck} />
        <span className='text-sm font-normal leading-[21px]'>{text}</span>
    </li>
);

export const PriceIncludesList = ({ serviceDetails }: PriceIncludesListProps) => {
    const {
        serviceType,
        crewSize,
        numHours,
        extraHourRate,
        travelingHours,
        perMileFeeBetweenLocations,
        driveTimePolicy,
        heavyItems,
        totalStairs,
        stairsFee,
        totalStairsFee,
        transportOptionID,
        transportOptionName,
    } = serviceDetails;
    const isMoversPlusTruck = serviceType === ServiceType2.MoversPlusTruck;

    let crewSizeAndRateDescription = `${crewSize} ${crewSize == 1 ? 'mover' : 'movers'} for ${numHours} ${numHours == 1 ? 'hour' : 'hours'}`;
    if (extraHourRate) {
        crewSizeAndRateDescription += ` ($${toStringNoTrailingZeros(extraHourRate!)} per hour of labor after)`;
    }

    let driveTimePolicyDescription = '';

    if (driveTimePolicy === DriveTimePolicyRule2.PerMileFee) {
        if (isMoversPlusTruck) {
            driveTimePolicyDescription = `$${toStringNoTrailingZeros(extraHourRate!)} per hour of drive time + $${toStringNoTrailingZeros(
                perMileFeeBetweenLocations!
            )}/per mile (from pick-up location to drop-off location)`;
        } else {
            driveTimePolicyDescription = `$${toStringNoTrailingZeros(perMileFeeBetweenLocations!)}/per mile (from pick-up location to drop-off location)`;
        }
    } else if (driveTimePolicy === DriveTimePolicyRule2.DriveTimeIncluded) {
        driveTimePolicyDescription = `$${toStringNoTrailingZeros(extraHourRate!)} per hour of drive time (from pick-up location to drop-off location)`;
    } else if (driveTimePolicy === DriveTimePolicyRule2.DoubleDriveTime) {
        driveTimePolicyDescription = `$${toStringNoTrailingZeros(extraHourRate! * 2)} per hour of drive time (from pick-up location to drop-off location)`;
    }

    let stairsFeeDescription = '';

    if (stairsFee! > 0) {
        if (totalStairs === 1) {
            stairsFeeDescription = `1 Flight of Stairs: No Fee ($${toStringNoTrailingZeros(stairsFee!)}/Flight after 1st)`;
        } else {
            stairsFeeDescription = `${totalStairs === 1 ? 'Flight' : 'Flights'} of Stairs: $${toStringNoTrailingZeros(
                totalStairsFee!
            )} ($${toStringNoTrailingZeros(stairsFee!)}/Flight after 1st)`;
        }
    } else {
        stairsFeeDescription = `${totalStairs === 1 ? 'Flight' : 'Flights'} of Stairs: No Fee (provider does not charge for stairs)`;
    }

    return (
        <ul>
            {isMoversPlusTruck && <ListItem text='Move Protection Fee to support mover verification, service guarantees, and 24/7 customer support' />}
            <ListItem text={crewSizeAndRateDescription} />
            <ListItem text='All travel to your location' />
            {isMoversPlusTruck && <ListItem text={`${totalMinutesFromHours(travelingHours!)} minutes of drive time between locations`} />}
            {<ListItem text={driveTimePolicyDescription} />}
            {heavyItems &&
                heavyItems.map((heavyItem, index) => {
                    let equipmentText = `${heavyItem.quantity} ${heavyItem.quantity === 1 ? heavyItem.name : heavyItem.namePlural?.toLowerCase()}`;

                    if (heavyItem.additionalCosts) {
                        equipmentText += ` ($${toStringNoTrailingZeros(heavyItem.price!)}/ea.)`;
                    }

                    return <ListItem key={index} text={equipmentText} />;
                })}
            {totalStairs! > 0 && <ListItem text={stairsFeeDescription} />}
            {isMoversPlusTruck && transportOptionID && <ListItem text={transportOptionName!} />}
        </ul>
    );
};
