import { HeavyItemOptionsModel } from "./checkoutState";
import { HeavyItemsModel } from "@types";

export const defaultHeavyItemOptions = {
    hasSafe: false,
    hasMarbleFurniture: false,
    hasPianoUpright: false,
    hasBabyGrandPiano: false,
    hasPoolTable: false,
    hasStove: false,
    heavyItemBetween300and450lbsQty: undefined,
    heavyItemBetween450and600lbsQty: undefined,
    heavyItemOver600lbsQty: undefined,
} as HeavyItemOptionsModel;

export const defaultHeavyItems = {
    heavyItemOptions: defaultHeavyItemOptions,
} as HeavyItemsModel;