import { ProviderDetails, SaveQuoteModal } from '@components';
import { ProviderCard } from '@generated/swaggerClient';
import { Flex, Icon, iconLibrary, StarDisplay, ActionButton, Modal, HahTooltip } from '@shared';
import { useState } from 'react';

export const MovingPlanProviderSummary = ({ provider }: { provider: ProviderCard; }) => {
    const [open, setOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState<'info' | 'credentials' | 'reviews' | 'photos'>('info');
    const confirmButton = (
        <ActionButton variant='transparent' className='text-neutralGrey-600 text-xl font-medium w-full' onClickAction={() => setOpen(false)}>
            <Icon size='xs' icon={iconLibrary.faArrowLeft} className='me-2' />
            Back
        </ActionButton>
    );
    return (
        <Flex className='mt-4'>
            <a
                className='pr-4 cursor-pointer'
                onClick={() => {
                    setSelectedTab('photos');
                    setOpen(true);
                }}>
                <img className='w-20 xs:w-40' src={provider.logoUrl} width={300} height={300} />
            </a>
            <div className='flex-auto'>
                <Flex align='center' className='mb-2 gap-2 flex-wrap'>
                    <Modal
                        title={provider.name}
                        triggerText={provider.name}
                        open={open}
                        setOpen={setOpen}
                        onConfirm={() => setOpen(false)}
                        confirmButton={confirmButton}
                        confirmContent={
                            <>
                                <Icon icon={iconLibrary.faArrowLeft} className='mr-2' />
                                Close
                            </>
                        }>
                        <ProviderDetails provider={provider} selectedTab={selectedTab} />
                    </Modal>
                </Flex>
                <div
                    className='mb-2 cursor-pointer'
                    onClick={() => {
                        setSelectedTab('reviews');
                        setOpen(true);
                    }}>
                    <strong>
                        <span className='me-2'>{provider.reviewAverage}</span>
                        <StarDisplay rating={provider.reviewAverage} id={provider.providerLocationID} />
                        <span className='ms-1 text-primary-700'>{provider.reviewCount.toLocaleString()} verified reviews</span>
                    </strong>
                </div>
                {provider.completedJobCount > 0 && (
                    <div className='mb-1'>
                        <Icon icon={iconLibrary.faBox} fixedWidth className='mr-2' />
                        <strong>{provider.completedJobCount.toLocaleString()}</strong> moves in our marketplace
                    </div>
                )}
                {provider.yearsInBusiness > 0 && (
                    <div className='mb-1'>
                        <Icon icon={iconLibrary.faBriefcase} fixedWidth className='mr-2' />
                        <strong>{provider.yearsInBusiness.toLocaleString()}</strong> years in business
                    </div>
                )}
                <SaveQuoteModal useLinkStyle={true} />
                <div className="flex items-center justify-between my-2">
                    <div className="inline-block bg-primary-50 included-items p-2 rounded mt-2 md:mt-0">
                        <span className="text-primary-700 font-medium mr-2">Included:</span>
                        <HahTooltip tooltipContent="Furniture Dolly Hand Truck Or Straps">
                            <Icon icon={iconLibrary.faDollyFlatbed} className="fa-fw text-sm mr-2 text-primary-700" />
                        </HahTooltip>
                        <HahTooltip tooltipContent="Hand Tools For Assembly">
                            <Icon icon={iconLibrary.faWrench} className="fa-fw text-sm mr-2 text-primary-700" />
                        </HahTooltip>
                        <HahTooltip tooltipContent="Plastic Wrap">
                            <Icon icon={iconLibrary.faToiletPaper} className="fa-fw text-sm mr-2 text-primary-700" flip='vertical' />
                        </HahTooltip>
                        <HahTooltip tooltipContent="Rope Tie Downs">
                            <svg fill='#2E4FC7' className="svg-inline--fa fa-fw text-sm mr-2 text-primary-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M576 176c0 97.2-128.9 176-288 176c-13.2 0-26.3-.5-39-1.6c4.6 12.8 7 26.5 7 40.6c0 66.8-54.2 121-121 121l-71 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l71 0c31.5 0 57-25.5 57-57c0-21.6-12.2-41.3-31.5-51l-46.8-23.4 .2-.4C44.7 284.1 0 233.2 0 176C0 78.8 128.9 0 288 0S576 78.8 576 176zM288 288c123.7 0 224-50.1 224-112s-100.3-112-224-112S64 114.1 64 176s100.3 112 224 112z"/></svg>
                        </HahTooltip>
                    </div>
                </div>
            </div>
        </Flex>
    );
};
