import { Calendar } from '@shared';
import { DateFormModel } from '@types';
import { useFormikContext } from 'formik';
interface Props {
    value: string | undefined;
    isLongDistance?: boolean;
}

export const JobDateEdit = ({ value, isLongDistance }: Props) => {
    const formikContext = useFormikContext<DateFormModel>();    
    
    const handleChange = (v: string) => {
        formikContext.setFieldValue('date',v, true)
    }
    return <Calendar moveDate={value} onChange={handleChange} isLongDistance={isLongDistance} />
}
