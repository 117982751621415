export const formatCurrency = (amount: number, currencyCode: string = 'USD'): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    });
    
    return formatter.format(amount);
}

/**
*   Formats a decimal number into a string without trailing zeros.  
*   Duplicated from Pmg.Core.Utils/Extensions/StringExtensions.cs.   
*   Examples:
*   1. 1234.50 --> '1,234.5'
*   2. 1234 --> '1,234'
*   3. 1234.567 --> '1,234.567'
*   4. 1234.567 --> '1234.567' (includeCommas = false)
*/
export const toStringNoTrailingZeros = (amount: number, includeCommas: boolean = true): string => {
    // Determine if the number is an integer or has a fractional part
    const isInteger = Math.trunc(amount) === amount;

    // Format the number based on its type
    let text = isInteger ? amount.toLocaleString(undefined, { maximumFractionDigits: 0 }) : amount.toLocaleString(undefined, { maximumFractionDigits: 20 });

    // Remove commas if `includeCommas` is false
    if (!includeCommas) {
        text = text.replace(/,/g, "");
    }

    return text;
}