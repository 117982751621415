import { defaultState, useCheckoutClientFactory, useCheckoutNavigation, useCheckoutState } from '@hooks';
import { ItemsForm } from '@components';
import { HeavyItemsFormModel } from '@types';
import { FormikHelpers } from 'formik';
import { UpdateHeavyItemsDto } from '@generated/swaggerClient';
import { sentryWrapper } from '@shared';
import { js2 } from '@utils';

export const ItemsPage = () => {
	const { onNavigate } = useCheckoutNavigation();
	const { model, setModel } = useCheckoutState();
	const { wrapApiCall } = useCheckoutClientFactory();

	const handleChange = async (values: HeavyItemsFormModel, formikHelpers: FormikHelpers<HeavyItemsFormModel>) => {
        // If we're in fast mode we will skip the date page and don't want a cached selection/recommendation, we need to reset it
        setModel({
            heavyItems: {
                heavyItemOptions: values.heavyItemOptions,
            },
            recommendation: defaultState.model.recommendation,
            selection: defaultState.model.selection
        });

		const dto = UpdateHeavyItemsDto.fromJS(values);
		const result = await wrapApiCall(client => client.itemsUpdateHeavyItems(model.id, dto), formikHelpers);

        if (!result.success) {
            sentryWrapper.logException(result.errorObj, 'error', 'Error updating heavy items', { component: 'ItemsPage' }, { id: model.id, dto: js2(dto) });
        }

		return result.success;
	};

    return <ItemsForm onSubmitted={onNavigate} onChange={handleChange} model={model.heavyItems} />;
};
